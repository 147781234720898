import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {connect} from "react-redux";
import {update_report_file_attributes} from "../../../../redux/actions";


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
      },
    textField:
        {
          marginLeft: theme.spacing(1),
          marginRight: theme.spacing(1),
          // width: 400,
        },
}));

function EditableTitleField(props) {

    const [title, setTitle] = React.useState(props.report_file_attributes['title']);
    const classes = useStyles();

    const handleChange = (e) => {
        setTitle(e.target.value);
    };

    const send_to_store = () => {
        props.update_report_file_attributes({'title': title});
    };

    return (
        <div className={classes.root}>
            <TextField
                id="filled-full-width"
                placeholder="Title"
                fullWidth
                defaultValue={title}
                margin="normal"
                InputLabelProps={{
                shrink: true,
                }}
                inputProps={{
                  style: {fontSize: 25}
                }}
                onChange={handleChange}
                onBlur={send_to_store}
            />
        </div>
    );
}

const mapStateToProps = state => {
    const { report_file_attributes } = state;
    return { report_file_attributes }
};

export default connect(
    mapStateToProps,
    { update_report_file_attributes}
)(EditableTitleField);