import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import _ from "lodash";
import moment from "moment";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { retrieve_statistics_data } from "../../APIs/dashboard_api";
import SearchDateField from "../../common_route_components/SearchDateField";
import AttackMethodCharts from "./route_component/AttackMethodCharts";
import CampaignCharts from "./route_component/CampaignCharts";
import MalwareNameCharts from "./route_component/MalwareNameCharts";
import TargetVerticalCharts from "./route_component/TargetVerticalCharts";
import ThreatActorCharts from "./route_component/ThreatActorCharts";
import VulnerabilitiesCharts from "./route_component/VulnerabilitiesCharts";
// import Checkbox from "@material-ui/core/Checkbox";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { TDButton } from "tau-design-system";
import WidgetCard from "./route_component/WidgetCard";
// import CustomisedBootstrapButton from "../../common_route_components/CustomisedBootstrapButton";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
    marginTop: "5px",
  },
  paper: {
    height: 285,
    width: 400,
    padding: theme.spacing(1),
  },

  button: {
    backgroundColor: "#1d2f5d",
    color: "#FFFFFF",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "rgba(29, 47, 93, 0.9)",
      color: "#FFFFFF",
    },
  },
}));

const initStartDate = "2021-07-01";

function DashBoard() {
  const classes = useStyles();
  const [dates, setDates] = React.useState({
    start_date: moment(initStartDate).format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
  });
  const [checkbox, setCheckbox] = React.useState({
    no_pub_date_box: false,
  });

  const [scim_lists, set_scim_lists] = React.useState(null);

  const history = useHistory();

  const retrieve_stats_data = async () => {
    const { data } = await retrieve_statistics_data(dates, checkbox.no_pub_date_box);

    if (data) {
      const campaign_name_obj = manipulate_data(
        data["campaign_names"],
        "Campaign Name",
      );
      const campaign_name_chart = create_chart_data(campaign_name_obj);

      const malware_name_obj = manipulate_data(
        data["malware_names"],
        "Malware Names",
      );
      const malware_name_chart = create_chart_data(malware_name_obj);

      const threat_actor_obj = manipulate_data(
        data["threat_actors"],
        "Threat Actors",
      );
      const threat_actor_chart = create_chart_data(threat_actor_obj);

      const attack_methods_obj = manipulate_data(
        data["attack_methods"],
        "Attack Methods",
      );
      const attack_methods_chart = create_chart_data(attack_methods_obj);

      const target_verticals_obj = manipulate_data(
        data["target_verticals"],
        "Target Verticals",
      );
      const target_verticals_chart = create_chart_data(target_verticals_obj);

      const vulnerabilities_obj = manipulate_data(
        data["vulnerabilities"],
        "Vulnerabilities",
      );
      const vulnerabilities_chart = create_chart_data(vulnerabilities_obj);

      set_scim_lists({
        campaign_name_chart,
        malware_name_chart,
        threat_actor_chart,
        attack_methods_chart,
        target_verticals_chart,
        vulnerabilities_chart,
      });
      console.log(campaign_name_obj);
      console.log(campaign_name_chart.labels);
      console.log(campaign_name_chart.datasets[0]);
    }
  };
  const manipulate_data = (data, title) => {
    let label = [];
    let count = [];
    _.forEach(data, (obj) => {
      label.push(obj["key"]);
      count.push(obj["count"]);
    });

    return { title, label, count };
  };

  const create_chart_data = (data) => {
    let bg_Col, border_Col, hover_bg_Col, hover_border_Col;

    switch (data["title"]) {
      case "Campaign Name":
        bg_Col = [
          "#612500",
          "#873800",
          "#ad4e00",
          "#d46b08",
          "#fa8c16",
          "#ffa940",
          "#ffc069",
          "#ffd591",
          "#ffe7ba",
          "#fff7e6"
        ];
        border_Col = [
          "#612500",
          "#873800",
          "#ad4e00",
          "#d46b08",
          "#fa8c16",
          "#ffa940",
          "#ffc069",
          "#ffd591",
          "#ffe7ba",
          "#fff7e6"
        ];
        hover_bg_Col = [
          "rgba(97,37,0,0.7)",
          "rgba(135,56,0,0.7)",
          "rgba(173,78,0,0.7)",
          "rgba(212,107,8,0.7)",
          "rgba(250,140,22,0.7)",
          "rgba(255,169,64,0.7)",
          "rgba(255,192,105,0.7)",
          "rgba(255,213,145,0.7)",
          "rgba(255,231,186,0.7)",
          "rgba(255,247,230,0.7)"
        ];
        hover_border_Col = [
          "rgba(97,37,0,0.7)",
          "rgba(135,56,0,0.7)",
          "rgba(173,78,0,0.7)",
          "rgba(212,107,8,0.7)",
          "rgba(250,140,22,0.7)",
          "rgba(255,169,64,0.7)",
          "rgba(255,192,105,0.7)",
          "rgba(255,213,145,0.7)",
          "rgba(255,231,186,0.7)",
          "rgba(255,247,230,0.7)"
        ];
        break;
      case "Malware Names":
        bg_Col = [
          "#254000",
          "#3f6600",
          "#5b8c00",
          "#7cb305",
          "#a0d911",
          "#bae637",
          "#d3f261",
          "#eaff8f",
          "#f4ffb8",
          "#fcffe6"
        ];
        border_Col = [
          "#254000",
          "#3f6600",
          "#5b8c00",
          "#7cb305",
          "#a0d911",
          "#bae637",
          "#d3f261",
          "#eaff8f",
          "#f4ffb8",
          "#fcffe6"
        ];
        hover_bg_Col = [
          "rgba(37,64,0, 0.7)",
          "rgba(63,102,0, 0.7)",
          "rgba(91,140,0, 0.7)",
          "rgba(124,179,5, 0.7)",
          "rgba(160,217,17, 0.7)",
          "rgba(186,230,55, 0.7)",
          "rgba(211,242,97, 0.7)",
          "rgba(234,255,143, 0.7)",
          "rgba(244,255,184, 0.7)",
          "rgba(252,255,230, 0.7)"
        ];
        hover_border_Col = [
          "rgba(37,64,0, 0.7)",
          "rgba(63,102,0, 0.7)",
          "rgba(91,140,0, 0.7)",
          "rgba(124,179,5, 0.7)",
          "rgba(160,217,17, 0.7)",
          "rgba(186,230,55, 0.7)",
          "rgba(211,242,97, 0.7)",
          "rgba(234,255,143, 0.7)",
          "rgba(244,255,184, 0.7)",
          "rgba(252,255,230, 0.7)"
        ];
        break;
      case "Threat Actors":
        bg_Col = [
          "#002766",
          "#003a8c",
          "#0050b3",
          "#096dd9",
          "#1890ff",
          "#40a9ff",
          "#69c0ff",
          "#91d5ff",
          "#bae7ff",
          "#e6f7ff"
        ];
        border_Col = [
          "#002766",
          "#003a8c",
          "#0050b3",
          "#096dd9",
          "#1890ff",
          "#40a9ff",
          "#69c0ff",
          "#91d5ff",
          "#bae7ff",
          "#e6f7ff"
        ];
        hover_bg_Col = [
          "rgba(0,39,102,0.7)",
          "rgba(0,58,140,0.7)",
          "rgba(0,80,179,0.7)",
          "rgba(9,109,217,0.7)",
          "rgba(24,144,255,0.7)",
          "rgba(64,169,255,0.7)",
          "rgba(105,192,255,0.7)",
          "rgba(145,213,255,0.7)",
          "rgba(186,231,255,0.7)",
          "rgba(230,247,255,0.7)"
        ];
        hover_border_Col = [
          "rgba(0,39,102,0.7)",
          "rgba(0,58,140,0.7)",
          "rgba(0,80,179,0.7)",
          "rgba(9,109,217,0.7)",
          "rgba(24,144,255,0.7)",
          "rgba(64,169,255,0.7)",
          "rgba(105,192,255,0.7)",
          "rgba(145,213,255,0.7)",
          "rgba(186,231,255,0.7)",
          "rgba(230,247,255,0.7)"
        ];
        break;
      case "Attack Methods":
        bg_Col = [
          "#5c0011",
          "#820014",
          "#a8071a",
          "#cf1322",
          "#f5222d",
          "#ff4d4f",
          "#ff7875",
          "#ffa39e",
          "#ffccc7",
          "#fff1f0",
        ];
        border_Col = [
          "#5c0011",
          "#820014",
          "#a8071a",
          "#cf1322",
          "#f5222d",
          "#ff4d4f",
          "#ff7875",
          "#ffa39e",
          "#ffccc7",
          "#fff1f0",
        ];
        hover_bg_Col = [
          "rgba(92,0,17,0.7)",
          "rgba(130,0,20,0.7)",
          "rgba(168,7,26,0.7)",
          "rgba(207,19,34,0.7)",
          "rgba(245,34,45,0.7)",
          "rgba(255,77,79,0.7)",
          "rgba(255,120,117,0.7)",
          "rgba(255,163,158,0.7)",
          "rgba(255,204,199,0.7)",
          "rgba(255,241,240,0.7)",
        ];
        hover_border_Col = [
          "rgba(92,0,17,0.7)",
          "rgba(130,0,20,0.7)",
          "rgba(168,7,26,0.7)",
          "rgba(207,19,34,0.7)",
          "rgba(245,34,45,0.7)",
          "rgba(255,77,79,0.7)",
          "rgba(255,120,117,0.7)",
          "rgba(255,163,158,0.7)",
          "rgba(255,204,199,0.7)",
          "rgba(255,241,240,0.7)"
        ];
        break;
      case "Target Verticals":
        bg_Col = [
          "#002329",
          "#00474f",
          "#006d75",
          "#08979c",
          "#13c2c2",
          "#36cfc9",
          "#5cdbd3",
          "#87e8de",
          "#b5f5ec",
          "#e6fffb"
        ];
        border_Col = [
          "#002329",
          "#00474f",
          "#006d75",
          "#08979c",
          "#13c2c2",
          "#36cfc9",
          "#5cdbd3",
          "#87e8de",
          "#b5f5ec",
          "#e6fffb"
        ];
        hover_bg_Col = [
          "rgba(0,35,41,0.7)",
          "rgba(0,71,79,0.7)",
          "rgba(0,109,117,0.7)",
          "rgba(8,151,156,0.7)",
          "rgba(19,194,194,0.7)",
          "rgba(54,207,201,0.7)",
          "rgba(92,219,211,0.7)",
          "rgba(135,232,222,0.7)",
          "rgba(181,245,236,0.7)",
          "rgba(230,255,251,0.7)"
        ];
        hover_border_Col = [
          "rgba(0,35,41,0.7)",
          "rgba(0,71,79,0.7)",
          "rgba(0,109,117,0.7)",
          "rgba(8,151,156,0.7)",
          "rgba(19,194,194,0.7)",
          "rgba(54,207,201,0.7)",
          "rgba(92,219,211,0.7)",
          "rgba(135,232,222,0.7)",
          "rgba(181,245,236,0.7)",
          "rgba(230,255,251,0.7)"
        ];
        break;
      case "Vulnerabilities":
        bg_Col = [
          "#520339",
          "#780650",
          "#9e1068",
          "#c41d7f",
          "#eb2f96",
          "#f759ab",
          "#ff85c0",
          "#ffadd2",
          "#ffd6e7",
          "#fff0f6"
        ];
        border_Col = [
          "#520339",
          "#780650",
          "#9e1068",
          "#c41d7f",
          "#eb2f96",
          "#f759ab",
          "#ff85c0",
          "#ffadd2",
          "#ffd6e7",
          "#fff0f6"
        ];
        hover_bg_Col = [
          "rgba(82,3,57,0.7)",
          "rgba(120,6,80,0.7)",
          "rgba(158,16,104,0.7)",
          "rgba(196,29,127,0.7)",
          "rgba(235,47,150,0.7)",
          "rgba(247,89,171,0.7)",
          "rgba(255,133,192,0.7)",
          "rgba(255,173,210,0.7)",
          "rgba(255,214,231,0.7)",
          "rgba(255,240,246,0.7)"
        ];
        hover_border_Col = [
          "rgba(82,3,57,0.7)",
          "rgba(120,6,80,0.7)",
          "rgba(158,16,104,0.7)",
          "rgba(196,29,127,0.7)",
          "rgba(235,47,150,0.7)",
          "rgba(247,89,171,0.7)",
          "rgba(255,133,192,0.7)",
          "rgba(255,173,210,0.7)",
          "rgba(255,214,231,0.7)",
          "rgba(255,240,246,0.7)"
        ];
        break;
      default:
        bg_Col = "rgba(0, 176, 80)";
        border_Col = "rgba(0, 176, 80)";
        hover_bg_Col = "rgba(255,255,255)";
        hover_border_Col = "rgba(255,255,255,0.5)";
    }
    return {
      labels: data["label"],
      datasets: [
        {
          label: `${data["title"]} - Top 10`,
          backgroundColor: bg_Col,
          borderColor: border_Col,
          borderWidth: 1,
          hoverBackgroundColor: hover_bg_Col,
          hoverBorderColor: hover_border_Col,
          data: data["count"],
        },
      ],
    };
  };

  window.onload = retrieve_stats_data; //This is to load the chart when the page is refreshed

  const handleRedirectLink = (elem, data) => {
    if (elem.length === 1) {
      // Clicked on a bar of any chart
      let query = data.labels[elem[0]._index];
      let modified_query = modify_query_to_have_quotes(query);
      alert("Searching for: " + modified_query);
      history.push(`/?search=${modified_query}`);
    }
  };

  const modify_query_to_have_quotes = (query) => {
    let splitted_str = query.split(" ");
    if (splitted_str.length > 1) {
      return '"' + query + '"';
    }
    return query;
  };

  const handleUpdateDate = (new_date) => {
    let new_state_date = { ...dates, ...new_date };
    setDates(new_state_date);
  };

  const handleNoPubDateCheckBox = (event) => {
    setCheckbox({ ...checkbox, [event.target.name]: event.target.checked });
  };

  useEffect(() => {
    retrieve_stats_data();
  }, []);

  return (
    <div style={{ marginTop: "70px", marginLeft: "55px" }}>
      <Container maxWidth={false}>
        <div className="flex row space-x-4 w-full justify-center mb-8">
          <SearchDateField
            initStartDate={initStartDate}
            handleUpdateDate={handleUpdateDate}
          />
          <TDButton onClick={() => retrieve_stats_data()}>View Dashboard</TDButton>
        </div>
        {/* <Grid container direction="row" justify="center">
          <Button
            variant="contained"
            color="primary"
            onClick={() => retrieve_stats_data()}
            style={{
              height: "35px",
              marginTop: "5px",
              backgroundColor: "#1d2f5d",
            }}
          >
            Search
          </Button>
        </Grid> */}
        <Grid container className={classes.root}>
          <Grid item xs={12}>
            <Grid container spacing={2} justify="center">
              <Grid item>
                <WidgetCard title="Most Common Attack Methods Used">
                  <AttackMethodCharts
                    scim_lists={scim_lists}
                    handleRedirectLink={handleRedirectLink}
                  ></AttackMethodCharts>
                </WidgetCard>
              </Grid>
              <Grid item>
                <WidgetCard title="Most Active Campaigns">
                  <CampaignCharts
                    scim_lists={scim_lists}
                    handleRedirectLink={handleRedirectLink}
                  ></CampaignCharts>
                </WidgetCard>
              </Grid>
              <Grid item>
                <WidgetCard title="Most Active Malwares">
                  <MalwareNameCharts
                    scim_lists={scim_lists}
                    handleRedirectLink={handleRedirectLink}
                  ></MalwareNameCharts>
                </WidgetCard>
              </Grid>
              <Grid item>
                <WidgetCard title="Most Targeted Verticals">
                  <TargetVerticalCharts
                    scim_lists={scim_lists}
                    handleRedirectLink={handleRedirectLink}
                  ></TargetVerticalCharts>
                </WidgetCard>
              </Grid>
              <Grid item>
                <WidgetCard title="Most Active Threat Actors">
                  <ThreatActorCharts
                    scim_lists={scim_lists}
                    handleRedirectLink={handleRedirectLink}
                  ></ThreatActorCharts>
                </WidgetCard>
              </Grid>
              <Grid item>
                <WidgetCard title="Most Active Vulnerabilities">
                  <VulnerabilitiesCharts
                    scim_lists={scim_lists}
                    handleRedirectLink={handleRedirectLink}
                  ></VulnerabilitiesCharts>
                </WidgetCard>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default DashBoard;
