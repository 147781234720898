import _ from 'lodash';
import {TTP, TTP_EXTRA, WHITE_LIST} from "../../constants/tables";


export const titleCase = (str) => {
    /*
        This function converts alias_china to Alias China (example)
        Mainly used for column names
     */
    let modified = headerTitle_changer(str);
    if (modified) {return modified}
    let splitStr = str.toLowerCase().split('_');
    for (let i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
}

export const titleLower = (str) => {
    /*
        This function converts Alias China to alias_china (example)
        Mainly used for column names
     */
    let splitStr = str.toLowerCase().split(' ');
    return splitStr.join('_');
}

const headerTitle_changer = (header_name) => {
    if ((header_name) === TTP) {
        return 'TTP Overview'
    }
    else if ((header_name) === TTP_EXTRA) {
        return 'TTP - Mitre'
    }
    else if ((header_name) === WHITE_LIST) {
        return 'URL and Email White List'
    }
}



export const TableDictBuilder = (table_data, table_headers) => {
    /*
        convert attack_methods to Attack Methods
        This function builds the Table Dict on the fly like REF_DOC_DICT in contants/tables.js
        @param table_data: actual data retrieved from backend
        @param table_headers: list of table names
     */
    let dict = {}
    table_headers.forEach(table_name => {
        const header_ = [];
        // get the table_data {table_name: [{column name: value}...]
        if (!_.isEmpty(table_data[table_name])) {
            let keys_array = Object.keys(table_data[table_name][0]);
            for (const header_name of keys_array) {
                if (header_name !== 'tableData') {
                    header_.push({
                        title: titleCase(header_name),
                        field: header_name
                    });
                }
            }
            dict[table_name] = {
                name: table_name,
                title: titleCase(table_name),
                header: header_
            }
        }

    })
    return dict;
}