/*
import ip_img from '../../../../assets/scim_icons/IP.png';
import hashes_sha1_img from '../../../../assets/scim_icons/Hashes-SHA1.png';
import hashes_sha256_img from '../../../../assets/scim_icons/Hashes-SHA256.png';
import hashes_md5_img from '../../../../assets/scim_icons/Hashes-MD5.png';
import url_img from '../../../../assets/scim_icons/URL.png';
import url_domain_img from '../../../../assets/scim_icons/URL-Domain.png';
import email_img from '../../../../assets/scim_icons/Email.png';
import country_img from '../../../../assets/scim_icons/Country.png';
import vulnerability_cve_img from '../../../../assets/scim_icons/CVE Number.png';
import threat_actor_img from '../../../../assets/scim_icons/ThreatActor.png';
import threat_actor_aliases_img from '../../../../assets/scim_icons/ThreatActor-Aliases.png';
import campaign_name_img from '../../../../assets/scim_icons/CampaignName.png';
import attack_method_img from '../../../../assets/scim_icons/AttackMethod.png';
import malware_name_img from '../../../../assets/scim_icons/MalwareName.png';
import malware_category_img from '../../../../assets/scim_icons/MalwareCategory.png';
import target_vertical_industry_img from '../../../../assets/scim_icons/TargetVerticals-Industry.png';
import target_vertical_sector_img from '../../../../assets/scim_icons/TargetVerticals-Sector.png';
import ttp_img from '../../../../assets/scim_icons/TTP.png';
import ttp_category_img from '../../../../assets/scim_icons/TTP-Category.png';
import ttp_id_img from '../../../../assets/scim_icons/TTP-ID.png';
import registry_key_img from '../../../../assets/scim_icons/RegistryKey.png';

*/

import report_img from '../../../../assets/stix_icons2_prof/report.png';

export const stylesheet=[
    {
        selector: 'node',
        style: {
            width: 30,
            height: 30,
            'background-color': '#135200',
            'label': 'data(label)',
            'color': '#f3f3f3',
            'font-family': 'Arial Narrow',
            'font-size': '17.5pt',
            'font-style': 'normal',
            'font-smoothing': 'subpixel-antialiased',
            'border-color': '#adadaf',
            'border-width': 2
        }
    },
    
    // {
    //     selector: ' ',
    //     css: {
    //       'background': '#BBBBBB'
    //     }
    // },

    {
        selector: 'edge',
        style: {
            'width': 1,
            'line-color': '#adadaf',
            'target-arrow-color': '#adadaf',
            'target-arrow-shape': 'triangle',
            'curve-style': 'bezier',
        }
    },


    {
        selector: 'edge[label="associate"]',
        style: {
            'width': 1,
            'line-color': '#adadaf',
            'line-style': 'solid',
            'target-arrow-color': '#adadaf',
            'target-arrow-shape': 'none',
            'source-arrow-shape': 'none',
            'curve-style': 'bezier',
        }
    },

    {
        selector: 'edge[label="ml_associate"]',
        style: {
            'width': 1,
            'line-color': '#adadaf',
            'line-style': 'dashed',
            'target-arrow-color': '#adadaf',
            'target-arrow-shape': 'none',
            'source-arrow-shape': 'none',
            'curve-style': 'bezier',
        }
    },

    {
        selector: 'edge[label="associatedoc"]',
        style: {
            'width': 2,
            'line-color': '#adadaf',
            'line-style': 'solid',
            'target-arrow-color': '#aa7714',
            'target-arrow-shape': 'none',
            'source-arrow-shape': 'none',
            'curve-style': 'bezier',
        }
    },

    {
        selector: 'edge[label="referencedoc"]',
        style: {
            'width': 2,
            'line-color': '#ADADAF',
            'line-style': 'solid',
            'target-arrow-color': '#ADADAF',
            'target-arrow-shape': 'none',
            'source-arrow-shape': 'none',
            'curve-style': 'bezier',
        }
    },

    {
        selector: 'edge[label="contains"]',
        css: {
            'line-color': '#C00000',
            'target-arrow-color': '#C00000',
        }
    },
    {
        selector: 'edge[label="mention"]',
        css: {
            'line-color': '#203864',
            'target-arrow-color': '#203864',
        }
    },
    {
        selector: 'edge[label="part_of"]',
        css: {
            'line-color': '#F47D23',
            'target-arrow-color': '#F47D23',
        }
    },
    {
        selector: 'edge[label="originate_from"]',
        css: {
            'line-color': '#662C91',
            'target-arrow-color': '#662C91',
        }
    },
    {
        selector: 'edge[label="attributes"]',
        css: {
            'line-color': '#008C48',
            'target-arrow-color': '#008C48',
        }
    },

    {   /*un-verified*/
        selector: 'node[type="summary_report"][!verified]',
        style: {
            'shape': 'ellipse',
            'width': 100,
            'height': 100,
            'font-weight': 'bold',
            'border-width': 10,
            'border-style': 'dashed',
            'border-color': '#fbff00',
            'background-image': report_img,
            "background-fit": "cover cover",
            'background-opacity': 0,
            'background-clip': 'none'
        }
    },
    {   /*verified*/
        selector: 'node[type="summary_report"][?verified]',
        style: {
            'shape': 'ellipse',
            'width': 100,
            'height': 100,
            'font-weight': 'bold',
            'background-image': report_img,
            "background-fit": "cover cover",
            'background-opacity': 0,
            'background-clip': 'none'
        }
    },

    // New icons!
    {
        // IP
        selector: 'node[type="ip"][?root]', /* Checks that IP node is a root node */
        style: {
            'shape': 'ellipse',
            'width': 30,
            'height': 30,
            'background-color': '#d87a16',
            "color": '#fafafa', /* Make it white and bold */
            'font-weight': 'bold'
        }
    },
    {
        selector: 'node[type="ip"]',
        style: {
            'shape': 'ellipse',
            'width': 30,
            'height': 30,
            'background-color': '#d87a16',
            'color': '#ffffff'
        }
    },
    {
        // Hash - sha1
        selector: 'node[type="hashes_sha1"][?root]',
        style: {
            'shape': 'ellipse',
            'width': 30,
            'height': 30,
            'background-color': '#a02669',
            "color": '#fafafa',
            'font-weight': 'bold'
        }
    },
    {
        selector: 'node[type="hashes_sha1"]',
        style: {
            'shape': 'ellipse',
            'width': 30,
            'height': 30,
            'background-color': '#a02669',
        }
    },
    {
        // Hash - sha256
        selector: 'node[type="hashes_sha256"][?root]',
        style: {
            'shape': 'ellipse',
            'width': 30,
            'height': 30,
            'background-color': '#a02669',
            "color": '#fafafa',
            'font-weight': 'bold'
        }
    },
    {
        selector: 'node[type="hashes_sha256"]',
        style: {
            'shape': 'ellipse',
            'width': 30,
            'height': 30,
            'background-color': '#a02669',
        }
    },
    {
        // Hash - md5
        selector: 'node[type="hashes_md5"][?root]',
        style: {
            'shape': 'ellipse',
            'width': 30,
            'height': 30,
            'background-color': '#a02669',
            "color": '#fafafa',
            'font-weight': 'bold'
        }
    },
    {
        selector: 'node[type="hashes_md5"]',
        style: {
            'shape': 'ellipse',
            'width': 30,
            'height': 30,
            'background-color': '#a02669',
        }
    },
    {
        // URL
        selector: 'node[type="url"][?root]',
        style: {
            'shape': 'ellipse',
            'width': 30,
            'height': 30,
            'background-color': '#d87a16',
            "color": '#fafafa',
            'font-weight': 'bold'
        }
    },
    {
        selector: 'node[type="url"]',
        style: {
            'shape': 'ellipse',
            'width': 30,
            'height': 30,
            'background-color': '#d87a16',
        }
    },
    {
        // URL - Domain
        selector: 'node[type="url_domain"][?root]',
        style: {
            'shape': 'ellipse',
            'width': 30,
            'height': 30,
            'background-color': '#d87a16',
            "color": '#fafafa',
            'font-weight': 'bold'
        }
    },
    {
        selector: 'node[type="url_domain"]',
        style: {
            'shape': 'ellipse',
            'width': 30,
            'height': 30,
            'background-color': '#d87a16',
        }
    },
    {
        // Email
        selector: 'node[type="email"][?root]',
        style: {
            'shape': 'ellipse',
            'width': 30,
            'height': 30,
            'background-color': '#d87a16',
            "color": '#fafafa',
            'font-weight': 'bold'
        }
    },
    {
        selector: 'node[type="email"]',
        style: {
            'shape': 'ellipse',
            'width': 30,
            'height': 30,
            'background-color': '#d87a16',
        }
    },
    {
        // Country
        selector: 'node[type="country"][?root]',
        style: {
            'shape': 'ellipse',
            'width': 30,
            'height': 30,
            'background-color': '#d87a16',
            "color": '#fafafa',
            'font-weight': 'bold'
        }
    },
    {
        selector: 'node[type="country"]',
        style: {
            'shape': 'ellipse',
            'width': 30,
            'height': 30,
            'background-color': '#d87a16',
        }
    },
    {
        // Vulnerability
        selector: 'node[type="vulnerability_cve"][?root]',
        style: {
            'shape': 'ellipse',
            'width': 30,
            'height': 30,
            'background-color': '#003a8c',
            "color": '#fafafa',
            'font-weight': 'bold'
        }
    },
    {
        selector: 'node[type="vulnerability_cve"]',
        style: {
            'shape': 'ellipse',
            'width': 30,
            'height': 30,
            'background-color': '#003a8c',
        }
    },
    {
        // Threat Actor
        selector: 'node[type="threat_actor"][?root]',
        style: {
            'shape': 'ellipse',
            'width': 30,
            'height': 30,
            'background-color': '#791A1F',
            "color": '#008C48',
            'font-weight': 'bold',
            'border-color': 'green'
        }
    },
    {
        selector: 'node[type="threat_actor"]',
        style: {
            'shape': 'ellipse',
            'width': 30,
            'height': 30,
            'background-color': '#791A1F',
            "color": '#fafafa'
        }
    },

    {
        // Threat Actor - Alias
        selector: 'node[type="threat_actor_aliases"][?root]',
        style: {
            'shape': 'ellipse',
            'width': 30,
            'height': 30,
            'background-color': '#791A1F',
            "color": '#fafafa',
            'font-weight': 'bold'
        }
    },
    {
        selector: 'node[type="threat_actor_aliases"]',
        style: {
            'shape': 'ellipse',
            'width': 30,
            'height': 30,
            'background-color': '#791A1F',
        }
    },
    {
        // Campaign Name
        selector: 'node[type="campaign_name"][?root]',
        style: {
            'shape': 'ellipse',
            'width': 30,
            'height': 30,
            'background-color': '#791A1F',
            "color": '#fafafa',
            'font-weight': 'bold'
        }
    },
    {
        selector: 'node[type="campaign_name"]',
        style: {
            'shape': 'ellipse',
            'width': 30,
            'height': 30,
            'background-color': '#791A1F',
        }
    },

    {
        // Campaign Name
        selector: 'node[type="campaign"][?root]',
        style: {
            'shape': 'ellipse',
            'width': 30,
            'height': 30,
            'background-color': '#008C48',
            "color": '#fafafa',
            'font-weight': 'bold',
            'border-color': 'green'
        }
    },
    {
        selector: 'node[type="campaign"]',
        style: {
            'shape': 'ellipse',
            'width': 30,
            'height': 30,
            'background-color': '#008C48',
        }
    },

    {
        // Attack Method
        selector: 'node[type="attack_method"][?root]',
        style: {
            'shape': 'ellipse',
            'width': 30,
            'height': 30,
            'background-color': '#003a8c',
            "color": '#fafafa',
            'font-weight': 'bold'
        }
    },
    {
        selector: 'node[type="attack_method"]',
        style: {
            'shape': 'ellipse',
            'width': 30,
            'height': 30,
            'background-color': '#003a8c',

        }
    },
    {
        // Malware Name
        selector: 'node[type="malware_name"][?root]',
        style: {
            'shape': 'ellipse',
            'width': 30,
            'height': 30,
            'background-color': '#791A1F',
            "color": '#008C48',
            'font-weight': 'bold'
        }
    },
    {
        selector: 'node[type="malware_name"]',
        style: {
            'shape': 'ellipse',
            'width': 30,
            'height': 30,
            'background-color': '#791A1F',
            "color": '#fafafa'
        }
    },

    {
        // Malware Name
        selector: 'node[type="malware"][?root]',
        style: {
            'shape': 'ellipse',
            'width': 30,
            'height': 30,
            'background-color': '#003a8c',
            "color": '#008C48',
            'font-weight': 'bold',
            'border-color': 'green'
        }
    },
    {
        selector: 'node[type="malware"]',
        style: {
            'shape': 'ellipse',
            'width': 30,
            'height': 30,
            'background-color': '#003a8c',
            "color": '#fafafa'
        }
    },

    {
        // Malware Category
        selector: 'node[type="malware_category"][?root]',
        style: {
            'shape': 'ellipse',
            'width': 30,
            'height': 30,
            'background-color': '#791A1F',
            "color": '#fafafa',
            'font-weight': 'bold'
        }
    },
    {
        selector: 'node[type="malware_category"]',
        style: {
            'shape': 'ellipse',
            'width': 30,
            'height': 30,
            'background-color': '#791A1F',
        }
    },
    {
        // Target Vertical - Industry
        selector: 'node[type="target_vertical_industry"][?root]',
        style: {
            'shape': 'ellipse',
            'width': 30,
            'height': 30,
            'background-color': '#aa9514',
            "color": '#fafafa',
            'font-weight': 'bold'
        }
    },
    {
        selector: 'node[type="target_vertical_industry"]',
        style: {
            'shape': 'ellipse',
            'width': 30,
            'height': 30,
            'background-color': '#aa9514',
        }
    },
    {
        // Target Vertical - Sector
        selector: 'node[type="target_vertical_sector"][?root]',
        style: {
            'shape': 'ellipse',
            'width': 30,
            'height': 30,
            'background-color': '#aa9514',
            "color": '#fafafa',
            'font-weight': 'bold'
        }
    },
    {
        selector: 'node[type="target_vertical_sector"]',
        style: {
            'shape': 'ellipse',
            'width': 30,
            'height': 30,
            'background-color': '#aa9514',
        }
    },
    {
        // TTP
        selector: 'node[type="ttp"][?root]',
        style: {
            'shape': 'ellipse',
            'width': 30,
            'height': 30,
            'background-color': '#003a8c',
            "color": '#fafafa',
            'font-weight': 'bold'
        }
    },
    {
        selector: 'node[type="ttp"]',
        style: {
            'shape': 'ellipse',
            'width': 30,
            'height': 30,
            'background-color': '#003a8c',
        }
    },
    {
        // TTP - Category
        selector: 'node[type="ttp_category"][?root]',
        style: {
            'shape': 'ellipse',
            'width': 30,
            'height': 30,
            'background-color': '#003a8c',
            "color": '#fafafa',
            'font-weight': 'bold'
        }
    },
    {
        selector: 'node[type="ttp_category"]',
        style: {
            'shape': 'ellipse',
            'width': 30,
            'height': 30,
            'background-color': '#003a8c',
        }
    },
    {
        // TTP - ID
        selector: 'node[type="ttp_id"][?root]',
        style: {
            'shape': 'ellipse',
            'width': 30,
            'height': 30,
            'background-color': '#003a8c',
            "color": '#fafafa',
            'font-weight': 'bold'
        }
    },
    {
        selector: 'node[type="ttp_id"]',
        style: {
            'shape': 'ellipse',
            'width': 30,
            'height': 30,
            'background-color': '#003a8c',

        }
    },
    {
        // Registry Key
        selector: 'node[type="registry_key"][?root]',
        style: {
            'shape': 'ellipse',
            'width': 30,
            'height': 30,
            'background-color': '#EF96BD',
            "color": '#fafafa',
            'font-weight': 'bold'
        }
    },
    {
        selector: 'node[type="registry_key"]',
        style: {
            'shape': 'ellipse',
            'width': 30,
            'height': 30,
            'background-color': '#EF96BD',

        }
    },


    // {
    //     selector: 'node[type="country"]',
    //     style: {
    //         'shape': 'ellipse',
    //         'width': 30,
    //         'height': 30,
    //         'font-weight': 'bold',
    //         'background-image': location_img,
    //         "background-fit": "cover cover",
    //         'background-opacity': 0,
    //         'background-clip': 'none'
    //     }
    // },
    // {
    //     selector: 'node[type="hashes"]',
    //     style: {
    //         'shape': 'ellipse',
    //         'width': 30,
    //         'height': 30,
    //         'font-weight': 'bold',
    //         'background-image': indicator_img,
    //         "background-fit": "cover cover",
    //         'background-opacity': 0,
    //         'background-clip': 'none'
    //     }
    // },
    // {
    //     selector: 'node[type="url"]',
    //     style: {
    //         'shape': 'ellipse',
    //         'width': 30,
    //         'height': 30,
    //         'font-weight': 'bold',
    //         'background-image': url_img,
    //         "background-fit": "cover cover",
    //         'background-opacity': 0,
    //         'background-clip': 'none'
    //     }
    // },
    // {
    //     selector: 'node[type="domain"]',
    //     style: {
    //         'shape': 'ellipse',
    //         'width': 30,
    //         'height': 30,
    //         'font-weight': 'bold',
    //         'background-image': domain_img,
    //         "background-fit": "cover cover",
    //         'background-opacity': 0,
    //         'background-clip': 'none'
    //     }
    // },
    // {
    //     selector: 'node[type="metadata"]',
    //     style: {
    //         'shape': 'ellipse',
    //         'width': 30,
    //         'height': 30,
    //         'font-weight': 'bold',
    //         'background-image': file_img,
    //         "background-fit": "cover cover",
    //         'background-opacity': 0,
    //         'background-clip': 'none'
    //     }
    // },
    // {
    //     selector: 'node[type="ip"]',
    //     style: {
    //         'shape': 'ellipse',
    //         'width': 30,
    //         'height': 30,
    //         'font-weight': 'bold',
    //         'background-image': ip_img,
    //         "background-fit": "cover cover",
    //         'background-opacity': 0,
    //         'background-clip': 'none'
    //     }
    // },
    {
        selector: 'node[type="topic_model_root"]',
        style: {
            'shape': 'ellipse',
            'width': 30,
            'height': 30,
            'font-weight': 'bold',
            'background-color': '#EF96BD',
            'label': 'data(label)'
        }
    },
    {
        selector: 'node[type="node"]',
        style: {
            'shape': 'ellipse',
            'width': 30,
            'height': 30,
            'background-color': '#38d4a5',
            'label': 'data(label)'
        }
    },
    {
        selector: 'node[type="unc"][?root]',
        style: {
            'shape': 'ellipse',
            'width': 30,
            'height': 30,
            'background-color': '#135200',
            'label': 'data(label)',
            "color": '#fafafa',
            'font-weight': 'bold'
        }
    },
    {
        selector: 'node[type="unc"]',
        style: {
            'shape': 'ellipse',
            'width': 30,
            'height': 30,
            'background-color': '#135200',
            'label': 'data(label)'
        }
    },
    {
        selector: 'node[type=""]',
        style: {
            'shape': 'ellipse',
            'width': 1,
            'height': 1,
            'background-color': '#acacac',
            'label': 'data(label)'
        }
    },
    {
        selector: '.searchterm',
        style: {
            'width': 40,
            'height': 40,
            'background-color': '#138585',
            'label': 'data(label)',
            "color": '#138585',
            'font-weight': 'bold'
        }
    },

    {
        selector: '.savegraphlayout',
        style: {
            'width': 30,
            'height': 30,
            'background-color': '#800080',
            'label': 'data(label)',
            "color": '#152238',
            'font-weight': 'bold'
        }
    },

    {
        selector: '.reloadgraphlayout',
        style: {
            'width': 30,
            'height': 30,
            'background-color': '#800080',
            'label': 'data(label)',
            "color": '#152238',
            'font-weight': 'bold'
        }
    },   
]