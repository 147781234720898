import CheckIcon from "@heroicons/react/24/outline/CheckIcon";
import * as Checkbox from "@radix-ui/react-checkbox";
import { clsx } from "clsx";
import React from "react";
import TDIcon from "../TDIcon";
import { TDCheckboxProps } from "./TDCheckbox.types";

export default function TDCheckbox({
  id,
  label,
  value = false,
  onChange,
  inputOnly = false,
  className,
}: TDCheckboxProps) {
  const rootClassName =
    "flex flex-row items-center justify-center bg-white rounded-full shadow-sm border border-slate-150 group rdx-state-checked:bg-primary rdx-state-checked:text-white rdx-state-unchecked:hover:border-primary-300 rdx-state-checked:hover:bg-primary-400 transition-colors rdx-state-unchecked:hover:bg-primary-50 focus:ring-2 focus:ring-primary-200";

  function handleOnChange(value) {
    if (id !== null) {
      onChange(value, { id });
    } else {
      onChange(value);
    }
  }
  return (
    <Checkbox.Root
      id={id}
      checked={value}
      onCheckedChange={handleOnChange}
      className={clsx(
        rootClassName,
        className,
        inputOnly && "p-1",
        !inputOnly && "p-1.5",
      )}
    >
      <Checkbox.Indicator className="bg-white rdx-state-checked:hidden">
        <div className="w-5 h-5 bg-primary rounded-full"></div>
      </Checkbox.Indicator>
      <div
        className="w-5 h-5 transition-colors group-hover:border-primary-300 border border-slate-300 bg-white 
      rounded-full flex items-center justify-center"
      >
        <TDIcon
          className="invisible group-rdx-state-checked:visible"
          iconSize="xs"
          color="text-primary"
        >
          <CheckIcon />
        </TDIcon>
      </div>
      {!inputOnly && (
        <label
          className="cursor-pointer px-2 mr-2 text-xs font-medium rdx-state-checked:text-white
         rdx-state-unchecked:text-slate-500 text-left"
          htmlFor={id}
        >
          {label}
        </label>
      )}
    </Checkbox.Root>
  );
}
