import ChevronLeftIcon from "@heroicons/react/24/outline/ChevronLeftIcon";
import ChevronRightIcon from "@heroicons/react/24/outline/ChevronRightIcon";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { RowOption, rowsPerPage } from "../../constants/types";
import TDIcon from "../TDIcon";
import TDIconButton from "../TDIconButton";
import TDSelect from "../TDSelect";
import { TDPaginationTypes } from "./TDPagination.types";

export default function TDPagination({
  className,
  startCount,
  endCount,
  totalCount,
  pageSize,
  pageNum = 1,
  onUpdate,
  rowsOption,
}: TDPaginationTypes) {
  // const pagination = {
  //   startCount,
  //   endCount,
  //   totalCount,
  //   pageSize,
  //   pageNum,
  // };
  let rowOptions: Array<RowOption> = [];
  const pagination = {
    startCount,
    endCount,
    totalCount,
    pageSize,
    pageNum,
    rowsOption,
  };
  // let isInternalPagination = false;

  useEffect(() => {
    // isInternalPagination = totalCount >= pageSize;
    // Using internal pagination
    // if (totalCount >= pageSize) {
    //   let calcStartCount = startCount;
    //   if (pagination.startCount == null) {
    //     if (pageNum == 1) {
    //       calcStartCount = 1;
    //     } else if (pageNum > 0) {
    //       calcStartCount = (pagination.pageNum - 1) * pageSize + 1;
    //     }
    //   }
    //   setPagination({
    //     ...pagination,
    //     startCount: calcStartCount,
    //     endCount: calcStartCount + (pagination.pageSize - 1),
    //   });
    // }
  }, []);

  function onUpdatePageSize(data: any) {
    pagination.pageNum = 1;

    updateData("pageSize", parseInt(data));
  }

  function updateData(key: keyof typeof pagination, value: number | Array<number>) {
    if (key == "rowsOption") {
      pagination[key] = value as Array<number>;
    } else {
      pagination[key] = value as number;
    }

    onUpdate != null && onUpdate(pagination);
  }

  function isEnablePrev() {
    return pageNum > 1;
  }

  function isEnableNext() {
    if (
      pagination.startCount != null &&
      // pagination.endCount != null &&
      totalCount != null
    ) {
      const currentCount = pagination.endCount - pagination.startCount;

      if (totalCount - pagination.endCount == 0) return false;
      if (currentCount + 1 == pageSize) return true;
    }

    return true;
  }

  function getRowsOption(): Array<RowOption> {
    if (rowsOption != null && rowsOption.length > 0) {
      const rowsOptionArr: Array<RowOption> = [];

      for (const value of rowsOption) {
        const valueStr = value.toString();

        rowsOptionArr.push({
          id: valueStr,
          name: valueStr,
        });
      }

      return rowsOptionArr;
    }

    return rowsPerPage;
  }

  return (
    <div
      className={clsx(
        className,
        "flex flex-row flex-wrap items-start justify-center text-xs text-slate-500",
      )}
    >
      <div className="mr-auto mt-1 flex flex-row flex-nowrap items-center justify-center">
        <div className="mr-1">Rows per page:</div>
        <TDSelect
          onUpdate={onUpdatePageSize}
          data={getRowsOption()}
          value={pageSize?.toString()}
        />
      </div>
      <div className="w-4" />
      <div className="flex flex-row space-x-4 mr-auto mt-1 justify-center">
        <div className="flex whitespace-nowrap items-center justify-center">
          Results {pagination.startCount} - {pagination.endCount} of {totalCount}
        </div>
        <div className="flex space-x-1 justify-center items-center">
          <TDIconButton
            onClick={(e) => isEnablePrev() && updateData("pageNum", --pageNum)}
            btnType="secondary"
            variant="ghost"
            iconSize="xs"
            disabled={!isEnablePrev()}
          >
            <TDIcon color="text-slate-400" iconSize="xs">
              <ChevronLeftIcon />
            </TDIcon>
          </TDIconButton>
          <div>{pageNum}</div>
          <TDIconButton
            onClick={(e) => isEnableNext() && updateData("pageNum", ++pageNum)}
            btnType="secondary"
            variant="ghost"
            iconSize="xs"
            disabled={!isEnableNext()}
          >
            <TDIcon color="text-slate-400" iconSize="xs">
              <ChevronRightIcon />
            </TDIcon>
          </TDIconButton>
        </div>
      </div>
    </div>
  );
}
