import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { TDButton, TDCheckbox } from "tau-design-system";
import {
  ATTACK_PATTERN,
  CAMPAIGN,
  IDENTITY,
  INDICATOR,
  INTRUSION_SET,
  LOCATION,
  MALWARE,
  OBSERVED_DATA,
  REPORT,
  STIX_MAPPING,
  THREAT_ACTOR,
  VULNERABILITY,
} from "../../../constants/stix_objects";
import { retrieve_stix_data } from "../../APIs/stix_search_api";
import SearchDateField from "../../common_route_components/SearchDateField";
import CheckBoxTable from "./route_component/CheckBoxTable";
import ListOfGuidedSearchBar from "./route_component/ListOfGuidedSearchBar";

const styles = () => ({
  root: {
    paddingBottom: "30px",
  },
  // logo: {
  //     margin: '0 auto',
  //     textAlign: 'center',
  //     height: '150px',
  //     display: 'block'
  // },
  SearchBarContainer: {
    marginTop: "10px",
  },
  formControl: {
    border: "2px solid",
    padding: "10px",
  },
  loaderContainer: {
    position: "absolute",
    left: "50%",
  },
  graphContainer: {
    border: "2px solid",
    padding: "10px",
    backgroundColor: "#2aa58817",
  },
  // checkbox: {
  //     "&:hover": {
  //         color: '#1d2f5d'
  //     }
  // }
});

// No need to read
// Need to update
/*
    If there is too much lag, consider switching to redux store
    to store the table data instead of the state.
    Redux store has already been setup
 */
class StixSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      table_data: {},
      dates: {},
      data: {},
      nomaindata: false,
      loading: false,
      no_pub_date_state: true,
      checked_state: {
        [ATTACK_PATTERN]: true,
        [CAMPAIGN]: true,
        [IDENTITY]: true,
        [INDICATOR]: false,
        [INTRUSION_SET]: false,
        [LOCATION]: false,
        [MALWARE]: false,
        [OBSERVED_DATA]: false,
        [REPORT]: false,
        [THREAT_ACTOR]: false,
        [VULNERABILITY]: false,
      },
    };
    this.ESSearch = this.ESSearch.bind(this);
    this.handleCheckedBoxes = this.handleCheckedBoxes.bind(this);
    this.handleNoPubDateCheckBox = this.handleNoPubDateCheckBox.bind(this);
    this.handleUpdateDate = this.handleUpdateDate.bind(this);
  }

  async ESSearch() {
    this.setState({
      loading: true,
      data: [],
      nomaindata: false,
    });

    try {
      const parsed_dates = this.assignDate(); // Assigns start and end dates, before they are sent to backend
      // console.log(parsed_dates);
      const { data } = await retrieve_stix_data(
        this.props.stix_query_data,
        parsed_dates,
        this.state.no_pub_date_state,
      );
      // console.log(data);
      if (_.isEmpty(data)) {
        this.setState({ nomaindata: true });
      }
      this.setState({ data, loading: false });
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
    }
  }
  handleCheckedBoxes(value, event) {
    let new_state = {
      ...this.state.checked_state,
      [event.id]: value,
    };
    this.setState({ checked_state: new_state });
  }

  handleNoPubDateCheckBox(value, event) {
    this.setState({
      ...this.state.no_pub_date_state,
      [event.id]: value,
    });
  }

  handleUpdateDate(new_date) {
    // {'start_date': val, 'end_date': val}
    let new_state_date = { ...this.state.dates, ...new_date };
    this.setState({ dates: new_state_date });
  }

  assignDate() {
    // Assigns start_date and end_date if they are untouched in datepicker. Both are initially "undefined" when page first loads
    let start_date;
    let end_date;
    if (this.state.dates.start_date === undefined) {
      // Set start_date to beginning of datepicker
      start_date = moment("1900-01-01").format("YYYY-MM-DD");
    } else {
      // No change to start_date if it was defined by user
      start_date = this.state.dates.start_date;
    }
    if (this.state.dates.end_date === undefined) {
      // Set end_date to current date
      end_date = moment().format("YYYY-MM-DD");
    } else {
      // No change to end_date if it was defined by user
      end_date = this.state.dates.end_date;
    }
    return { start_date, end_date };
  }

  render_document() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Container style={{ marginTop: "80px" }}>
          <Grid>
            {/* <img className={classes.logo} src={logo} alt="logo"/> */}
            {/* <h3>Select date range (displaying only reports with Published Date): </h3> */}
            <div className="flex row space-x-4 mb-6">
              <SearchDateField handleUpdateDate={this.handleUpdateDate} />
              <TDCheckbox
                id="no_pub_date_state"
                label="Include reports with no Published dates"
                value={this.state.no_pub_date_state}
                onChange={this.handleNoPubDateCheckBox}
              ></TDCheckbox>
              {/* <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.no_pub_date_state}
                    onChange={this.handleNoPubDateCheckBox}
                    name="no_pub_date_state"
                    // color="secondary"
                    className={classes.checkbox}
                    style={{ color: "#1d2f5d" }}
                  />
                }
                label="Include reports with no Published dates"
              /> */}
            </div>
            <div className="flex row flex-wrap p-2 rounded-lg bg-slate-100 border border-slate-200">
              {Object.keys(STIX_MAPPING).map((key, index) => {
                let stix_object = STIX_MAPPING[key];
                let { title } = stix_object;
                let isChecked = this.state.checked_state[key];
                return (
                  <>
                    <TDCheckbox
                      className="m-1"
                      id={key}
                      label={title}
                      value={isChecked}
                      onChange={this.handleCheckedBoxes}
                    ></TDCheckbox>
                    {/* <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={isChecked}
                          onChange={this.handleCheckedBoxes}
                          name={key}
                          //  color="primary"
                          style={{ color: "#1d2f5d" }}
                        />
                      }
                      label={title}
                    /> */}
                  </>
                );
              })}
            </div>

            <ListOfGuidedSearchBar stix_types={this.state.checked_state} />
            <br />
            {/* <CustomisedBootstrapButton
                            onClick={this.ESSearch}>
                            Search
                        </CustomisedBootstrapButton> */}
            {/* <Button
              variant="contained"
              color="primary"
              onClick={this.ESSearch}
              style={{
                height: "35px",
                backgroundColor: "#1d2f5d",
              }}
            >
              Search
            </Button> */}
            <TDButton onClick={this.ESSearch}>Search</TDButton>
            <br />
            <br />
            <div>
              {this.state.loading && (
                <div className={classes.loaderContainer}>
                  <CircularProgress
                    style={{ color: "#1d2f5d" }}
                    disableShrink={true}
                  />
                </div>
              )}
              {this.state.nomaindata && <div>No Results Returned</div>}
              {!_.isEmpty(this.state.data) ? (
                <CheckBoxTable data={this.state.data} />
              ) : null}
            </div>
          </Grid>
        </Container>
      </div>
    );
  }
  render() {
    return <div>{this.render_document()}</div>;
  }
}

const mapStateToProps = (state) => {
  const { stix_query_data } = state;
  return { stix_query_data };
};

export default connect(mapStateToProps, {})(withStyles(styles)(StixSearch));
