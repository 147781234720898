import Tippy from 'tippy.js';

export const PopperObj = (dummyDomEle, type_str, ref) => {
    /*
        dummyDomEle: Popper Dom Element
        type_str: The type of node in string
        ref: Reference popper node
     */
    return new Tippy(dummyDomEle, {
        trigger: 'manual', // call show() and hide() yourself
        lazy: false, // needed for onCreate()
        hideOnClick: true,
        interactive: true,
        appendTo: () => document.body,
        onCreate: instance => {
            instance.popperInstance.reference = ref;
        }, // needed for `ref` positioning
        content: () => {
            let content = document.createElement('div');
            content.tabIndex = 0;
            content.style= "overflow:auto;max-height:400px;";
            content.innerHTML = type_str;
            return content;
        },

    });
}