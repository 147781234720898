import { useLottie } from "lottie-react";
import React from "react";
import searchEngineAnim from "./search-engine-anim.json";

export default function TDSearchAnim({}) {
  const options = {
    animationData: searchEngineAnim,
    loop: true,
  };

  const { View } = useLottie(options);

  return <div>{View}</div>;
}
