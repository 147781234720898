import {toast} from "react-toastify";

export const CommonToastEmitter = (props) => {
    toast.info(props,
{
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}