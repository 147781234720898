import CalendarIcon from "@heroicons/react/24/outline/CalendarIcon";
import XMarkIcon from "@heroicons/react/24/outline/XMarkIcon";
import * as Dialog from "@radix-ui/react-dialog";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import TDButton from "../TDButton";
import TDIcon from "../TDIcon";
import TDIconButton from "../TDIconButton";
import { TDDatePickerProps } from "./TDDatePicker.types";

export default function TDDatePicker({
  onChange,
  btnVariant = "outline",
  btnType = "secondary",
  valueFormat = "yyyy-MM-dd",
  placeholder,
  value,
}: TDDatePickerProps) {
  const [date, setDate] = useState(value ?? new Date());
  const [isOpen, setIsOpen] = useState(false);

  function parseLabel(date: Date) {
    if (value != null) return format(date, valueFormat);

    if (value == null && placeholder != null) {
      return placeholder;
    }
  }

  function onChangeDate(val: Date) {
    setDate(val);

    if (onChange != null) onChange(val);
  }

  function onOpenDatePicker(val: boolean) {
    setIsOpen(val);
  }

  useEffect(() => {
    setDate(value ?? new Date());
  }, [value]);

  return (
    <div>
      <Dialog.Root open={isOpen} onOpenChange={onOpenDatePicker}>
        <Dialog.Trigger asChild>
          <TDButton
            btnType={btnType}
            variant={btnVariant}
            icon={
              <TDIcon iconSize="xs">
                <CalendarIcon />
              </TDIcon>
            }
            iconPos="RIGHT"
          >
            <div className="font-medium text-xs">{parseLabel(date)}</div>
          </TDButton>
        </Dialog.Trigger>
        <Dialog.Overlay className="bg-slate-800/90 w-screen h-screen fixed top-0 left-0 backdrop-blur-sm z-40" />
        <Dialog.Content className="w-auto h-auto">
          <div className="z-[90] fixed -translate-x-1/2 -translate-y-1/2 bg-white top-1/2 left-1/2 p-4 rounded-lg shadow-lg">
            <Dialog.Close asChild>
              <TDIconButton
                className="absolute right-0 top-2 translate-x-1/2"
                iconSize="xs"
              >
                <TDIcon
                  iconSize="2xs"
                  className="group-rdx-state-open:rotate-180 transition-transform ease-in-out"
                >
                  <XMarkIcon />
                </TDIcon>
              </TDIconButton>
            </Dialog.Close>
            <Calendar onChange={onChangeDate} value={date} />
          </div>
        </Dialog.Content>
      </Dialog.Root>
    </div>
  );
}
