import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import React, { useState } from "react";
import Autosuggest from "react-autosuggest";
import { connect } from "react-redux";
import { SCIM_ELASTIC_MAP } from "../../../../constants/scim_elasticsearch_mapping";
import { SCIM_NAMES, STIX_MAPPING } from "../../../../constants/stix_objects";
import { update_stix_query_data } from "../../../../redux/actions";
import { request_autocomplete_suggestions } from "../../../APIs/stix_search_api";
import "../styles/auto-complete.css";

const useStyles = makeStyles(() => ({
  root: {
    marginTop: "10px",
  },
}));

// Need to update
// Need to retrieve
const ListOfGuidedSearchBar = (props) => {
  const [suggestions, setSuggestions] = useState([]);
  const classes = useStyles();
  const { stix_types } = props;
  let mappings = [];

  // get all the keys that are true:
  _.forEach(stix_types, (value, key) => {
    if (value) {
      const mapping = STIX_MAPPING[key].mapping;
      mappings.push(...mapping);
    }
  });

  // Remove duplicates
  mappings = [...new Set(mappings)];

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onSuggestionsFetchRequested = async ({ value }, entity) => {
    entity = SCIM_ELASTIC_MAP[entity].nested;
    const { data } = await request_autocomplete_suggestions(value, entity);
    data && setSuggestions(data);
  };

  const renderSuggestion = (suggestion) => suggestion.text;

  const onSuggestionSelected = (e, { suggestionValue }, scim_name) => {
    // This is where u update the state in the redux store
    props.update_stix_query_data(suggestionValue, scim_name);
  };

  return (
    <Grid className={classes.root} container spacing={3}>
      {mappings.map((value, index) => {
        const scim_name = value; // [attack_methods, ...]
        const scim_title = SCIM_NAMES[value].title;
        const inputProps = {
          value: props.stix_query_data[scim_name] || "",
          onChange: (e) => props.update_stix_query_data(e.target.value, scim_name),
        };
        return (
          <Grid key={value + "-" + index} item xs={4}>
            <div>
              <h4>{scim_title}</h4>
              <Autosuggest
                suggestions={suggestions}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                onSuggestionsFetchRequested={(e) =>
                  onSuggestionsFetchRequested(e, scim_name)
                }
                getSuggestionValue={(suggestion) => suggestion.text}
                renderSuggestion={renderSuggestion}
                onSuggestionSelected={(e, suggestion) =>
                  onSuggestionSelected(e, suggestion, scim_name)
                }
                inputProps={inputProps}
              />
            </div>
          </Grid>
        );
      })}
    </Grid>
  );
};

const mapStateToProps = (state) => {
  const { stix_query_data } = state;
  return { stix_query_data };
};

export default connect(mapStateToProps, { update_stix_query_data })(
  ListOfGuidedSearchBar,
);
