import clsx from "clsx";
import React from "react";

export default function TDProximityIcon({ className }: React.ComponentPropsWithRef<"svg">) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(className, "svg-container")}
    >
      <path
        d="M16 17C18.7614 17 21 14.7614 21 12C21 9.23858 18.7614 7 16 7C13.2386 7 11 9.23858 11 12C11 14.7614 13.2386 17 16 17Z"
        fill="currentColor"
      />
      <path
        d="M6 14.5C7.38071 14.5 8.5 13.3807 8.5 12C8.5 10.6193 7.38071 9.5 6 9.5C4.61929 9.5 3.5 10.6193 3.5 12C3.5 13.3807 4.61929 14.5 6 14.5Z"
        stroke="currentColor"
      />
    </svg>
  );
}
