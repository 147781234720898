export function open_pdf_viewer() {
  let pdf_container = document.getElementById("pdf_container");
  let summary_viewer = document.getElementById("summary_viewer");
  let pdf_iframe = document.getElementById("pdf_iframe");

  function getTransitionEndEventName() {
    let transitions = {
      transition: "transitionend",
      OTransition: "oTransitionEnd",
      MozTransition: "transitionend",
      WebkitTransition: "webkitTransitionEnd",
    };
    let bodyStyle = document.body.style;
    for (let transition in transitions) {
      if (bodyStyle[transition] !== undefined) {
        return transitions[transition];
      }
    }
  }
  function make_pdf_visible() {
    pdf_iframe.style.visibility = "visible";
    pdf_container.removeEventListener(transitionEndEventName, make_pdf_visible);
  }
  const transitionEndEventName = getTransitionEndEventName();
  pdf_container.addEventListener(transitionEndEventName, make_pdf_visible);
  //   summary_viewer.style.transform = "translateX(-50%)";
  pdf_container.style.transform = "tranlateX(0%)";
  summary_viewer.style.width = "50%";
  pdf_container.style.width = "50%";
  pdf_container.style.right = "0";
}

export function close_pdf_viewer() {
  let pdf_container = document.getElementById("pdf_container");
  let summary_viewer = document.getElementById("summary_viewer");
  let pdf_iframe = document.getElementById("pdf_iframe");

  pdf_iframe.style.visibility = "hidden";
  summary_viewer.style.transform = "translateX(0%)";
  summary_viewer.style.width = "100%";
  pdf_container.style.transform = "tranlateX(100%)";
  pdf_container.style.right = "-100";
  pdf_container.style.width = "0";
}
