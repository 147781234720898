import {
    SET_TOGGLER_DATA_TYPE_REFDOC,
    SET_TOGGLER_DATA_TYPE_REPORT,
    UPDATE_TOGGLE_EXPANSION_ALL, UPDATE_TOGGLE_EXPANSION_MULTIPLE,
    UPDATE_TOGGLE_EXPANSION_ONE
} from "../actionTypes";
import {DATA_DICT} from "../../constants/tables";
import update from 'immutability-helper';


const initialState = {
    expanded: Object.keys(DATA_DICT).map(() => false),
    expand_all: false
};

// Note: no function name!
export default function(state = initialState, action) {
    switch (action.type) {
        case SET_TOGGLER_DATA_TYPE_REPORT: {
            return {
                ...state,
                expanded: Object.keys(DATA_DICT).map(() => false),
            }
        }

        case SET_TOGGLER_DATA_TYPE_REFDOC: {
            const { ref_doc_dict } = action.payload;
            return {
                ...state,
                expanded: Object.keys(ref_doc_dict).map(() => false),
            }
        }

        case UPDATE_TOGGLE_EXPANSION_ALL: {
            // const { content } = action.payload;
            return {
                ...state,
                expand_all: !state.expand_all,
                expanded: state.expanded.map(() => !state.expand_all)
            };
        }
        case UPDATE_TOGGLE_EXPANSION_ONE: {
            const { id } = action.payload;
            return {
                ...state,
                expanded: update(state.expanded, {[id]: {$set: !state.expanded[id]}}),
            }
        }
        case UPDATE_TOGGLE_EXPANSION_MULTIPLE: {
            const { ids } = action.payload; // an array
            // create the payload
            const payload = {};
            for (const id of ids) {
                payload[[id]] = {$set: !state.expanded[id]}
            }
            return {
                ...state,
                expanded: update(state.expanded, payload),
            }
        }
        default:
            return state;
    }
}