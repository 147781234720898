import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import key from "weak-key";
import clsx from "clsx";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  tableWrapper: {
    maxHeight: 707,
    overflow: "auto",
  },
});

export default function TDDataTable(props) {
  const { columns, query, summaryOnly } = props;
  const rows = props.data;
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
    setPage(0);
  }

  return (
    <Paper className={classes.root}>
      <div className={clsx(classes.tableWrapper)}>
        <Table data-testid="result-table" stickyHeader>
          <TableHead>
            <TableRow className="rounded-t overflow-hidden">
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    fontSize: 14,
                    fontWeight: "bold",
                    backgroundColor: "#1A658A",
                    color: "#FFFFFF",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={key(row)}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    if (column.id === "file_name") {
                      return (
                        <TableCell key={column.id} align={column.align}>
                          <a
                            target="_blank"
                            href={`/report_detail?txt_file_path=${encodeURIComponent(
                              row["txt_file_path"],
                            )}&query=${query}&summary=${summaryOnly}`}
                            rel="noopener noreferrer"
                          >
                            {value}
                          </a>
                        </TableCell>
                      );
                    }
                    return (
                      <TableCell key={column.id} align={column.align}>
                        <div className="font-open-sans text-slate-700 font-medium text-sm">
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </div>
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          "aria-label": "previous page",
        }}
        nextIconButtonProps={{
          "aria-label": "next page",
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
