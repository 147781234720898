import ChevronDownIcon from "@heroicons/react/24/outline/ChevronDownIcon";
import * as Accordion from "@radix-ui/react-accordion";
import clsx from "clsx";
import React from "react";
import TDIcon from "../TDIcon";
import { TDAccordionItemProps, TDAccordionProps } from "./TDAccordion.types";
import TDAccordionItem from "./TDAccordionItem";

export default function TDAccordion({ items = [], className }: TDAccordionProps) {
  return (
    <Accordion.Root
      className={clsx("w-full space-y-2", className)}
      type="single"
      defaultValue="item-1"
      collapsible
    >
      {items.map((item, i) => (
        <Accordion.Item key={i} value={`item-${i + 1}`} className="rounded-md">
          <TDAccordionItem {...item} />
        </Accordion.Item>
      ))}
    </Accordion.Root>
  );
}
