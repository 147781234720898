import clsx from "clsx";
import React from "react";
import { TDLabelFieldType } from "./TDLabelField.types";

/* 
TODO: Create GroupLabelField that is able to render labels with uniform width 
*/

const TDLabelField = ({
  label,
  labelPos = "left",
  children,
  isDense = false,
  isFull = false,
  className,
  labelClassName,
}: TDLabelFieldType) => {
  const labelPosMap = {
    left: "flex-row items-center",
    top: "flex-col justify-start items-start",
  };

  const posAxisMap = {
    left: "x",
    top: "y",
  };

  function denseClass() {
    if (posAxisMap[labelPos] == "x") {
      return `space-x-${isDense ? "2" : "4"}`;
    } else if (posAxisMap[labelPos] == "y") {
      return `space-y-${isDense ? "1" : "2"}`;
    }

    return "";
  }

  return (
    <div
      className={clsx(
        "flex text-slate-500",
        denseClass(),
        isFull ? "w-full" : "w-auto",
        labelPosMap[labelPos],
        className,
      )}
    >
      {label != null && (
        <div
          className={clsx("text-xs font-semibold whitespace-nowrap", labelClassName)}
        >
          {label}
        </div>
      )}
      <div className="w-full">{children}</div>
    </div>
  );
};

TDLabelField.displayName = "TDLabelField";

export default TDLabelField;
