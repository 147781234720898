import * as Collapsible from "@radix-ui/react-collapsible";
import clsx from "clsx";
import React from "react";
import TDIcon from "../TDIcon";
import { TDMenuItemProps } from "./TDMenuItem.types";

export default function TDMenuItem({
  name,
  icon,
  link,
  isActive = false,
  className,
  customIcon,
}: TDMenuItemProps) {
  return (
    <a
      href={link}
      className={clsx(
        isActive ? "text-primary" : "text-slate-500 group-1-hover:text-primary",
        "flex flex-row flex-nowrap items-center group-1-hover:no-underline space-x-2 text-sm whitespace-nowrap p-2",
        className
      )}
    >
      {customIcon != null && icon != null ? customIcon : <TDIcon>{icon}</TDIcon>}
      <Collapsible.Content>
        <span className="pr-12 whitespace-normal">{name}</span>
      </Collapsible.Content>
    </a>
  );
}
