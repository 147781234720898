import {remove_selected_node_type} from "./filtering_nodes";

export const generate_graph_stats = (cy, update_graph_metadata) => {
    /*
        This function generates the graph stats that show what type of nodes
        belong to which summary node.
        Note: url and domain are combined into one key
     */
    let metadata = {};
    let summary_report_nodes = cy.filter('node[type="summary_report"]');
    for (let report of summary_report_nodes) {
        let summary_report_label = report.data().label;
        metadata[summary_report_label] = {};
        let neighbor = report.neighborhood();
        for (let neigh of neighbor) {
            if (neigh.isNode()) {
                const node_type = neigh.data().type;
                if (node_type === 'url' || node_type === 'domain') {
                    metadata[summary_report_label]['url & domain'] = true;
                }
                else if (node_type === 'hashes' || node_type === 'metadata'){
                    metadata[summary_report_label]['hashes & metadata'] = true;
                }
                else {
                    metadata[summary_report_label][neigh.data().type] = true;
                }
            }
        }
    }
    update_graph_metadata(metadata);
    return metadata;
}

export const modify_unverified_edges = (cy) => {
    /*
        This function changes the edges style for summary report that are not verified
     */
    let filteredNodes = cy.filter('node[type="summary_report"][!verified]');
    let filteredNodesEdges = filteredNodes.connectedEdges();
    filteredNodesEdges.style('line-style', 'dotted');
}

export const filter_to_show_common_nodes = (cy) => {
    /*
        This function no longer relies on the backend to show common nodes but
        requires the parameter centrality that is calculated in the backend.
        Instead, It first calculates which are the common nodes and
        find the intermediate nodes that are attached to these common nodes
     */

    let possibleCommonNodes = cy.nodes('node[centrality>1]');
    let commonNodes = possibleCommonNodes.filter(node => {
        let connectedEdges = node.connectedEdges();
        let possibleSummaryNode = connectedEdges.connectedNodes();
        let summaryNodeConnected = possibleSummaryNode.filter(ele => {
            return ele.data().type === 'summary_report'
        })
        // Number of common nodes must be connected to the number of summary_report nodes
        return summaryNodeConnected.length === cy.filter('node[type="summary_report"]').length;
    })

    // commonNodes.forEach(ele => console.log("Common Node ", ele.data().label));

    let filteredSummary = cy.nodes('node[type="summary_report"]');      // Filter all summary Nodes
    let summaryNodesAndCommonNodes = commonNodes.union(filteredSummary)                // Combine summary nodes with the common nodes
    let connnectedEdgesToCommonNode = commonNodes.connectedEdges();
    let intermediateNodeConnectedToCommon = connnectedEdgesToCommonNode.connectedNodes(ele => {
        // If the intermediate node is not a common node and not a summary node
        // console.log("possible intermediate node: ", ele.data().label);
        return !commonNodes.toArray().includes(ele) && ele.data().type !== 'summary_report';
    })

    // intermediateNodeConnectedToCommon.forEach(ele => console.log("intermediate node ", ele.data().label));

    let connectedEdgesToIntermediateNode = intermediateNodeConnectedToCommon.connectedEdges();
    let secondaryNodeConnectedToIntermediate = connectedEdgesToIntermediateNode.connectedNodes(
        ele => {
            return !commonNodes.toArray().includes(ele)
                && ele.data().type !== 'summary_report'
                && !intermediateNodeConnectedToCommon.toArray().includes(ele);
        }
    );

    // secondaryNodeConnectedToIntermediate.forEach(ele => console.log("secondary node: ", ele.data().label));

    let filteredNodesAndEdges = summaryNodesAndCommonNodes
                                .union(connnectedEdgesToCommonNode)
                                .union(intermediateNodeConnectedToCommon)
                                .union(connectedEdgesToIntermediateNode)
                                .union(secondaryNodeConnectedToIntermediate)
                                .union(secondaryNodeConnectedToIntermediate.connectedEdges());
    let difference = cy.elements().difference(filteredNodesAndEdges)
    cy.remove(difference);
    return difference;
}

export const remove_edge_from_node = (node, node_map, cy) => {
    /*
        This function removes the edges from the summary_node
        and add them back when it is clicked again
     */

    let node_type = node.data().type;
    let node_label = node.data().label;

    if (node_type === 'summary_report') {
        if (node_label in node_map) {
            cy.add(node_map[node_label])
            delete node_map[node_label];
        } else {
            let connectedEdges = node.connectedEdges();
            node_map[node_label] = connectedEdges;
            cy.remove(connectedEdges);
        }
    }else{
        //localStorage.setItem("graph_node_search", node_label)
        window.open('/?search=' + node_label,'_blank')
        //window.open('/search?query=' + node_label + '&enable_anatomy_term_expansion=false&enable_soft_search=false&enable_partial_search=false&enable_proximity_search=false&enable_summary_search=false', '_blank');
    }
}

export const remove_selected_sub_nodes_handler = (filterNodes, metadata, prevMetadata, node_and_edges_store, cy) => {
    /*
        This function handles removing of selected node type by utilising the remove_selected_node_type function
        It gets the data from filterNodes to see what type of nodes are selected
        and metadata and prevMetadata are used to check whether the type of node should be removed or not!

        Most complex code i've ever written over here.
     */
    for (let [summary_node, entities] of Object.entries(filterNodes)) {
        // If the summary_node checkbox is selected
        if (typeof entities === 'boolean') {
            let allFalse = Object.keys(prevMetadata[summary_node]).every(function(k){ return prevMetadata[summary_node][k] === false })

            for (let [entity, checked] of Object.entries(metadata[summary_node])) {
                const prevChecked = prevMetadata[summary_node][entity];
                // If it is unchecked and it was previously checked
                if (!checked && prevChecked) {
                    node_and_edges_store[summary_node][entity] = remove_selected_node_type(cy, summary_node, entity);
                } else if (allFalse) {
                    node_and_edges_store[summary_node][entity].restore();
                }
            }

        } else {
            // If the attribute node under summary_node checkbox is selected
            for (let [entity, checked] of Object.entries(entities)) {
                if (!checked) {
                    node_and_edges_store[summary_node][entity] = remove_selected_node_type(cy, summary_node, entity);
                } else {
                    node_and_edges_store[summary_node][entity].restore();
                }
            }
        }
    }
}
