import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import { blue } from "@material-ui/core/colors";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import PropTypes from "prop-types";
import React from "react";
import { TDButton } from "tau-design-system";
import { DownloadAsCSV } from "../../utils/DownloadAsCSV";
import { DownloadAsJson } from "../../utils/DownloadAsJson";

const data_types = ["JSON", "CSV"];
const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

function SimpleDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open, raw_data, summary_fpath } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);

    if (value === "JSON") {
      DownloadAsJson(summary_fpath, raw_data);
    } else if (value === "CSV") {
      DownloadAsCSV(summary_fpath, raw_data);
    }
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="dialog-title" open={open}>
      <DialogTitle id="dialog-title">Select Report Format To Download</DialogTitle>
      <List>
        {data_types.map((data_type) => (
          <ListItem
            button
            onClick={() => handleListItemClick(data_type)}
            key={data_type}
          >
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <InsertDriveFileIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={data_type} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function DownloadSummaryButton(props) {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(data_types[1]);

  const { raw_data, summary_fpath } = props;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <div>
      <TDButton onClick={handleClickOpen}>Download Report Summary</TDButton>
      {/* <Button
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
        style={{ height: "35px", backgroundColor: "#1d2f5d" }}
      >
        Download Report Summary
      </Button> */}
      <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
        raw_data={raw_data}
        summary_fpath={summary_fpath}
      />
    </div>
  );
}
