import React, {useState} from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {DropzoneAreaBase} from "material-ui-dropzone";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {Typography} from "@material-ui/core";
import CustomisedTreeView from "./route_components/CustomisedTreeView";
import {retrieve_completed_ocr_data, retrieve_pending_data, upload_pdf_zip, process_data} from "../../APIs/upload_api";
import Loader from "../../common_route_components/Loader";
import {CommonToastContainer} from "../../common_route_components/CommonToastContainer";
import {CommonToastEmitter} from "../../common_route_components/CommonToastEmitter";
import CircularProgress from "@material-ui/core/CircularProgress";
import _ from 'lodash';

const useStyles = makeStyles(theme => createStyles({
    previewChip: {
        minWidth: 160,
        maxWidth: 210
    },
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    dropzone: {
        margin: 25,
    },
    uploadButton: {
        margin: '0px 25px 20px',
    },
    processButton: {
        width: '80%',
        margin: '25px 0px 0px',
    },
    progressBar: {
        margin: '0px 0px 0px 25px'
    },
    pendingOCR: {
        width: '80%',
        height: 250,
        border: 3,
        borderStyle: 'dashed',
        borderColor: 'rgba(0, 0, 0, 0.12)',
        borderRadius: 4,
        padding: theme.spacing(2),
        overflow: 'auto',
        boxSizing: 'border-box'
    },
    completedOCR: {
        height: 400,
        width: '60%',
        padding: theme.spacing(2),
        border: 3,
        borderStyle: 'dashed',
        borderColor: 'rgba(0, 0, 0, 0.12)',
        borderRadius: 4,
        overflow: 'auto',
        boxSizing: 'border-box'
    },
    loader: {
        position: 'absolute',
        width: '50%',
        height: 250,
        top: "50%",
        left: "50%",
        boxSizing: 'border-box',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    }
}));


const Upload = () => {
    const classes = useStyles();
    const [fileObjects, setFileObjects] = useState([]);
    const [progress, setProgress] = useState(1);
    const [loaderStatus, setLoaderStatus] = useState(false);
    const [processStatus, setProcessStatus] = useState(false);
    const [pendingJson, setPendingJson] = useState({});
    const [completedOCRJson, setCompletedOCRJson] = useState({});

    const get_pending_data = async () => {
        return await retrieve_pending_data();
    }

    const get_completed_ocr_data = async () => {
        return await retrieve_completed_ocr_data();
    }

    React.useEffect( () => {
        // Retrieve data for pending
        get_pending_data().then(res => setPendingJson(res.data));
        get_completed_ocr_data().then(res => setCompletedOCRJson(res.data));
    }, []);

    React.useEffect(() => {
        if (progress === 100) {

            // Fetch pending ocr data from the server
            //get_pending_data().then(res => setPendingJson(res.data));
        }
    }, [progress]);


    const handleSubmitToBackend = async () => {
        alert("for paid subscribers only.")
        return false;
        if (fileObjects.length > 0) {
            for (var i=fileObjects.length-1; i>-1; i--){
                setLoaderStatus(true);
                setProgress(1);
                let formData = new FormData();
                formData.append("file", fileObjects[i].file);
                //console.log(JSON.stringify(formData))
                await upload_pdf_zip(formData, setProgress, setLoaderStatus);
                const newFileObjs = [...fileObjects];
                newFileObjs.splice(-1, 1);
                setFileObjects(newFileObjs);
            }
            setFileObjects([]);
            get_pending_data().then(res => setPendingJson(res.data));
        }
    };

    const handleSubmitToProcess = async () => {
        alert("for paid subscribers only.")
        return false;
        setProcessStatus(true);
        await process_data();
        get_pending_data().then(res => setPendingJson(res.data));
        //get_completed_ocr_data().then(res => setCompletedOCRJson(res.data));
        setProcessStatus(false)
    };

    return (
        <div style={{marginTop:'102px', marginLeft: '55px'}}>
            <Grid container
                  spacing={1}
                  direction="row"
                  justify="center"
                  alignItems="center"
            >
                <Grid container direction="column" item xs={5} sm={5} justify="center">
                    <div className={classes.dropzone}>
                        <Typography variant="h5">
                            UPLOAD
                        </Typography>
                        <DropzoneAreaBase
                            filesLimit={20}
                            fileObjects={fileObjects}
                            onAdd={newFileObjs => {
                                setFileObjects([].concat(fileObjects, newFileObjs))
                            }}
                            onDelete={(deleteFileObj, index) => {
                                const newFileObjs = [...fileObjects];
                                newFileObjs.splice(index, 1);
                                setFileObjects(newFileObjs);
                            }}
                            maxFileSize={5000000000000}
                            dropzoneText={"Click here or Drag and Drop Files (Zip preferred)"}
                            showPreviews={true}
                            showPreviewsInDropzone={false}
                            useChipsForPreview={true}
                            previewGridProps={{container: { spacing: 1, direction: 'row' }}}
                            previewChipProps={{classes: { root: classes.previewChip } }}
                        />
                    </div>
                    <Button variant="contained"
                            color="primary"
                            disabled={processStatus}
                            className={classes.uploadButton}
                            onClick={() => handleSubmitToBackend()}
                            style={{backgroundColor: '#1d2f5d'}}
                    >
                        Upload
                    </Button>
                    {/*Upload Progress Bar*/}
                    {loaderStatus ? <div className={classes.progressBar}><Loader progress={progress}/></div>  : null}
                </Grid>

                <Grid container item xs={2} sm={2} justify="center">
                    {processStatus ? <CircularProgress disableShrink={true}/> : <ArrowForwardIosIcon fontSize="large" style={{color:'#1d2f5d'}}/>}
                </Grid>

                <Grid container direction="column" item xs={5} sm={5} justify="center">
                    <Typography variant="h5">
                        Files to be processed
                    </Typography>
                    <div className={classes.pendingOCR}>
                        {!_.isEmpty(pendingJson) && <CustomisedTreeView data={pendingJson}/>}
                    </div>
                    <Button variant="contained"
                            color="primary"
                            disabled={processStatus}
                            className={classes.processButton}
                            onClick={() => handleSubmitToProcess()}
                            style={{backgroundColor: '#1d2f5d'}}
                    >
                        Process
                    </Button>

                </Grid>
               { /*processStatus ? <div className={classes.loader}><CircularProgress disableShrink={true}/></div> : null*/}


            </Grid>
            <CommonToastContainer />
        </div>
    )

};

export default Upload;

/*
            CommonToastEmitter(
                <div>
                    File has been uploaded!
                </div>
            );
                 <Grid container item xs={2} sm={2} justify="center">
                    <ArrowForwardIosIcon fontSize="large" style={{color:'#1d2f5d'}}/>
                </Grid>

                <Grid container direction="column" item xs={5} sm={5} justify="center">
                    <Typography variant="h5">
                        Pending OCR
                    </Typography>
                    <div className={classes.pendingOCR}>
                        {!_.isEmpty(pendingJson) && <CustomisedTreeView data={pendingJson}/>}
                    </div>
                </Grid>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Typography variant="h5">
                        Completed OCR
                    </Typography>
                    <div className={classes.completedOCR}>
                        {!_.isEmpty(completedOCRJson) && <CustomisedTreeView data={completedOCRJson}/>}
                    </div>
                </Grid>
 */