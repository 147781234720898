import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const BootstrapButton = withStyles({
    root: {
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 12px',
        border: '1px solid',
        lineHeight: 1.5,
        backgroundColor: '#23272b',
        borderColor: '#343a40',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            backgroundColor: '#28a745',
            borderColor: '#1e7e34',
            boxShadow: '0 0 0 .2rem rgba(72,180,97,.5)',
        },
        '&:active': {
            boxShadow: '0 0 0 .2rem rgba(72,180,97,.5)',
            backgroundColor: '#28a745',
            borderColor: '#1e7e34',
        },
        '&:focus': {
            backgroundColor: '#28a745',
            borderColor: '#1e7e34',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        },
    },
})(Button);


const useStyles = makeStyles((theme) => ({
    color_green: {
        margin: theme.spacing(0),
        boxShadow: '0 0 0 .2rem rgba(72,180,97,.5)',
        backgroundColor: '#28a745',
        borderColor: '#1e7e34',
    },

    color_black: {
        margin: theme.spacing(0),
        backgroundColor: '#23272b',
        borderColor: '#343a40',
    },
}));

export default function CustomizedBootstrapButton(props) {
    const classes = useStyles();

    return (
        <div>
            <BootstrapButton
                variant="contained"
                color="secondary"
                disableRipple
                className={props.type ? classes.color_green: classes.color_black}
                onClick={props.onClick}
            >
                {props.children}
            </BootstrapButton>
        </div>
    );
}
