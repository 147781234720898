import clsx from "clsx";
import React from "react";

export default function TDAssociativeIcon({
  className,
}: React.ComponentPropsWithRef<"svg">) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(className, "svg-container")}
    >
      <path
        d="M15 13C17.7614 13 20 10.7614 20 8C20 5.23858 17.7614 3 15 3C12.2386 3 10 5.23858 10 8C10 10.7614 12.2386 13 15 13Z"
        fill="currentColor"
      />
      <path
        d="M6.5 18C8.433 18 10 16.433 10 14.5C10 12.567 8.433 11 6.5 11C4.567 11 3 12.567 3 14.5C3 16.433 4.567 18 6.5 18Z"
        fill="currentColor"
      />
      <path
        d="M14 20C15.1046 20 16 19.1046 16 18C16 16.8954 15.1046 16 14 16C12.8954 16 12 16.8954 12 18C12 19.1046 12.8954 20 14 20Z"
        fill="currentColor"
      />
    </svg>
  );
}
