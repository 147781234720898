import * as Tooltip from "@radix-ui/react-tooltip";
import React from "react";
import { TDToolTipProps } from "./TDToolTip.types";
import clsx from 'clsx';

export default function TDToolTip({
  message,
  delay = 300,
  children,
  pos = 'top',
  isDisabled = false,
}: TDToolTipProps) {
  let arrowOffset = null;

  if (pos == 'right') {
    arrowOffset = '-mt-px';
  }

  return (
    <>
      {!isDisabled && <Tooltip.Provider>
        <Tooltip.Tooltip delayDuration={delay}>
          {!isDisabled && message != null && message != "" && (
            <Tooltip.Content sideOffset={12} side={pos}>
              <Tooltip.TooltipArrow className={clsx("fill-primary", arrowOffset)} />
              <div className="px-4 py-1.5 bg-primary rounded-full text-white text-xs">
                {message}
              </div>
            </Tooltip.Content>
          )}
          <Tooltip.TooltipTrigger asChild>
            {children}
          </Tooltip.TooltipTrigger>
        </Tooltip.Tooltip>
      </Tooltip.Provider>}
      {isDisabled && children}
    </>
  );
}
