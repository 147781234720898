import React from 'react';
import Grid from "@material-ui/core/Grid";
import {Doughnut} from "react-chartjs-2";
import _ from 'lodash';

function TargetVerticalCharts(props) {

    const {handleRedirectLink, scim_lists} = props;

    if (!_.isEmpty(scim_lists)) {
        return Object.keys(scim_lists).map((key, index) => {
            if (key.includes("target_verticals_chart")){
                return (
                    <Grid 
                        container
                        direction="row"
                        alignItems="center" //To align vertically
                        xs={12} 
                        style={{height:'238px'}} //This height is calculated so that the dimension is balance, you can validate using the developer tools
                    >
                        <Doughnut
                            data={scim_lists[key]}
                            width='368px'
                            height='238px'
                            options={{
                                title:{
                                    fontColor:'#000000',
                                    display: false,
                                },
                                legend: {
                                    labels: {
                                        fontColor:'#000000',
                                        usePointStyle: true, //This is to make the legend coloured box into circle
                                        generateLabels: chart => chart.data.labels.map((l, i) => ({
                                            datasetIndex: 0,
                                            index: i,
                                            text: ((l.length > 24) ? l.substr(0, 20).padEnd(24, '.') : l.padEnd(24)),
                                            fillStyle: chart.data.datasets[0].backgroundColor[i], //Point Style Color
                                            strokeStyle: chart.data.datasets[0].backgroundColor[i], //Point Style Border Color
                                            hidden: false
                                          }))
                                    },
                                    display: true,
                                    position: "right",
                                    onHover: function (event, legendItem) {
                                      var chart = this.chart;
                                      var index = legendItem.index;
                                      var segment = chart.getDatasetMeta(0).data[index];
                                      chart.tooltip._active = [segment]
                                      chart.tooltip.update()
                                      chart.draw()
                                  },
                                    onLeave: function () {
                                      var chart = this.chart;
                                      chart.tooltip._active = []
                                      chart.tooltip.update()
                                      chart.draw()
                                  },
                                },
                                tooltips: {
                                    callbacks: {
                                        label: function(tooltipItem, data) {
                                            var dataset = data.datasets[tooltipItem.datasetIndex];
                                            var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                                            var total = meta.total;
                                            var currentValue = dataset.data[tooltipItem.index];
                                            var percentage = parseFloat((currentValue/total*100).toFixed(1));
                                            return currentValue + ' (' + percentage + '%)';
                                        },
                                        title: function(tooltipItem, data) {
                                            return data.labels[tooltipItem[0].index];
                                        }
                                    }
                                },
                                cutoutPercentage:80,
                                elements: {
                                    center: {
                                      text: 'Top 10',
                                      color: '#00474f', // Default is #000000
                                      fontStyle: 'Arial', // Default is Arial
                                      sidePadding: 20, // Default is 20 (as a percentage)
                                      minFontSize: 20, // Default is 20 (in px), set to false and text will not wrap.
                                      lineHeight: 25 // Default is 25 (in px), used for when text wraps
                                    }
                                  }
                            }}
                            plugins={{
                                beforeDraw: function(chart) {
                                    if (chart.config.options.elements.center) {
                                      // Get ctx from string
                                      var ctx = chart.chart.ctx;
                                
                                      // Get options from the center object in options
                                      var centerConfig = chart.config.options.elements.center;
                                      var fontStyle = centerConfig.fontStyle || 'Arial';
                                      var txt = centerConfig.text;
                                      var color = centerConfig.color || '#000';
                                      var maxFontSize = centerConfig.maxFontSize || 75;
                                      var sidePadding = centerConfig.sidePadding || 20;
                                      var sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)
                                      // Start with a base font of 30px
                                      ctx.font = "50px " + fontStyle;
                                
                                      // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
                                      var stringWidth = ctx.measureText(txt).width;
                                      var elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;
                                
                                      // Find out how much the font can grow in width.
                                      var widthRatio = elementWidth / stringWidth;
                                      var newFontSize = Math.floor(30 * widthRatio);
                                      var elementHeight = (chart.innerRadius * 2);
                                
                                      // Pick a new font size so it will not be larger than the height of label.
                                      var fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
                                      var minFontSize = centerConfig.minFontSize;
                                      var lineHeight = centerConfig.lineHeight || 25;
                                      var wrapText = false;
                                
                                      if (minFontSize === undefined) {
                                        minFontSize = 20;
                                      }
                                
                                      if (minFontSize && fontSizeToUse < minFontSize) {
                                        fontSizeToUse = minFontSize;
                                        wrapText = true;
                                      }
                                
                                      // Set font settings to draw it correctly.
                                      ctx.textAlign = 'center';
                                      ctx.textBaseline = 'middle';
                                      var centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
                                      var centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
                                      ctx.font = fontSizeToUse + "px " + fontStyle;
                                      ctx.fillStyle = color;
                                
                                      if (!wrapText) {
                                        ctx.fillText(txt, centerX, centerY);
                                        return;
                                      }
                                
                                      var words = txt.split(' ');
                                      var line = '';
                                      var lines = [];
                                
                                      // Break words up into multiple lines if necessary
                                      for (var n = 0; n < words.length; n++) {
                                        var testLine = line + words[n] + ' ';
                                        var metrics = ctx.measureText(testLine);
                                        var testWidth = metrics.width;
                                        if (testWidth > elementWidth && n > 0) {
                                          lines.push(line);
                                          line = words[n] + ' ';
                                        } else {
                                          line = testLine;
                                        }
                                      }
                                
                                      // Move the center up depending on line height and number of lines
                                      centerY -= (lines.length / 2) * lineHeight;
                                
                                      for (var n = 0; n < lines.length; n++) {
                                        ctx.fillText(lines[n], centerX, centerY);
                                        centerY += lineHeight;
                                      }
                                      //Draw text in center
                                      ctx.fillText(line, centerX, centerY);
                                    }
                                  }
                              }} 
                            getElementsAtEvent={elem => {
                                handleRedirectLink(elem, scim_lists[key]);
                            }}/>
                    </Grid>
                );
            }
        })
    } else {
        return null;
    }

}

export default TargetVerticalCharts;