import {
    DELETE_REFERENCE_DOC_STORE_SINGLE,
    UPDATE_REFERENCE_DOC_STORE,
    UPDATE_REFERENCE_DOC_STORE_SINGLE
} from "../actionTypes";
import update from 'immutability-helper';

const initialState = {};

// sample state = {
//     [WHITE_LIST]: {
//         name: WHITE_LIST,
//         title: "URL and Email White List",
//         header: [
//             { title: 'Name', field: 'name'},
//             { title: 'Test', field: 'test'}
//         ]
//     }
// }

// Note: no function name!
export default function(state = initialState, action) {
    switch (action.type) {
        case UPDATE_REFERENCE_DOC_STORE: {
            const { content } = action.payload;
            return {
                ...state,
                ...content,
            };
        }

        case UPDATE_REFERENCE_DOC_STORE_SINGLE: {
            const { table_name, content } = action.payload;
            return {
                ...state,
                [table_name]: {
                    ...state[table_name],
                    header: content
                }
            }
        }

        case DELETE_REFERENCE_DOC_STORE_SINGLE: {
            const { table_name, index } = action.payload;
            return {
                ...state,
                [table_name]: {
                    ...state[table_name],
                    header: update(state[table_name].header, {$splice: [[index, 1]]})
                }
            }
        }

        default:
            return state;
    }
}