import {THREAT_ACTORS} from "../../constants/tables";
import _ from 'lodash';
import React from 'react';
import {Link} from "react-router-dom";
import {EXCLUDE_HYPERLINK_FIELDS, EXCLUDE_PDF_SEARCH_FIELDS} from "../../constants/exclude_hyperlink_fields";
import {ReportStyle} from "../routes/report_generator/component_styles/ReportStyle";


// export const modify_data_to_dict = (data) => {
//     for (const [key, value] of Object.entries(data)) {
//         if (key in LIST_ITEMS) {
//             data[key] = value.map(val => ({[LIST_ITEMS[key]]: val}));
//         }
//     }
//     return data;
// };

export const modify_threat_actor_aliases = (data) => {
    /*
        This function convert ['a', 'b', 'c'] -> 'a,b,c'
     */
    _.forEach(data[THREAT_ACTORS], (threat_actor) => {
        if(!_.isEmpty(threat_actor["alias"])) {
            threat_actor["alias"] = threat_actor["alias"].toString();
        }
    });

    return data;
};

export const modify_back_threat_actor_aliases = (data) => {
    /*
        This function convert 'a,b,c' -> ['a', 'b', 'c']
    */
    _.forEach(data[THREAT_ACTORS], (threat_actor) => {

        try {
            if (!_.isEmpty(threat_actor["alias"])) {
                threat_actor["alias"] = threat_actor["alias"].split(',');
            }
        } catch (e) {
            console.log(e);
        }
    });
    return data;
};

export const modify_table_headers_to_have_links = (headers) => {
    /*
        _.assign is to merge the two objects together
     */
    _.forEach(headers, (header) => {
        const field = header.field;
        if (!EXCLUDE_HYPERLINK_FIELDS.includes(field)) {
            const hyperlink = {
                render: rowData => (
                    <Link to={{ pathname: '/',
                        search: `?search=${rowData[field]}`,
                        state: { redirect: true }
                    }}>{rowData[field]}</Link>
                )
            };
            _.assign(header, hyperlink);
        }
    });

    return headers;
};

export const modify_table_header_for_pdf_search = (headers, dispatcher) => {
    /*
        This function is used in the summary page to modify
        the table header to upload the search term into the redux
        store for pdf searching
     */
    _.forEach(headers, (header) => {
        const field = header.field;
        if (!EXCLUDE_PDF_SEARCH_FIELDS.includes(field)) {
            const searchable_value = {
                render: rowData => (
                    <div style={ReportStyle.table_contents} onClick={() => dispatcher(rowData[field])}>
                        {rowData[field]}
                    </div>
                )
            }
            _.assign(header, searchable_value);
        }
    });

    return headers;
}

export const remove_key_in_data_object = (data, key) => {
    let modified_data = _.cloneDeep(data);
    delete modified_data[key];
    return modified_data;
};

export const remove_key_in_list_of_objects = (data, name) => {
    /*
    {
        'alias_russia': [{a:1, b:2, c:3}],
        'alias_china' : ...
        delete c
    }
    */
    let modified_data = data;
    _.forEach(modified_data,(value) => {
        value.forEach((elem) => {
            delete elem[name];
        });
    });

    return modified_data;
};


export const modify_null_values_to_scim_unchanged = (data) => {
    /*
        converts all null type to "scim_unchanged"
   */
    let modified_data = data;
    _.forEach(modified_data, (value, key) => {
       if (_.isEmpty(value)) {
           modified_data[key] = 'scim_unchanged';
       }
    });

    return modified_data;
};

export const modify_data_for_matrix = (data, filenames) => {
    /*
        @params data: {entity: [list of filenames that contain the entity]}
        @params filenames: [list of filenames]
        This function converts the params into the data used for react-virtualized
        @returns: A list of list where each list represents a column
     */
    let table_data = [];
    let first_column = [''];
    // first row of data is the entities
    // second row onwards contains [filename, 'x' ... ]
    _.forEach(data, (value, key) => {
        first_column.push(key);
    });
    table_data.push(first_column)
    _.forEach(filenames, filename => {
        let column = [filename];
        _.forEach(data, (value) => {
            if (value.includes(filename)) {
                column.push('X');
            } else {
                column.push('');
            }
        });
        table_data.push(column);
    });

    return table_data;
}