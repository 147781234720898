import _ from 'lodash';
import {DATA_DICT} from "../../../../constants/tables";


export const verify_table_data_is_not_null = (data) => {
    /*
        For every key in the data_table constants,
        check whether the values in the data params
        is not null,
        if null, initialise an array
        ** This is to prevent page crash
     */
    _.forEach(DATA_DICT, (value, key) => {
       if (_.isEmpty(data[key])) data[key] = [];
    });

    return data;
};