import MomentUtils from "@date-io/moment";
import { createMuiTheme } from "@material-ui/core/styles";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import moment from "moment";
import React, { useState } from "react";
import { TDDatePicker, TDLabelField } from "tau-design-system";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#1d2f5d",
    },
  },
});

const dateFormat = "YYYY-MM-DD";

function SearchDateField(props) {
  const { handleUpdateDate, initStartDate = "2000-01-01" } = props;
  // const [startDate, setStartDate] = useState(null);
  // const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(
    moment(initStartDate, dateFormat).toDate(),
  );
  const [endDate, setEndDate] = useState(moment().toDate());

  const handleStartDateChange = (date) => {
    setStartDate(date);
    let start_date = moment(date).format(dateFormat);
    if (start_date === "Invalid date") {
      // when user clears date from datepicker
      start_date = moment(initStartDate).format(dateFormat);
    }
    handleUpdateDate({ start_date });
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    let end_date = moment(date).format(dateFormat);
    if (end_date === "Invalid date") {
      // when user clears date from datepicker
      end_date = moment().format(dateFormat);
    }
    handleUpdateDate({ end_date });
  };

  return (
    <div className="inline-flex row space-x-8">
      <TDLabelField className="justify-between" isDense label="Start date">
        <TDDatePicker
          onChange={handleStartDateChange}
          btnVariant="outline"
          value={startDate}
        />
      </TDLabelField>
      <TDLabelField className="justify-between" isDense label="End date">
        <TDDatePicker
          onChange={handleEndDateChange}
          btnVariant="outline"
          value={endDate}
        />
      </TDLabelField>
    </div>
  );
}

export default SearchDateField;
