import {CLEAR_METADATA, UPDATE_METADATA} from "../actionTypes";


const initialState = {};

// Note: no function name!
export default function(state = initialState, action) {
    switch (action.type) {
        case UPDATE_METADATA: {
            const { content } = action.payload;
            return {
                ...state,
                metadata: content,
            };
        }

        case CLEAR_METADATA: {
            return {}
        }

        default:
            return state;
    }
}