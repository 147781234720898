export const ADD_TODO = "ADD_TODO";
export const UPDATE_FILE_ATTRIBUTES = "UPDATE_FILE_ATTRIBUTES";
export const UPDATE_METADATA = "UPDATE_METADATA";
export const UPDATE_TABLE_DATA = "UPDATE_TABLE_DATA";

export const CLEAR_METADATA = "CLEAR_METADATA";
export const CLEAR_TABLE_DATA = "CLEAR_TABLE_DATA";

export const UPDATE_TOGGLE_EXPANSION_ALL = "UPDATE_TOGGLE_EXPANSION";
export const UPDATE_TOGGLE_EXPANSION_ONE = "UPDATE_TOGGLE_EXPANSION_ONE";
export const UPDATE_TOGGLE_EXPANSION_MULTIPLE = "UPDATE_TOGGLE_EXPANSION_MULTIPLE";
export const SET_TOGGLER_DATA_TYPE_REPORT = "SET_TOGGLER_DATA_TYPE_REPORT";
export const SET_TOGGLER_DATA_TYPE_REFDOC = "SET_TOGGLER_DATA_TYPE_REFDOC";

export const UPDATE_STIX_QUERY_DATA = "UPDATE_STIX_QUERY_DATA";

export const UPDATE_GRAPH_COMMON_NODES = "UPDATE_GRAPH_COMMON_NODES";
export const UPDATE_GRAPH_LAYOUT_TYPE = "UPDATE_GRAPH_LAYOUT_TYPE";
export const UPDATE_GRAPH_LOADING_BOOL = "UPDATE_GRAPH_LOADING_BOOL";
export const UPDATE_GRAPH_METADATA = "UPDATE_GRAPH_METADATA";
export const UPDATE_SUBGRAPH_METADATA_BOOL = "UPDATE_SUBGRAPH_METADATA_BOOL";
export const UPDATE_GRAPH_METADATA_BOOL = "UPDATE_GRAPH_METADATA_BOOL";
export const UPDATE_GRAPH_RELATIONSHIP_LENGTH = "UPDATE_GRAPH_RELATIONSHIP_LENGTH";
export const UPDATE_GRAPH_SENSITIVITY_LEVEL = "UPDATE_GRAPH_SENSITIVITY_LEVEL";
export const UPDATE_GRAPH_HIDE_UNC_BOOL = "UPDATE_GRAPH_HIDE_UNC_BOOL";
export const SET_GRAPH_SEARCH_TERM = "SET_GRAPH_SEARCH_TERM";
export const UPDATE_GRAPH_SHOW_SUBGRAPH = "UPDATE_GRAPH_SHOW_SUBGRAPH";

export const UPDATE_REFERENCE_DOC_STORE = "UPDATE_REFERENCE_DOC_STORE";
export const UPDATE_REFERENCE_DOC_STORE_SINGLE = "UPDATE_REFERENCE_DOC_STORE_SINGLE";
export const DELETE_REFERENCE_DOC_STORE_SINGLE = "DELETE_REFERENCE_DOC_STORE_SINGLE";

export const UPDATE_REPORT_PDF_SEARCH = "UPDATE_REPORT_PDF_SEARCH";
export const UPDATE_REPORT_PDF_URI = "UPDATE_REPORT_PDF_URI";