import clsx from "clsx";
import React from "react";

export default function TDNetworkGraphIcon({ className }: React.ComponentPropsWithRef<"svg">) {
  return (
    <svg
      className={clsx(className, "svg-container")}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
    >
      <g clipPath="url(#A)">
        <path
          d="M22.448 4.541c-.332-.326-.777-.519-1.247-.54s-.931.13-1.294.424-.598.71-.663 1.167.047.92.314 1.299l-3.899 5.485c-.295.016-.582.099-.839.243l-3.61-2.16v-.085c0-.49-.199-.959-.553-1.306a1.91 1.91 0 0 0-2.668 0c-.354.346-.553.816-.553 1.306 0 .234.046.465.135.682l-4.433 5.261c-.38-.05-.767.014-1.109.185a1.87 1.87 0 0 0-.804.77 1.81 1.81 0 0 0-.213 1.081c.043.373.201.725.454 1.008s.587.484.959.575.764.07 1.123-.062a1.88 1.88 0 0 0 .888-.677 1.82 1.82 0 0 0 .336-1.051c0-.267-.059-.531-.173-.774l4.373-5.188a1.92 1.92 0 0 0 1.293-.213l3.61 2.16v.085c0 .291.069.578.204.838s.33.485.57.656a1.91 1.91 0 0 0 .812.332c.294.047.595.024.878-.065s.541-.242.752-.447a1.84 1.84 0 0 0 .463-.733 1.81 1.81 0 0 0 .073-.858c-.045-.288-.159-.561-.332-.798l3.883-5.456c.367-.012.723-.129 1.024-.336a1.85 1.85 0 0 0 .667-.831 1.81 1.81 0 0 0 .093-1.053c-.075-.352-.253-.675-.513-.93l-.001.005z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="A">
          <path fill="#fff" transform="translate(1 4)" d="M0 0h22v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
