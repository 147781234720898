import React, { useState } from "react";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import {connect} from "react-redux";
import {update_report_file_attributes} from "../../../../redux/actions";

function DateField(props) {
    const [selectedDate, setSelectedDate] = useState(props.report_file_attributes['published_date']);

    const send_to_store = (date) => {
        setSelectedDate(date);
        const parsed_date = date.format('YYYY-MM-DD');
        props.update_report_file_attributes({"published_date": parsed_date});
    };

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                    clearable
                    value={selectedDate}
                    placeholder="No Date Found"
                    onChange={date => send_to_store(date)}
                    format="DD/MM/YYYY"
                />
        </MuiPickersUtilsProvider>
    );
}

const mapStateToProps = state => {
    const { report_file_attributes } = state;
    return { report_file_attributes }
};

export default connect(
    mapStateToProps,
    { update_report_file_attributes }
)(DateField);

