import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { getElevationClass } from "../../utils/helper";
import TDIcon from "../TDIcon";
import { TDTextFieldProps } from "./TDTextField.types";

const _defaultPlaceholder = "Enter value...";
const TDTextField = React.forwardRef<HTMLInputElement, TDTextFieldProps>(
  (
    {
      className,
      type = "primary",
      placeholder = _defaultPlaceholder,
      id,
      isRequired = true,
      icon,
      elevationLevel = type == "primary" ? "level_2" : "level_1",
      disableBorder = false,
      onUpdate,
      onEnter,
      onBlur,
      value = "",
      inputClassName,
      isLarge = false,
    }: TDTextFieldProps,
    ref,
  ) => {
    const [textValue, setTextValue] = useState(value);

    useEffect(() => {
      setTextValue(value);
    }, [value]);

    function onChange(val: string) {
      onUpdate != null && onUpdate(val);

      setTextValue(val);
    }

    return (
      <div
        className={clsx(
          className,
          getElevationClass(elevationLevel),
          type == "primary" && "bg-white",
          type == "secondary" && "bg-gray-100",
          !disableBorder && "border-slate-100",
          "w-full relative flex flex-row space-between text-slate-600  rounded-full overflow-hidden px-2",
        )}
      >
        {icon != null && (
          <div className="flex items-center pointer-events-none pl-2">
            <TDIcon color="text-slate-400" iconSize="sm">
              {icon}
            </TDIcon>
          </div>
        )}
        <input
          ref={ref}
          type="text"
          id={id}
          className={clsx(
            type == "primary" && "bg-white",
            type == "secondary" && "bg-gray-100",
            "block w-full text-slate-600 font-medium rounded-full focus:ring-primary focus:outline-none",
            isLarge ? "text-lg pl-3 pr-5 py-3" : "text-sm pl-2 pr-2 py-2",
            inputClassName,
          )}
          onChange={(e) => onChange(e.target.value)}
          onBlur={onBlur}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              onEnter != null && onEnter();
            }
          }}
          value={textValue}
          placeholder={placeholder != "" ? placeholder : _defaultPlaceholder}
          required={isRequired}
        />
      </div>
    );
  },
);

export default TDTextField;
