import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import clsx from "clsx";
import React from "react";
import { TDIconProps } from "./TDIcon.types";

const TDIconSizes = {
  "2xs": "w-3 h-3",
  xs: "w-3.5 h-3.5",
  sm: "w-4 h-4",
  m: "w-5 h-5",
  l: "w-8 h-8",
  xl: "w-16 h-16",
};

export default function TDIcon({
  className,
  color,
  iconSize = "m",
  label,
  children,
}: TDIconProps) {
  return (
    <React.Fragment>
      {/* @ts-ignore */}
      <AccessibleIcon.Root label={label}>
        <div
          className={clsx(
            className,
            TDIconSizes[iconSize],
            color,
            "flex items-center justify-center w-auto h-auto",
          )}
        >
          {children}
        </div>
      </AccessibleIcon.Root>
    </React.Fragment>
  );
}
