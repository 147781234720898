import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const StyledTableCell = withStyles((theme) => ({
  head: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor: "#e2e8f0",
    color: "#334155",
    fontWeight: "bold",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
    marginBottom: "30px",
  },
  table: {
    minWidth: 700,
  },
}));

export default function TDQueryExpansionTable(props) {
  const classes = useStyles();
  const expansion = props.data;

  return (
    <Paper className={classes.root}>
      <Table data-testid="expansion-table" className={classes.table}>
        <TableHead>
          <TableRow>
            <StyledTableCell>Original Words</StyledTableCell>
            <StyledTableCell align="left">Associated Words</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(expansion).map(([item, val]) => {
            return (
              <StyledTableRow key={item}>
                <StyledTableCell component="th" scope="row">
                  <div className="font-open-sans text-slate-700 font-medium text-sm">{item}</div>
                </StyledTableCell>

                <StyledTableCell align="left">
                  <div className="font-open-sans text-slate-700 font-medium text-sm">
                    {val + ""}
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  );
}
