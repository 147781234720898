import {
    COUNTRIES_INVOLVED,
    HASHES_MD5, HASHES_SHA1, HASHES_SHA256, IPS,
    THREAT_ACTORS,
    URLS,
} from "./tables";

export const SCIM_NEO4J_MAP = {
    /* Take note of hashes
    Hashes are not under this mapping
    we replace hashes_256 to hashes.256
     */
    [THREAT_ACTORS]: {label: 'apt'},
    [URLS]: {label: 'url'},
    [IPS]: {label: 'ip'},
    [COUNTRIES_INVOLVED]: {label: 'country'},
    [HASHES_MD5]: {label: 'hashes', property: 'md5'},
    [HASHES_SHA1]: {label: 'hashes', property: 'sha1'},
    [HASHES_SHA256]: {label: 'hashes', property: 'sha256'},
}

/*
    Neo4j Attribs
    1. Threat Actor
    2. IPS
    3. URLS
    4. Hashes
    5. Countries
 */