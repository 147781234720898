import SearchIcon from "@heroicons/react/24/outline/MagnifyingGlassIcon";
import clsx from "clsx";
import React from "react";
import TDTextField from "../TDTextField";
import { TDSearchBarProps } from "./TDSearchBar.types";

export default function TDSearchBar({
  className,
  type = "primary",
  actions,
  onUpdate,
  onEnter,
  value,
}: TDSearchBarProps) {
  return (
    <form className={className}>
      <div
        className={clsx(
          type == "primary" && "shadow-md bg-white",
          type == "secondary" && "bg-gray-100",
          "w-full relative flex flex-row space-between border-slate-100 text-slate-600  rounded-full overflow-hidden pr-2",
        )}
      >
        <TDTextField
          className="pl-2 pr-1"
          id="search"
          icon={<SearchIcon />}
          placeholder="Search..."
          elevationLevel="level_1"
          type={type}
          disableBorder
          onUpdate={onUpdate}
          onEnter={onEnter}
          value={value}
        />
        {type == "primary" && actions != null && (
          <div className="flex flex-row items-center justify-center space-x-2 py-2 ml-1">
            {actions}
          </div>
        )}
      </div>
    </form>
  );
}
