import {CLEAR_TABLE_DATA, UPDATE_TABLE_DATA} from "../actionTypes";

const initialState = {
};

// Note: no function name!
export default function(state = initialState, action) {
    switch (action.type) {
        case UPDATE_TABLE_DATA: {
            const { content } = action.payload;
            return {
                ...state,
                ...content,
            };
        }

        case CLEAR_TABLE_DATA: {
            return {}
        }

        default:
            return state;
    }
}