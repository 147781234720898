import ChevronRightIcon from "@heroicons/react/24/outline/ChevronRightIcon";
import * as Collapsible from "@radix-ui/react-collapsible";
import clsx from "clsx";
import React, { useState } from "react";
import TDIcon from "../TDIcon";
import TDIconButton from "../TDIconButton";
import TDMenuItem from "../TDMenuItem";
import TDToolTip from "../TDToolTip";
import { TDSideNavProps } from "./TDSideNav.types";

export default function TDSideNav({
  className,
  menuItems,
  onUpdate,
  currentUrl,
}: TDSideNavProps) {
  const initial = localStorage.getItem('sidenav-toggle');
  const defaultState = false;
  if (initial == null) {
    storeToggle(defaultState);
  }
  const parseInitial: boolean = readToggle();
  const [isOpen, setOpen] = useState(parseInitial);

  function storeToggle(state: boolean) {
    localStorage.setItem('sidenav-toggle', state.toString());
  }

  function readToggle(): boolean {
    const state = localStorage.getItem('sidenav-toggle') === "true";

    return state;
  }

  function onOpenChange(open: boolean) {
    setOpen(open);
  }

  function toggleOpen() {
    setOpen(!isOpen);
    storeToggle(!isOpen);
    onUpdate(!isOpen);
  }

  return (
    // TODO: Dynamically generate list items
    <Collapsible.Root
      onOpenChange={onOpenChange}
      open={isOpen}
      className="group h-full "
    >
      <div className={clsx(className)}>
        <nav className="transition-all py-2 h-full relative">
          <TDIconButton
            onClick={toggleOpen}
            className="absolute right-0 top-5 translate-x-1 z-30"
            iconSize="xs"
            tooltip={`${isOpen ? "Hide" : "Show"} sidebar`}
          >
            <TDIcon
              iconSize="2xs"
              className="group-rdx-state-open:rotate-180 transition-transform ease-in-out"
            >
              <ChevronRightIcon />
            </TDIcon>
          </TDIconButton>
          <div className="h-full bg-white rounded-2xl py-2 mx-2 max-w-[18rem] z-20">
            <ul className="px-2">
              {menuItems.map((item, index) => (
                <TDToolTip
                  key={index}
                  delay={250}
                  message={item.label}
                  pos='right'
                  isDisabled={isOpen}
                >
                  <li
                    className="px-4 py-4 group-1 hover:bg-primary/10 transition-colors rounded-md cursor-pointer"
                  >
                    <TDMenuItem
                      name={item.label}
                      icon={item.icon}
                      link={item.url}
                      isActive={currentUrl == item.url}
                    />
                  </li>
                </TDToolTip>
              ))}
            </ul>
          </div>
        </nav>
      </div>
    </Collapsible.Root>
  );
}
