import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import NotFoundLogo from '../../../assets/not_found.svg';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    },
    content: {
        paddingTop: 20,
        textAlign: 'center'
    },
    image: {
        marginTop: 50,
        display: 'inline-block',
        maxWidth: '100%',
        width: 560
    }
}));

const NotFound = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid
                container
                justify="center"
                spacing={4}
            >
                <Grid
                    item
                    lg={6}
                    xs={12}
                >
                    <div className={classes.content}>
                        <Typography variant="h4">
                            404: The page you are looking for isn’t here
                        </Typography>
                        <Typography variant="subtitle2">
                            You either tried some shady route or you came here by mistake.
                            Whichever it is, try using the navigation at the top.
                        </Typography>
                        <img
                            alt="Under development"
                            className={classes.image}
                            src={NotFoundLogo}
                        />
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default NotFound;
