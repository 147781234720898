import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";
import Brightness1Icon from "@material-ui/icons/Brightness1";
import Brightness1OutlinedIcon from "@material-ui/icons/Brightness1Outlined";
import React from "react";
import Legend from "../../../../assets/legend.png";
// import Brightness1TwoToneIcon from '@material-ui/icons/Brightness1TwoTone';
// import ArrowRightAltTwoToneIcon from '@material-ui/icons/ArrowRightAltTwoTone';
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import RemoveIcon from "@material-ui/icons/Remove";
import { TDButton } from "tau-design-system";

const insightNetworkStyles = makeStyles({
  shape_column: {
    width: "50%",
    height: "65px",
    display: "inline-block",
    textAlign: "center",
    verticalAlign: "middle",
  },
  text_column: {
    width: "50%",
    height: "65px",
    lineHeight: "65px",
    display: "inline-block",
    textAlign: "left",
    // verticalAlign: 'middle',
    color: "#ffffff",
  },
  text_column_inner: {
    display: "inline-block",
    verticalAlign: "middle",
    lineHeight: "normal",
  },
  searched_node_outer_circle: {
    width: "65px",
    height: "65px",
    color: "green",
  },
  associated_node_outer_circle: {
    width: "65px",
    height: "65px",
    color: "#fafafa",
  },
  target_vertical_node_inner_circle: {
    width: "65px",
    height: "65px",
    color: "#aa9514",
    padding: "5px",
    position: "absolute",
    left: 20.5,
  },
  country_node_inner_circle: {
    width: "65px",
    height: "65px",
    color: "#d87a16",
    padding: "5px",
    position: "absolute",
    left: 20.5,
  },
  hash_node_inner_circle: {
    width: "65px",
    height: "65px",
    color: "#a02669",
    padding: "5px",
    position: "absolute",
    left: 20.5,
  },
  campaign_node_inner_circle: {
    width: "65px",
    height: "65px",
    color: "#791A1F",
    padding: "5px",
    position: "absolute",
    left: 20.5,
  },
  ttp_node_inner_circle: {
    width: "65px",
    height: "65px",
    color: "#003a8c",
    padding: "5px",
    position: "absolute",
    left: 20.5,
  },
  unc_node_inner_circle: {
    width: "65px",
    height: "65px",
    color: "#135200",
    padding: "5px",
    position: "absolute",
    left: 20.5,
  },
  machine_learning_edge: {
    width: "65px",
    height: "65px",
    color: "#fafafa",
  },
  domain_knowledge_edge: {
    width: "65px",
    height: "65px",
    color: "#fafafa",
  },
  coexist_document_edge: {
    width: "65px",
    height: "65px",
    color: "#fafafa",
  },
  divider: {
    margin: 0,
  },
});

const conceptNetworkStyles = makeStyles({
  shape_column: {
    width: "50%",
    height: "65px",
    display: "inline-block",
    textAlign: "center",
    verticalAlign: "middle",
  },
  text_column: {
    width: "50%",
    height: "65px",
    lineHeight: "65px",
    display: "inline-block",
    textAlign: "left",
    // verticalAlign: 'middle',
    color: "#ffffff",
  },
  text_column_inner: {
    display: "inline-block",
    verticalAlign: "middle",
    lineHeight: "normal",
  },
  searched_node_outer_circle: {
    width: "65px",
    height: "65px",
    color: "green",
  },
  associated_node_outer_circle: {
    width: "65px",
    height: "65px",
    color: "#fafafa",
  },
  threat_actor_node_inner_circle: {
    width: "65px",
    height: "65px",
    color: "#791A1F",
    padding: "5px",
    position: "absolute",
    left: 20.5,
  },
  malware_name_node_inner_circle: {
    width: "65px",
    height: "65px",
    color: "#003a8c",
    padding: "5px",
    position: "absolute",
    left: 20.5,
  },
  campaign_name_node_inner_circle: {
    width: "65px",
    height: "65px",
    color: "#a02669",
    padding: "5px",
    position: "absolute",
    left: 20.5,
  },
  common_attribute_edge: {
    width: "65px",
    height: "65px",
    color: "#fafafa",
  },
  divider: {
    margin: 0,
  },
});

export default function LegendPopover(props) {
  const classes_concept = conceptNetworkStyles();
  const classes_insight = insightNetworkStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { legendType } = props;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "legend" : undefined;

  if (legendType === "CONCEPT_NETWORK_CONCEPT_MODEL") {
    return (
      <div>
        <TDButton className="w-full" btnType="secondary" onClick={handleClick}>
          Legend
        </TDButton>
        {/* <Button
          aria-describedby={id}
          variant="outlined"
          color="primary"
          onClick={handleClick}
          // style={{width: '100%', color:'#a6a6a6', borderColor: '#a6a6a6'}}
          style={{ width: "100%", color: "#FFFFFF", borderColor: "#FFFFFF" }}
        >
          Legend
        </Button> */}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          style={{ width: "100%" }}
        >
          <div
            style={{
              width: "207px",
              backgroundColor: "#424242",
              border: "2px solid #ffffff",
            }}
          >
            <div>
              <div className={classes_concept.shape_column}>
                <Brightness1OutlinedIcon
                  className={classes_concept.searched_node_outer_circle}
                />
                {/* <Brightness1Icon className={classes.threat_actor_node_inner_circle}/> */}
              </div>
              <div className={classes_concept.text_column}>
                <div className={classes_concept.text_column_inner}>
                  Searched Concept
                </div>
              </div>
            </div>
            <hr className={classes_concept.divider} />
            <div>
              <div className={classes_concept.shape_column}>
                <Brightness1OutlinedIcon
                  className={classes_concept.associated_node_outer_circle}
                />
              </div>
              <div className={classes_concept.text_column}>
                <div className={classes_concept.text_column_inner}>
                  Associated Concept
                </div>
              </div>
            </div>
            <hr className={classes_concept.divider} />
            <div>
              <div className={classes_concept.shape_column}>
                <Brightness1Icon
                  className={classes_concept.threat_actor_node_inner_circle}
                />
              </div>
              <div className={classes_concept.text_column}>
                <div className={classes_concept.text_column_inner}>Threat Actor</div>
              </div>
            </div>
            <hr className={classes_concept.divider} />
            <div>
              <div className={classes_concept.shape_column}>
                <Brightness1Icon
                  className={classes_concept.malware_name_node_inner_circle}
                />
              </div>
              <div className={classes_concept.text_column}>
                <div className={classes_concept.text_column_inner}>Malware</div>
              </div>
            </div>
            <hr className={classes_concept.divider} />
            <div>
              <div className={classes_concept.shape_column}>
                <Brightness1Icon
                  className={classes_concept.campaign_name_node_inner_circle}
                />
              </div>
              <div className={classes_concept.text_column}>
                <div className={classes_concept.text_column_inner}>Campaign</div>
              </div>
            </div>
            <hr className={classes_concept.divider} />
            <div>
              <div className={classes_concept.shape_column}>
                <RemoveIcon className={classes_concept.common_attribute_edge} />
              </div>
              <div className={classes_concept.text_column}>
                <div className={classes_concept.text_column_inner}>
                  Common Attributes
                </div>
              </div>
            </div>
          </div>
        </Popover>
      </div>
    );
  } else if (legendType === "CONCEPT_NETWORK_TOPIC_MODEL") {
    return (
      <div>
        <Button
          aria-describedby={id}
          variant="outlined"
          color="primary"
          onClick={handleClick}
          // style={{width: '100%', color:'#a6a6a6', borderColor: '#a6a6a6'}}
          style={{ width: "100%", color: "#FFFFFF", borderColor: "#FFFFFF" }}
        >
          Legend
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          {/* <img src={Legend} alt="legend" width="340.15748031" height="814.48818898"/> */}
          <div
            style={{
              width: "207px",
              backgroundColor: "#424242",
              border: "2px solid #ffffff",
            }}
          >
            <div>
              <div className={classes_insight.shape_column}>
                <Brightness1OutlinedIcon
                  className={classes_insight.searched_node_outer_circle}
                />
                {/* <Brightness1Icon className={classes.threat_actor_node_inner_circle}/> */}
              </div>
              <div className={classes_insight.text_column}>
                <div className={classes_insight.text_column_inner}>
                  Searched Insight
                </div>
              </div>
            </div>
            <hr className={classes_insight.divider} />
            <div>
              <div className={classes_insight.shape_column}>
                <Brightness1OutlinedIcon
                  className={classes_insight.associated_node_outer_circle}
                />
              </div>
              <div className={classes_insight.text_column}>
                <div className={classes_insight.text_column_inner}>
                  Associated Insight
                </div>
              </div>
            </div>
            <hr className={classes_insight.divider} />
            <div>
              <div className={classes_insight.shape_column}>
                <Brightness1Icon
                  className={classes_insight.target_vertical_node_inner_circle}
                />
              </div>
              <div className={classes_insight.text_column}>
                <div className={classes_insight.text_column_inner}>
                  Industry and Sector Target Vertical
                </div>
              </div>
            </div>
            <hr className={classes_insight.divider} />
            <div>
              <div className={classes_insight.shape_column}>
                <Brightness1Icon
                  className={classes_insight.country_node_inner_circle}
                />
              </div>
              <div className={classes_insight.text_column}>
                <div className={classes_insight.text_column_inner}>
                  Country, Domain and URL, Email, IP
                </div>
              </div>
            </div>
            <hr className={classes_insight.divider} />
            <div>
              <div className={classes_insight.shape_column}>
                <Brightness1Icon
                  className={classes_insight.hash_node_inner_circle}
                />
              </div>
              <div className={classes_insight.text_column}>
                <div className={classes_insight.text_column_inner}>Hash</div>
              </div>
            </div>
            <hr className={classes_insight.divider} />
            <div>
              <div className={classes_insight.shape_column}>
                <Brightness1Icon
                  className={classes_insight.campaign_node_inner_circle}
                />
              </div>
              <div className={classes_insight.text_column}>
                <div className={classes_insight.text_column_inner}>
                  Campaign, Malware, Threat Actor
                </div>
              </div>
            </div>
            <hr className={classes_insight.divider} />
            <div>
              <div className={classes_insight.shape_column}>
                <Brightness1Icon className={classes_insight.ttp_node_inner_circle} />
              </div>
              <div className={classes_insight.text_column}>
                <div className={classes_insight.text_column_inner}>
                  TTP, Attack Method, Vulnerability
                </div>
              </div>
            </div>
            {/* <hr className={classes_insight.divider}/>
                        <div>
                            <div className={classes_insight.shape_column}>
                                <Brightness1Icon className={classes_insight.unc_node_inner_circle}/>
                            </div>
                            <div className={classes_insight.text_column}>
                                <div className={classes_insight.text_column_inner}>
                                    Uncategorised
                                </div>
                            </div>
                        </div> */}
            <hr className={classes_insight.divider} />
            <div>
              <div className={classes_insight.shape_column}>
                <MoreHorizIcon className={classes_insight.machine_learning_edge} />
              </div>
              <div className={classes_insight.text_column}>
                <div className={classes_insight.text_column_inner}>
                  Machine Learning Association
                </div>
              </div>
            </div>
            <hr className={classes_insight.divider} />
            {/* <div>
                            <div className={classes_insight.shape_column}>
                                <MoreHorizIcon className={classes_insight.domain_knowledge_edge}/>
                            </div>
                            <div className={classes_insight.text_column}>
                                <div className={classes_insight.text_column_inner}>
                                    Domain Knowledge Association
                                </div>
                            </div>
                        </div>
                        <hr className={classes_insight.divider}/> */}
            <div>
              <div className={classes_insight.shape_column}>
                <RemoveIcon className={classes_insight.coexist_document_edge} />
              </div>
              <div className={classes_insight.text_column}>
                <div className={classes_insight.text_column_inner}>
                  Coexist in Document
                </div>
              </div>
            </div>
          </div>
        </Popover>
      </div>
    );
  }
}
