export const rootStyle = {
  paddingBottom: '30px',
};

export const divStyle = {
    width: '100%',
};
// export const headerStyle = {
//     margin: '0 auto',
//     textAlign: 'center',
//     fontSize: '2.5rem',
// };
export const logoStyle = {
    margin: 'auto',
    marginTop: '40px',
    marginBottom: '20px',
    textAlign: 'center',
    height: '150px',
    display: 'block'
};
