import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import React, { useState } from "react";
import { connect } from "react-redux";
import { TDButton, TDLabelField, TDSelect, TDTextField } from "tau-design-system";
import {
  CONCEPT,
  INSIGHT,
  STIX,
} from "../../../constants/concept_network_constants";
import { SCIM_NEO4J_MAP } from "../../../constants/scim_neo4j_mapping";
import CustomisedSearchbar from "./route_component/AutoCompleteSearchBar";

const useStyles = makeStyles({
  root: {
    marginTop: "80px",
    marginLeft: "55px",
  },
});

const conceptNetworkTypes = [
  { id: CONCEPT, name: "Concept" },
  { id: INSIGHT, name: "Insight" },
];

const ConceptNetworkSearch = () => {
  const classes = useStyles();
  const [currentType, setCurrentType] = useState(CONCEPT);
  const [currentVal, setCurrentVal] = useState("");
  const [entitySuggestionsDict, setEntitySuggestionsDict] = useState({});

  const handleGenerateGraph = () => {
    /*
            open a new tab and display the graph
         */
    if (currentType === CONCEPT) {
      // store the word in localstorage
      localStorage.setItem("concept_model_word", currentVal);
      window.open("/graphsearch?redirect=concept_model", "_blank");
    } else if (currentType === STIX) {
      const entity_type = entitySuggestionsDict[currentVal];

      if (entity_type) {
        let entity_label = _.get(SCIM_NEO4J_MAP[entity_type], "label", "");
        let entity_property = _.get(
          SCIM_NEO4J_MAP[entity_type],
          "property",
          SCIM_NEO4J_MAP[entity_type]["label"],
        );
        let entity_list = [[entity_label, entity_property, currentVal]];
        localStorage.setItem("entity_list", JSON.stringify(entity_list));
        window.open("/graphsearch?redirect=concept_entity", "_blank");
      }
    } else if (currentType === INSIGHT) {
      // store the word in localstorage
      localStorage.setItem("topic_model_word", currentVal);
      window.open("/graphsearch?redirect=concept_topic_model", "_blank");
    }
  };

  const handleEntityChange = (val) => {
    setCurrentType(val);
  };

  return (
    <div className="flex row space-x-4 mt-10 ml-4">
      <TDLabelField className="min-w-[10rem]" label="Type:" isDense>
        <TDSelect data={conceptNetworkTypes} onUpdate={handleEntityChange} />
      </TDLabelField>
      <TDTextField
        className="max-w-[15rem]"
        onUpdate={setCurrentVal}
        // value={data != null ? data.countryCode : "SN"}
      />
      {/* <CustomisedSearchbar
        type={currentType}
        setCurrentVal={setCurrentVal}
        setEntitySuggestionsDict={setEntitySuggestionsDict}
      /> */}
      <TDButton onClick={handleGenerateGraph}>View Network</TDButton>
      {/* <Button
        variant="contained"
        color="primary"
        onClick={handleGenerateGraph}
        style={{
          height: "56px",
          // marginTop: '10px',
          backgroundColor: "#1d2f5d",
          marginLeft: "5px",
        }}
      >
        View Network
      </Button> */}
      {/* <Container>
        <Grid container>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="outlined-age-native-simple">Type</InputLabel>

            <Select
              native
              value={currentType}
              onChange={handleEntityChange}
              label="Type"
              inputProps={{
                name: "type",
                id: "outlined-type",
              }}
            >
              <option value={CONCEPT}>Concept</option>
              <option value={INSIGHT}>Insight</option>
            </Select>
          </FormControl>

         
        </Grid>
      </Container> */}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { stix_query_data } = state;
  return { stix_query_data };
};

export default connect(mapStateToProps, {})(ConceptNetworkSearch);
