import ClockIcon from "@heroicons/react/24/outline/ClockIcon";
import DocumentTextIcon from "@heroicons/react/24/outline/DocumentTextIcon";
import TranslateIcon from "@heroicons/react/24/outline/LanguageIcon";
import LocationMarkerIcon from "@heroicons/react/24/outline/MapPinIcon";
import cslx from "clsx";
import React from "react";
import TDIconLabel from "../TDIconLabel";
import { TDGdeltResItemProps } from "./TDGdeltResItem.types";

export default function TDGdeltResItem({
  className,
  title,
  lang,
  location,
  timeStamp,
  source,
  url,
}: TDGdeltResItemProps) {
  return (
    <a href={url} target="_blank">
      <div className={cslx(className, "flex flex-col space-y-2 w-full")}>
        <div className="text-sm text-primary font-semibold max-w-[34rem]">
          {title}
        </div>
        <div className="flex flex-row justify-between space-x-24 items-end">
          <div className="flex flex-col justify-start items-start space-y-1">
            <TDIconLabel icon={<DocumentTextIcon />} label={source} />
            <TDIconLabel icon={<ClockIcon />} label={timeStamp} />
            <div className="flex flex-row space-x-3">
              <TDIconLabel icon={<TranslateIcon />} label={lang} />
              <TDIconLabel icon={<LocationMarkerIcon />} label={location} />
            </div>
          </div>
        </div>
      </div>
    </a>
  );
}
