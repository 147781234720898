export const ELASTIC_INDEX = 'scim';
export let CURRENT_ADDRESS, CURRENT_ELASTIC_ADDRESS;

if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
    CURRENT_ADDRESS = process.env.REACT_APP_DEV_SERVER_ADDRESS
    CURRENT_ELASTIC_ADDRESS = process.env.REACT_APP_DEV_ELASTIC_ADDRESS;
} else {
    CURRENT_ADDRESS = process.env.REACT_APP_PROD_SERVER_ADDRESS;
    CURRENT_ELASTIC_ADDRESS = process.env.REACT_APP_PROD_ELASTIC_ADDRESS;
}
let _API_GATEWAY_ADDRESS;
if (window.location.hostname.includes('tauexpress.ai')) {
    _API_GATEWAY_ADDRESS = '//api.' + window.location.hostname.replace('www.', '')
} else {
    _API_GATEWAY_ADDRESS = '//' + window.location.hostname + ':8080';
}
CURRENT_ADDRESS = _API_GATEWAY_ADDRESS + '/web-backend';

export const API_GATEWAY_ADDRESS = _API_GATEWAY_ADDRESS;
export const SODA_LOGIN = `${_API_GATEWAY_ADDRESS}/auth/login/`;
export const SODA_VERIFY = `${_API_GATEWAY_ADDRESS}/auth/verify/`;

export const API_ADDRESSES =
    {
        'search': `${CURRENT_ADDRESS}/search/`,
        'reports_summary': `${CURRENT_ADDRESS}/reports/summary/`,
        'reports_pdf': `${CURRENT_ADDRESS}/reports/pdf/`,
        'reports_docx': `${CURRENT_ADDRESS}/reports/docx/`,
        'reports_update': `${CURRENT_ADDRESS}/reports/update/info/`,
        'reports_unverify': `${CURRENT_ADDRESS}/reports/update/unverify/`,
        'reports_extract_single': `${CURRENT_ADDRESS}/reports/update/extractsingle/`,
        'refdoc': `${CURRENT_ADDRESS}/refdoc/`,
        'refdoc_update': `${CURRENT_ADDRESS}/refdoc/update/`,
        'guided_search': `${CURRENT_ADDRESS}/guidedsearch/`,
        'graph_search': `${CURRENT_ADDRESS}/graph/build/`,
        'statistics': `${CURRENT_ADDRESS}/dashboard/retrieve/`,
        'stix_search': `${CURRENT_ADDRESS}/stixsearch/search/`,
        'stix_generate_graph': `${CURRENT_ADDRESS}/stixsearch/create_graph/`,
        'autocomplete': `${CURRENT_ELASTIC_ADDRESS}/${ELASTIC_INDEX}/_search/`,
        'upload': `${CURRENT_ADDRESS}/upload/zip/`,
        'pendingOCR': `${CURRENT_ADDRESS}/upload/pendingOCR`,
        'completedOCR': `${CURRENT_ADDRESS}/upload/completedOCR`,
        'processData': `${CURRENT_ADDRESS}/upload/processData/`,
        'entity_generate_graph': `${CURRENT_ADDRESS}/conceptsearch/create_graph_entities/`,
        'topic_model_generate_graph': `${CURRENT_ADDRESS}/conceptsearch/create_graph_topic_model/`,
        'concept_model_generate_graph': `${CURRENT_ADDRESS}/conceptsearch/create_graph_concept_model/`
    };
