import React from "react";
import TDIcon from "../TDIcon";
import { TDIconLabelProps } from "./TDIconLabel.types";

export default function TDIconLabel({ icon, label }: TDIconLabelProps) {
  return (
    <div className="flex flex-row space-x-1.5 items-center justify-center text-2xs font-medium text-slate-500">
      <TDIcon iconSize="xs">{icon}</TDIcon>
      <div className="whitespace-nowrap">{label}</div>
    </div>
  );
}
