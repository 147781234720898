import React from 'react';
import ReactDOM from 'react-dom';
import './components/styles/index.css';
import './components/styles/App.css';
import 'react-toastify/dist/ReactToastify.css';
import App from './components/App';
import {Provider} from "react-redux";
import store from "./redux/store";
import * as serviceWorker from './serviceWorker';
import axios from "axios";
import {API_GATEWAY_ADDRESS, SODA_VERIFY} from "./constants/api_addresses";

//config axios to include cookie for all requests
axios.defaults.withCredentials = true;

//config axios to redirect to welcome page if 401 error
axios.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 401) {
        window.location.href = '/welcome';
    }
    return Promise.reject(error);
})

//check if url have access_token, if yes jump to auth/verify for login verification
if (window.location.href.includes('access_token=')) {
    let searchParams = new URLSearchParams(window.location.search.substring(1));
    if (searchParams.has('access_token')) {
        window.location.href = SODA_VERIFY + '?access_token=' + searchParams.get('access_token');
    }
}

//trigger a random API to check if user is logged in
axios.get(API_GATEWAY_ADDRESS+'/pis-en').then(res => {
    ReactDOM.render(
        <Provider store={store}>
            <App/>
        </Provider>,
        document.getElementById('root')
    );
})
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
