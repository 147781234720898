import cslx from "clsx";
import React from "react";
import { TDSearchResItemProps } from "./TDSearchResItem.types";

export default function TDSearchResItem({
  className,
  title,
  content,
  status,
  timeStamp,
}: TDSearchResItemProps) {
  return (
    <a href="/article-details">
      <div className={cslx(className, "flex flex-col space-y-2 w-full")}>
        <div className="text-sm text-primary font-semibold max-w-[34rem]">{title}</div>
        <div className="flex flex-row justify-between space-x-24 items-end">
          <div className="max-w-xl text-sm text-slate-500 font-medium">{content}</div>
          <div className="flex flex-col text-2xs font-medium text-slate-500 justify-end items-end">
            <div>{status}</div>
            <div className="whitespace-nowrap">{timeStamp}</div>
          </div>
        </div>
      </div>
    </a>
  );
}
