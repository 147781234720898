import React, { useState, useEffect } from "react";
import * as Tabs from "@radix-ui/react-tabs";
import { TDTabProps } from "./TDTabs.types";
import clsx from "clsx";

const TDTabs = ({ className, content, value, onUpdate }: TDTabProps) => {
  const triggerClass =
    "px-8 py-2 rounded-t-xl rdx-state-active:bg-primary rdx-state-active:text-white rdx-state-active:hover:bg-primary-600 hover:bg-gray-300 bg-gray-200 text-primary text-sm font-medium";
  const [tabValue, setTabValue] = useState(value);

  function onChange(val: string) {
    setTabValue(val);

    onUpdate != null && onUpdate(val);
  }

  useEffect(() => {
    setTabValue(value);
  }, [value]);

  return (
    <div className={clsx(className)}>
      <Tabs.Root defaultValue={value ?? content[0].id} value={tabValue} onValueChange={onChange}>
        <Tabs.List aria-label="Linkage Search">
          {content.map((tab) => (
            <Tabs.Trigger key={tab.id} className={triggerClass} value={tab.id}>
              {tab.header}
            </Tabs.Trigger>
          ))}
        </Tabs.List>
        <div className="bg-white rounded-b-xl shadow-md flex flex-col items-start border-t-4 border-primary justify-start w-auto p-4">
          {content.map((tab) => (
            <Tabs.Content key={tab.id} value={tab.id} asChild>
              {tab.children}
            </Tabs.Content>
          ))}
        </div>
      </Tabs.Root>
    </div>
  );
};

TDTabs.displayName = "TDTabs";

export default TDTabs;
