import CheckIcon from "@heroicons/react/24/outline/CheckIcon";
import ChevronDownIcon from "@heroicons/react/24/outline/ChevronDownIcon";
import * as Select from "@radix-ui/react-select";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import TDIcon from "../TDIcon";
import { TDSelectProps } from "./TDSelect.types";

export default function TDSelect({
  data,
  type,
  onUpdate,
  value,
  groupMeta = [{ name: "", startItemIndex: 0 }],
}: TDSelectProps) {
  const [selectVal, setSelectVal] = useState<string>(value ?? "");
  const [currCount, setCurrCount] = useState<number>(0);

  useEffect(() => {
    if (data != null && data.length > 0) {
      setSelectVal(data[0].id);
    }
  }, [data]);

  useEffect(() => {
    if (value != null) setSelectVal(value);
  }, [value]);

  function onValueChange(val: string) {
    setSelectVal(val);
    onUpdate != null && onUpdate(val);
  }

  function getName(id: string): string | undefined {
    return data?.find((item) => item.id == id)?.name;
  }

  return (
    <Select.Root value={selectVal} onValueChange={onValueChange}>
      <Select.Trigger
        className="group-1 relative text-sm focus-visible:outline-none
       flex flex-row items-center justify-center space-x-1"
      >
        <Select.Value aria-label={selectVal} asChild>
          <div className="group-1-hover:bg-gray-300 px-3 py-1 bg-gray-100 rounded-full text-xs font-medium text-slate-500 ml-1">
            {getName(selectVal)}
          </div>
        </Select.Value>
        <Select.Icon>
          <TDIcon iconSize="xs" color="text-slate-400">
            <ChevronDownIcon />
          </TDIcon>
        </Select.Icon>
      </Select.Trigger>
      <Select.Content className="bg-white rounded-md shadow-md p-2 border border-slate-100 z-50">
        <Select.Viewport>
          {groupMeta?.map((group, i) => (
            <Select.Group key={group.name}>
              {i > 0 && (
                <Select.Separator className="bg-slate-200 h-px mt-2"></Select.Separator>
              )}
              {group.name !== "" && group.name !== null && (
                <Select.Label className="text-xs text-slate-400 font-medium pl-8 py-2">
                  {group.name}
                </Select.Label>
              )}
              {data?.slice(group.startItemIndex, group.endItemIndex).map((item) => (
                <Select.Item
                  key={item.id}
                  className={clsx(
                    "cursor-pointer font-medium group flex flex-row items-center justify-start pl-8 pr-2 py-2 hover:text-white",
                    "hover:bg-primary rounded-md relative text-xs text-slate-500 focus-visible:outline-none",
                    value == item.id && "text-primary hover:text-white",
                  )}
                  value={item.id}
                >
                  <Select.ItemIndicator className="absolute left-2">
                    <TDIcon
                      iconSize="sm"
                      color="text-primary group-hover:text-white"
                    >
                      <CheckIcon />
                    </TDIcon>
                  </Select.ItemIndicator>
                  <Select.ItemText className="text-sm">{item.name}</Select.ItemText>
                </Select.Item>
              ))}
            </Select.Group>
          ))}
        </Select.Viewport>
      </Select.Content>
    </Select.Root>
  );
}
