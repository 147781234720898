import ChevronLeftIcon from "@heroicons/react/24/outline/ChevronLeftIcon";
import clsx from "clsx";
import React from "react";
import TDButton from "../TDButton";
import TDIcon from "../TDIcon";
import TDIconButton from "../TDIconButton";
import { TDBackButtonProps } from "./TDBackButton.types";

const TDBackButton = ({ className }: TDBackButtonProps) => {
  return (
    <TDButton
      btnType="secondary"
      variant="ghost"
      icon={
        <TDIcon iconSize="xs">
          <ChevronLeftIcon />
        </TDIcon>
      }
    >
      <div className="text-xs font-medium">Back</div>
    </TDButton>
  );
};

TDBackButton.displayName = "TDBackButton";

export default TDBackButton;
