import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
  CONCEPT_NETWORK_CONCEPT_MODEL,
  CONCEPT_NETWORK_STIX,
  CONCEPT_NETWORK_TOPIC_MODEL,
  STIX_GRAPH,
} from "../../../constants/graph_types";
import { update_graph_loading_bool } from "../../../redux/actions";
import {
  generate_graph_data_from_concept_word,
  generate_graph_data_from_entity,
  generate_graph_data_from_stix,
  generate_graph_data_from_topic_word,
} from "../../APIs/graph_api";
import { objHasKey } from "../../common_utils/util";
import { GraphToastContainer } from "./route_component/GraphToastContainer";
import { GraphToastEmitter } from "./route_component/GraphToastEmitter";
import GraphView from "./route_component/GraphView";
import Sidebar from "./route_component/Sidebar";

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  nodata: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "80vh",
    width: "calc(100% - 240px)",
    marginLeft: "auto",
  },
  loader: {
    position: "absolute",
    width: "50%",
    height: "80vh",
    top: "50%",
    left: "50%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
});

// No Need to update
// Need to read

class Graph extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      nomaindata: false,
      queries: {},
      currentGraphType: null,
      node_limit_exceeded: false,
      file_list: [],
      entity_list: [],
      topic_model_word: "",
      concept_model_word: "",
      cache_key: {},
    };
    this.handleQueryChange = this.handleQueryChange.bind(this);
    this.make_api_request_with_filelist =
      this.make_api_request_with_filelist.bind(this);
  }

  async make_api_request_with_filelist(queryObj) {
    this.setState({ nomaindata: false });
    try {
      let res;
      const file_list = _.get(queryObj, "file_list", null);
      const entity_list = _.get(queryObj, "entity_list", null);
      const topic_model_word = _.get(queryObj, "topic_model_word", null);
      const concept_model_word = _.get(queryObj, "concept_model_word", null);
      const relationship_length = _.get(queryObj, "relationship_length", 1);
      const sensitivity_level = _.get(queryObj, "sensitivity_level", 3);
      const hide_unc_node = _.get(queryObj, "hide_unc_node", false);
      const show_subgraph = _.get(queryObj, "show_subgraph", false);
      const graph_search_term = _.get(queryObj, "graph_search_term", null);

      if (file_list) {
        res = await generate_graph_data_from_stix(file_list);
      } else if (entity_list) {
        res = await generate_graph_data_from_entity(
          entity_list,
          relationship_length,
        );
      } else if (topic_model_word) {
        sessionStorage.setItem("topic_model_word", topic_model_word);
        res = await generate_graph_data_from_topic_word(
          topic_model_word,
          relationship_length,
          sensitivity_level,
          hide_unc_node,
          show_subgraph,
          graph_search_term,
        );
      } else if (concept_model_word) {
        sessionStorage.setItem("topic_model_word", concept_model_word);
        res = await generate_graph_data_from_concept_word(
          concept_model_word,
          relationship_length,
          sensitivity_level,
          hide_unc_node,
          show_subgraph,
          graph_search_term,
        );
      }
      const { data } = res;
      console.log(data);
      if (data.common_node) {
        this.setState({
          data,
          node_limit_exceeded: true,
        });
        GraphToastEmitter();
      } else {
        this.setState({ data });
      }

      if (!data || _.isEmpty(data.nodes)) {
        this.setState({ nomaindata: true });
        this.props.update_graph_loading_bool(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  handleQueryChange(value, heading) {
    const queries = this.state.queries;
    queries[heading] = value;
    this.setState({ queries: queries });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      window.location.reload();
    }

    if (
      this.props.graph_controls.relationship_length !==
      prevProps.graph_controls.relationship_length
    ) {
      const { topic_model_word, entity_list, currentGraphType, concept_model_word } =
        this.state;

      if (currentGraphType === CONCEPT_NETWORK_STIX) {
        this.make_api_request_with_filelist({
          entity_list,
          relationship_length: this.props.graph_controls.relationship_length,
        });
      } else if (currentGraphType === CONCEPT_NETWORK_TOPIC_MODEL) {
        let show_graph = true;

        if (this.props.graph_controls.graph_search_term === "") {
          show_graph = false;
        }

        this.make_api_request_with_filelist({
          topic_model_word: topic_model_word,
          relationship_length: this.props.graph_controls.relationship_length,
          sensitivity_level: this.props.graph_controls.sensitivity_level,
          hide_unc_node: this.props.graph_controls.hide_unc_node,
          show_subgraph: show_graph,
          graph_search_term: this.props.graph_controls.graph_search_term,
        });
      } else if (currentGraphType === CONCEPT_NETWORK_CONCEPT_MODEL) {
        let show_graph = true;

        if (this.props.graph_controls.graph_search_term === "") {
          show_graph = false;
        }

        this.make_api_request_with_filelist({
          concept_model_word: concept_model_word,
          relationship_length: this.props.graph_controls.relationship_length,
          sensitivity_level: this.props.graph_controls.sensitivity_level,
          hide_unc_node: this.props.graph_controls.hide_unc_node,
          show_subgraph: show_graph,
          graph_search_term: this.props.graph_controls.graph_search_term,
        });
      }
      this.props.update_graph_loading_bool(true);
    }

    if (
      this.props.graph_controls.sensitivity_level !==
      prevProps.graph_controls.sensitivity_level
    ) {
      const { topic_model_word, currentGraphType, concept_model_word } = this.state;

      if (currentGraphType === CONCEPT_NETWORK_TOPIC_MODEL) {
        let show_graph = true;

        if (this.props.graph_controls.graph_search_term === "") {
          show_graph = false;
        }

        this.make_api_request_with_filelist({
          topic_model_word: topic_model_word,
          relationship_length: this.props.graph_controls.relationship_length,
          sensitivity_level: this.props.graph_controls.sensitivity_level,
          hide_unc_node: this.props.graph_controls.hide_unc_node,
          show_subgraph: show_graph,
          graph_search_term: this.props.graph_controls.graph_search_term,
        });
      } else if (currentGraphType === CONCEPT_NETWORK_CONCEPT_MODEL) {
        let show_graph = true;

        if (this.props.graph_controls.graph_search_term === "") {
          show_graph = false;
        }

        this.make_api_request_with_filelist({
          concept_model_word: concept_model_word,
          relationship_length: this.props.graph_controls.relationship_length,
          sensitivity_level: this.props.graph_controls.sensitivity_level,
          hide_unc_node: this.props.graph_controls.hide_unc_node,
          show_subgraph: show_graph,
          graph_search_term: this.props.graph_controls.graph_search_term,
        });
      }
      this.props.update_graph_loading_bool(true);
    }

    if (
      this.props.graph_controls.hide_unc_node !==
      prevProps.graph_controls.hide_unc_node
    ) {
      const { topic_model_word } = this.state;

      let show_graph = true;

      if (this.props.graph_controls.graph_search_term === "") {
        show_graph = false;
      }

      this.make_api_request_with_filelist({
        topic_model_word,
        relationship_length: this.props.graph_controls.relationship_length,
        sensitivity_level: this.props.graph_controls.sensitivity_level,
        hide_unc_node: this.props.graph_controls.hide_unc_node,
        show_subgraph: show_graph,
        graph_search_term: this.props.graph_controls.graph_search_term,
      });
      this.props.update_graph_loading_bool(true);
    }

    if (
      this.props.graph_controls.show_subgraph !==
      prevProps.graph_controls.show_subgraph
    ) {
      const { topic_model_word, currentGraphType, concept_model_word } = this.state;

      if (currentGraphType === CONCEPT_NETWORK_TOPIC_MODEL) {
        let show_graph = true;

        if (this.props.graph_controls.graph_search_term === "") {
          show_graph = false;
        }

        this.make_api_request_with_filelist({
          topic_model_word: topic_model_word,
          relationship_length: this.props.graph_controls.relationship_length,
          sensitivity_level: this.props.graph_controls.sensitivity_level,
          hide_unc_node: this.props.graph_controls.hide_unc_node,
          show_subgraph: show_graph,
          graph_search_term: this.props.graph_controls.graph_search_term,
        });
      } else if (currentGraphType === CONCEPT_NETWORK_CONCEPT_MODEL) {
        let show_graph = true;

        if (this.props.graph_controls.graph_search_term === "") {
          show_graph = false;
        }

        this.make_api_request_with_filelist({
          concept_model_word: concept_model_word,
          relationship_length: this.props.graph_controls.relationship_length,
          sensitivity_level: this.props.graph_controls.sensitivity_level,
          hide_unc_node: this.props.graph_controls.hide_unc_node,
          show_subgraph: show_graph,
          graph_search_term: this.props.graph_controls.graph_search_term,
        });
      }

      this.props.update_graph_loading_bool(true);
    }
  }

  componentDidMount() {
    if (objHasKey(this.props, "search")) {
      if (!_.isEmpty(this.props.location.search)) {
        let searchParams = new URLSearchParams(this.props.location.search);
        if (searchParams.get("redirect") === "stix") {
          const file_list = JSON.parse(localStorage.getItem("file_list"));
          this.setState(
            {
              currentGraphType: STIX_GRAPH,
              file_list,
            },
            () => {
              this.make_api_request_with_filelist({ file_list });
            },
          );
        } else if (searchParams.get("redirect") === "concept_entity") {
          const entity_list = JSON.parse(localStorage.getItem("entity_list"));
          this.setState(
            {
              currentGraphType: CONCEPT_NETWORK_STIX,
              entity_list,
            },
            () => {
              this.make_api_request_with_filelist({ entity_list });
            },
          );
        } else if (searchParams.get("redirect") === "concept_topic_model") {
          const topic_model_word = localStorage.getItem("topic_model_word");
          this.setState(
            {
              currentGraphType: CONCEPT_NETWORK_TOPIC_MODEL,
              topic_model_word,
            },
            () => {
              this.make_api_request_with_filelist({ topic_model_word });
            },
          );
        } else if (searchParams.get("redirect") === "concept_model") {
          const concept_model_word = localStorage.getItem("concept_model_word");
          this.setState(
            {
              currentGraphType: CONCEPT_NETWORK_CONCEPT_MODEL,
              concept_model_word,
            },
            () => {
              this.make_api_request_with_filelist({ concept_model_word });
            },
          );
        }
      } else {
        this.props.history.push("/not-found");
      }
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        {this.state.nomaindata && (
          <div className={classes.nodata}>No Results Returned</div>
        )}
        {this.props.graph_controls.loading_spinner && (
          <div className={classes.loader}>
            <CircularProgress disableShrink={true} style={{ color: "#1d2f5d" }} />
          </div>
        )}
        {!_.isEmpty(this.state.data.nodes) ? (
          <GraphView data={this.state.data} file_list={this.state.file_list} />
        ) : null}
        <Sidebar
          currentGraphType={this.state.currentGraphType}
          disabled={this.state.node_limit_exceeded}
        />
        <GraphToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { graph_controls } = state;
  return { graph_controls };
};

export default connect(mapStateToProps, {
  update_graph_loading_bool,
})(withStyles(styles)(Graph));
