import {resolve} from "../common_utils/resolve";
import axios from "axios";
import {API_ADDRESSES} from "../../constants/api_addresses";

export const retrieve_report_summary_data = async (params) => {
    return await resolve(axios.get(API_ADDRESSES['reports_summary'], {params: params}).then(res => res.data));
}

export const post_report_update = async (data) => {
    return await resolve(axios.post(API_ADDRESSES['reports_update'], { posted_data: data }).then(res => res.data));
}

export const extract_single_report = async (data) => {
    return await resolve(axios.post(API_ADDRESSES['reports_extract_single'], { posted_data: data }).then(res => res.data));
}

export const unverify_report = async (summary_data) => {
    return await resolve(axios.post(API_ADDRESSES['reports_unverify'], { posted_data: summary_data }).then(res => res.data));
}

export const retrieve_pdf_blob = async (pdf_path) => {
    return await resolve(axios(`${API_ADDRESSES['reports_pdf']}?pdf_path=${encodeURIComponent(pdf_path)}`, {
        method: 'GET',
        responseType: 'blob'
    }).then(res => {
        return new Blob(
            [res.data],
            {type: 'application/pdf'}
        )
    }));
}