import clsx from "clsx";
import React from "react";
import TDToolTip from "../TDToolTip";
import { TDButtonProps } from "./TDButton.types";

const TDButtonSizes = {
  n: "",
  xs: "px-1.5 py-1.5",
  s: "px-2 py-1.5",
  m: "px-4 py-2",
};

const TDButton = React.forwardRef<HTMLButtonElement, TDButtonProps>(
  (
    {
      className,
      children,
      tooltip,
      icon,
      variant = "filled",
      btnType = "primary",
      iconPos = "LEFT",
      paddingSize = "m",
      iconOnly = false,
      onClick,
      preventDefault = false,
      disabled = false,
      label,
    }: TDButtonProps,
    ref,
  ) => {
    return (
      <TDToolTip message={tooltip}>
        <button
          ref={ref}
          onClick={(e) => {
            if (preventDefault) {
              e.preventDefault();
            }
            onClick != null && onClick(e);
          }}
          disabled={disabled}
          className={clsx(
            "text-sm rounded-full transition-colors flex flex-row flex-nowrap items-center justify-center space-x-1.5",
            TDButtonSizes[paddingSize],
            variant === "filled" &&
            btnType === "primary" &&
            "bg-primary hover:bg-primary-400",
            variant === "filled" &&
            btnType === "secondary" &&
            "bg-gray-200 text-slate-500 hover:bg-slate-300 hover:text-slate-600",
            variant === "filled" && " text-white",
            variant === "filled" &&
            iconOnly &&
            "hover:outline hover:outline-4 hover:outline-primary/10",
            variant === "outline" && "border border-primary",
            variant === "outline" &&
            btnType === "secondary" &&
            "border-slate-200",
            (variant === "outline" || variant === "ghost") &&
            btnType == "primary" &&
            `text-primary hover:text-white hover:bg-primary`,
            (variant === "outline" || variant === "ghost") &&
            btnType == "secondary" &&
            `text-slate-500 hover:bg-slate-150`,
            disabled && "disable-hover opacity-50",
            className,
          )}
        >
          {iconPos === "LEFT" && icon}
          <div className="whitespace-nowrap">
            {label != null ? label : children}
          </div>
          {iconPos === "RIGHT" && icon}
        </button>
      </TDToolTip>
    );
  },
);

TDButton.displayName = "TDButton";

export default TDButton;
