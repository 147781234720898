import _ from "lodash";
import queryString from "query-string";
import React, { Component } from "react";
import {
  TDAssociativeIcon,
  TDIconButton,
  TDLoader,
  TDProximityIcon,
  TDSearchAnim,
  TDSearchBar,
} from "tau-design-system";
import { retrieve_search_data } from "../../APIs/freetextsearch_api";
import { objHasKey } from "../../common_utils/util";
import { divStyle } from "./component_styles/FreeTextSearchStyle";
import TDDataTable from "./route_component/TDDataTable";
import TDQueryExpansionTable from "./route_component/TDQueryExpansionTable";

const columns = [
  { id: "file_name", label: "Source", minWidth: 300, align: "left" },
  { id: "snippet", label: "Snippet", minWidth: 300, align: "left" },
  // { id: 'txt_file_path',
  // 	label: 'File Path',
  // 	minWidth: 200,
  // 	align: 'left'},
  { id: "published_date", label: "Published Date", minWidth: 300, align: "left" },
];

export default class FreeTextSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      query: "",
      query_expansion: {},
      loading: false,
      nomaindata: false,
      noqueryexp: false,
      preciseSearch: false,
      associativeSearch: false,
      proximateSearch: false,
      summaryOnly: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handlePrecise = this.handlePrecise.bind(this);
    this.handleAssociative = this.handleAssociative.bind(this);
    this.handleProximate = this.handleProximate.bind(this);
    this.handleSummary = this.handleSummary.bind(this);
  }

  async search(proximity_search, soft_search) {
    let enable_proximity_search = false;
    let enable_soft_search = false;
    let enable_anatomy_term_expansion = false;
    let enable_partial_search = false;

    this.setState({
      data: [],
      loading: true,
      nomaindata: false,
      noqueryexp: false,
    });

    if (!proximity_search && !soft_search) {
      //activate Precise Search
      console.log("trigger precise search");
      enable_proximity_search = false;
      enable_soft_search = false;
      enable_anatomy_term_expansion = false;
      enable_partial_search = false;
    }
    if (proximity_search) {
      //activate proximity/proximate search
      console.log("trigger proximity search");
      enable_proximity_search = true;
      enable_soft_search = false;
      enable_anatomy_term_expansion = false;
      enable_partial_search = false;
    }
    if (soft_search) {
      //activate associative search (soft search + anatomy expansion)
      console.log("trigger associative search");
      enable_soft_search = true;
      enable_anatomy_term_expansion = true;
      enable_proximity_search = false;
    }

    let parameters = {
      query: this.state.query,
      enable_anatomy_term_expansion: enable_anatomy_term_expansion,
      enable_soft_search: enable_soft_search,
      enable_partial_search: enable_partial_search,
      enable_proximity_search: enable_proximity_search,
      enable_summary_search: this.state.summaryOnly,
    };

    try {
      const { data } = await retrieve_search_data(parameters);
      this.setState({ loading: false });
      if (_.isEmpty(data["search_results"])) {
        this.setState({
          nomaindata: true,
          noqueryexp: _.isEmpty(data["soft_search_expansion"]),
        });
      } else {
        let { search_results, soft_search_expansion } = data;
        let sorted_results = search_results.sort((a, b) => {
          return new Date(b.published_date) - new Date(a.published_date);
        });;

        this.setState({
          data: sorted_results,
          nomaindata: false,
          query_expansion: soft_search_expansion,
        });
      }
    } catch (error) {
      this.setState({
        loading: false,
        nomaindata: true,
      });
      if (error.response) {
        console.log(error.response);
        console.log("error message: " + error.response.data);
      }
    }
  }

  clearAll() {
    this.setState({
      data: [],
      query_expansion: {},
      loading: false,
      nomaindata: false,
      noqueryexp: false,
    });
  }

  handleChange(value) {
    this.setState({ query: value });
  }

  handlePrecise() {
    this.clearAll();
    this.setState({
      preciseSearch: true,
      associativeSearch: false,
      proximateSearch: false,
    });
    this.search(false, false);
  }

  handleAssociative() {
    this.clearAll();
    this.setState({
      preciseSearch: false,
      associativeSearch: true,
      proximateSearch: false,
    });
    this.search(false, true);
  }

  handleProximate() {
    this.clearAll();
    this.setState({
      preciseSearch: false,
      associativeSearch: false,
      proximateSearch: true,
    });
    this.search(true, false);
    this.setState({ noqueryexp: true });
  }

  handleSummary() {
    this.setState({ summaryOnly: !this.state.summaryOnly });
  }

  componentDidMount() {
    /*
    if (objHasKey(localStorage, 'graph_node_search')){
      const node_query = localStorage.getItem('graph_node_search')
      this.state.query = node_query
      this.handleChange(node_query)
      this.handlePrecise()
      localStorage.removeItem('graph_node_search', node_query)
    }*/

    if (objHasKey(this.props, "search")) {
      if (!_.isEmpty(this.props.location.search)) {
        const search_query = queryString.parse(this.props.location.search).search;

        // Trigger precise search after setting state
        this.setState({ query: search_query }, () => {
          this.handlePrecise();
        });
      }
    }
  }

  render() {
    return (
      <div className="max-h-[calc(100vh-5rem)] flex flex-col justify-start h-full w-full py-20 px-12">
        <div className="flex flex-row justify-between items-start space-x-10">
          <div className="flex flex-col w-full items-center justify-center">
            <div className="flex flex-col space-y-4 items-center justify-center">
              <TDSearchAnim></TDSearchAnim>
              <h1 className="text-2xl font-semibold text-slate-900">
                Intelligent search starts here.
              </h1>
            </div>
            <div
              className="w-full flex flex-row justify-center items-center"
              style={divStyle}
            >
              <TDSearchBar
                className="z-10 w-full my-14 max-w-[42rem] font-open-sans"
                onUpdate={this.handleChange}
                onEnter={this.handlePrecise}
                value={this.state.query}
                actions={
                  <>
                    <TDIconButton
                      onClick={this.handleAssociative}
                      iconSize="xs"
                      tooltip="Associative Search"
                      preventDefault
                    >
                      <TDAssociativeIcon className="text-white h-5 w-5" />
                    </TDIconButton>
                    <TDIconButton
                      onClick={this.handleProximate}
                      iconSize="xs"
                      tooltip="Proximity Search"
                      preventDefault
                    >
                      <TDProximityIcon className="text-white h-5 w-5" />
                    </TDIconButton>
                  </>
                }
              />
            </div>
          </div>
        </div>
        <div
          className="flex flex-col justify-center items-center"
          style={{ textAlign: "center" }}
        >
          {this.state.loading && <TDLoader svgClassName="my-8 mx-auto" />}
          {this.state.nomaindata && this.state.noqueryexp && (
            <div>No Results Returned</div>
          )}

          {!_.isEmpty(this.state.query_expansion) ? (
            <TDQueryExpansionTable data={this.state.query_expansion} />
          ) : null}

          {!_.isEmpty(this.state.data) ? (
            <div className="mt-6">
              <TDDataTable
                role="table"
                data={this.state.data}
                columns={columns}
                query={this.state.query}
                summaryOnly={this.state.summaryOnly}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
