import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import SaveIcon from "@material-ui/icons/Save";
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import PropTypes from 'prop-types';
import {screenShotHandler} from "../utils/ScreenshotHandler";
import {jsonHandler} from "../utils/JsonHandler";

const useStyles = makeStyles((theme) => ({
    root: {
        height: '90vh',
        transform: "translateZ(0px)",
        flexGrow: 1
    },
    speedDial: {
        position: "absolute",
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    }
}));

const toolTipStyles = makeStyles(() => ({
    staticTooltipLabel: {
        width: 150
    }
}));

const actions = [
    { icon: <PhotoCameraIcon />, name: "Take screenshot", key: 'screenshot'},
    { icon: <SaveIcon />, name: "Save Network as JSON", key: 'json'},
];


const GraphSpeedDial = ({cy, file_list}) => {
    const classes = useStyles();
    const toolTipClasses = toolTipStyles();
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClick = (key) => {
        let filename = file_list.join('-');
        if (file_list.length === 0) {
            let today = new Date();
            filename  = today.getHours() + "-" + today.getMinutes() + "-" + today.getSeconds();
            console.log(filename)
        }

        if (key === 'screenshot') {
            let data = cy.jpg({quality: 1, bg:"#14151a"});
            screenShotHandler({filename: `${filename}.jpeg`, data});
        }
        else if (key === 'json') {
            let data = cy.json().elements;
            jsonHandler({filename: `${filename}.json`, data});
        }
        setOpen(false);
    }

    return (
        <div className={classes.root}>
            <SpeedDial
                ariaLabel="SpeedDial tooltip example"
                className={classes.speedDial}
                hidden={false}
                icon={<SpeedDialIcon />}
                onClose={handleClose}
                onOpen={handleOpen}
                open={open}
                FabProps={{ style: { backgroundColor: "#1d2f5d" } }}
            >
                {actions.map((action) => (
                    <SpeedDialAction
                        classes={toolTipClasses}
                        TooltipClasses={toolTipClasses}
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        tooltipOpen
                        onClick={() => handleClick(action.key)}
                    />
                ))}
            </SpeedDial>
        </div>
    );
}

export default GraphSpeedDial;

GraphSpeedDial.propTypes = {
    cy: PropTypes.object.isRequired,
    file_list: PropTypes.array.isRequired,
}