import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Header from "./Header";
import Page from "./Page";

const App = () => (
  <React.Fragment>
    <Router>
      <Header>
        <Page />
      </Header>
    </Router>
  </React.Fragment>
);

export default App;
