import { ElevationLevel } from "../constants/enums";
import { ElevationLevelClass, rowsPerPage } from "../constants/types";

function getDefaultRowOptionValue(customOptions?: Array<number>): number {
  if (customOptions != null && customOptions.length > 0) {
    return customOptions[0];
  }

  return parseInt(rowsPerPage[0].id);
}

function getElevationClass(level: keyof typeof ElevationLevel): string {
  return ElevationLevelClass[level];
}


export { getElevationClass, getDefaultRowOptionValue };
