import cslx from "clsx";
import React from "react";
import TDButton from "../TDButton";
import { TDIconButtonProps } from "./TDIconButton.types";

const TDIconButtonSizes = {
  xs: "px-1.5 py-1.5",
  s: "px-2 py-2",
  m: "px-3 py-3",
};

const TDIconButton = React.forwardRef<HTMLButtonElement, TDIconButtonProps>(
  (
    {
      children,
      className,
      iconSize = "m",
      tooltip,
      variant,
      btnType,
      onClick,
      preventDefault = false,
      disabled = false,
    }: TDIconButtonProps,
    ref,
  ) => {
    return (
      <TDButton
        btnType={btnType}
        variant={variant}
        onClick={onClick}
        className={cslx(className, TDIconButtonSizes[iconSize])}
        paddingSize="n"
        iconOnly
        tooltip={tooltip}
        preventDefault={preventDefault}
        disabled={disabled}
      >
        {children}
      </TDButton>
    );
  },
);

TDIconButton.displayName = "TDIconButton";

export default TDIconButton;
