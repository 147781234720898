import { IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { PagesSharp } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import PinDropOutlinedIcon from "@material-ui/icons/PinDropOutlined";
import cytoscape from "cytoscape";
import avsdf from "cytoscape-avsdf";
import euler from "cytoscape-euler";
import fcose from "cytoscape-fcose";
import klay from "cytoscape-klay";
import popper from "cytoscape-popper";
import { clone } from "lodash";
import React, { Component } from "react";
import CytoscapeComponent from "react-cytoscapejs";
import { connect } from "react-redux";
import "tippy.js/dist/tippy.css";
import { AVSDF, EULER, FCOSE, KLAY } from "../../../../constants/graph_types";
import {
  update_graph_loading_bool,
  update_graph_metadata,
} from "../../../../redux/actions";
import {
  filter_to_show_common_nodes,
  generate_graph_stats,
  modify_unverified_edges,
  remove_edge_from_node,
  remove_selected_sub_nodes_handler,
} from "../utils/GraphViewHelpers";
import {
  avsdf_layout,
  euler_layout,
  fcose_layout,
  klay_layout,
} from "../utils/GraphViewLayout";
import { stylesheet } from "../utils/GraphViewStylesheet";
import { PopperObj } from "../utils/PopperObj";
import GraphSpeedDial from "./SpeedDial";
// import {content_menu} from "../utils/NodeContentMenu";
// import cxtmenu from 'cytoscape-cxtmenu';
cytoscape.use(avsdf);
cytoscape.use(fcose);
cytoscape.use(klay);
cytoscape.use(euler);
cytoscape.use(popper);
// cytoscape.use(cxtmenu);

// Need to update
// Need to read from store
const styles = () => ({
  root: {
    height: "100%",
    display: "flex",
    //marginLeft: 'auto',
    // marginTop: "55px",
    // marginLeft: "55px",
    // marginLeft: "100px",
    marginTop: "28px",
    //order: 2,
    // width: "calc(100% - 240px - 55px)",
    width: "calc(100% - 240px - 116px)",
  },
});

// const styles = () => ({
//     root: {
//         marginTop:'80px',
//         marginLeft: '55px',
//         width: 'calc(100% - 240px)',
//         height: '100%'
//     }
// });

function dragPopper(element) {
  var pos1 = 0,
    pos2 = 0,
    pos3 = 0,
    pos4 = 0;
  if (document.getElementById(element.id)) {
    /* if present, the header is where you move the DIV from:*/
    document.getElementById(element.id).onmousedown = dragMouseDown;
  } else {
    /* otherwise, move the DIV from anywhere inside the DIV:*/
    element.onmousedown = dragMouseDown;
  }

  function dragMouseDown(e) {
    e = e || window.event;
    e.preventDefault();
    // get the mouse cursor position at startup:
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag;
  }

  function elementDrag(e) {
    e = e || window.event;
    e.preventDefault();
    // calculate the new cursor position:
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    // set the element's new position:
    element.style.top = element.offsetTop - pos2 + "px";
    element.style.left = element.offsetLeft - pos1 + "px";
  }

  function closeDragElement() {
    /* stop moving when mouse button is released:*/
    document.onmouseup = null;
    document.onmousemove = null;
  }
}

const alert_box = (element_id) => {
  var element = document.getElementById(element_id);
  element.style.display = "inline-block";
  element.style.fontSize = "12px";
  element.style.backgroundColor = "#FFFFFF";
  element.style.color = "#000000";
  element.style.borderRadius = "5px";
  element.style.padding = "5px";
  element.innerHTML = "Copied!";
  setTimeout(function () {
    element.style.display = "none";
  }, 1000); // 1000ms = 1 second
};

function copyToClipboard(contentId) {
  var textToBeCopied = document.getElementById(contentId).innerHTML;
  var textToBeCopied_1 = textToBeCopied.replaceAll("<br>", "\n");
  var textToBeCopied_2 = textToBeCopied_1.replaceAll("</a>", "");
  var textToBeCopied_3 = textToBeCopied_2.replaceAll(
    /<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1\s+target=(["'](.*))>/g,
    "",
  );
  // navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    // navigator clipboard api method'
    return navigator.clipboard.writeText(textToBeCopied_3);
  } else {
    // text area method
    let textArea = document.createElement("textarea");
    textArea.value = textToBeCopied_3;
    // make the textarea out of viewport
    textArea.style.position = "absolute";
    textArea.style.opacity = 0;
    textArea.style.left = "-999999px";
    textArea.style.top = "-999999px";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    return new Promise((res, rej) => {
      // here the magic happens
      document.execCommand("copy") ? res() : rej();
      textArea.remove();
    });
  }
}

function removeHTMLDisplay(element_id) {
  var element = document.getElementById(element_id);
  element.style.display = "none";
}

function duplicatePopper(edge_id) {
  var original = document.getElementById("popper");
  var clone = original.cloneNode(true);
  clone.id = edge_id;
  clone.childNodes[0].id = "header_" + edge_id;
  clone.childNodes[0].childNodes[0].id = "title_" + edge_id;
  clone.childNodes[0].childNodes[1].childNodes[0].id = "alert_" + edge_id;
  clone.childNodes[0].childNodes[1].childNodes[1].id = "copyButton_" + edge_id;
  clone.childNodes[0].childNodes[1].childNodes[2].id = "pinButton_" + edge_id;
  clone.childNodes[0].childNodes[1].childNodes[3].id = "crossButton_" + edge_id;
  clone.childNodes[0].childNodes[1].childNodes[1].childNodes[0].id =
    "copyIcon_" + edge_id;
  clone.childNodes[0].childNodes[1].childNodes[2].childNodes[0].id =
    "pinIcon_" + edge_id;
  clone.childNodes[0].childNodes[1].childNodes[3].childNodes[0].id =
    "crossIcon_" + edge_id;
  clone.childNodes[1].childNodes[0].id = "content_" + edge_id;
  original.parentNode.appendChild(clone);
}

class GraphView extends Component {
  constructor(props) {
    super(props);
    this.node_map = {};
    this.node_and_edges_store = {};
    this.filtered = null;

    this.state = {
      search_count_result: "",
    };
    this.handleSearchCountChange = this.handleSearchCountChange.bind(this);
  }

  handleSearchCountChange(value) {
    this.setState({ search_count_result: value });
  }

  componentDidMount = () => {
    this.setUpListeners();
  };

  componentDidUpdate = (prevProps) => {
    // https://stackoverflow.com/questions/34506363/deleting-and-recreating-an-element-with-react
    //https://stackoverflow.com/questions/48709127/slider-filtering-edges-by-property-e-g-strength-0-1
    // this.cy.destroy();
    const current_layout = this.layoutHandler(
      this.props.graph_controls.graph_layout,
    );

    if (
      this.props.graph_controls.common_nodes_bool !==
      prevProps.graph_controls.common_nodes_bool
    ) {
      if (this.props.graph_controls.common_nodes_bool) {
        this.filtered = filter_to_show_common_nodes(this.cy);
      } else {
        this.filtered.restore();
      }
    }

    if (this.props.data !== prevProps.data) {
      const search_term = this.props.graph_controls.graph_search_term;
      /*
            const topic_model_word = sessionStorage.getItem('topic_model_word');
            const cache_key = 'cache_' + topic_model_word + '_' + this.props.graph_controls.graph_layout + '_' +
            this.props.graph_controls.relationship_length + '_' + this.props.graph_controls.sensitivity_level + '_' + this.props.graph_controls.hide_unc_node + '_' + this.props.graph_controls.graph_search_term;

            if (sessionStorage.hasOwnProperty(cache_key)){
                console.log("cache_key found: " + cache_key);
                this.cy.elements().remove();
                this.cy.json({elements: JSON.parse(sessionStorage.getItem(cache_key)).elements}).layout({
                    name: 'preset',
                    positions: undefined, // map of (node id) => (position obj); or function(node){ return somPos; }
                    zoom: undefined, // the zoom level to set (prob want fit = false if set)
                    pan: undefined, // the pan level to set (prob want fit = false if set)
                    fit: true, // whether to fit to viewport
                    padding: 30, // padding on fit
                    animate: false, // whether to transition the node positions
                    animationDuration: 500, // duration of animation in ms if enabled
                    animationEasing: undefined, // easing of animation if enabled
                    ready: undefined, // callback on layoutready
                    stop: undefined // callback on layoutstop
                }).run();
                this.cy.center();
                this.cy.fit();
                this.props.update_graph_loading_bool(false);
            }else{
                this.cy.elements().remove();
                this.cy.json({elements: this.props.data});
                this.cy.layout(current_layout).run();
                this.props.update_graph_loading_bool(false);
                this.node_and_edges_store = generate_graph_stats(this.cy, this.props.update_graph_metadata);
                this.cy.center();
                this.cy.fit();
                console.log("save to cache_key: " + cache_key);
                sessionStorage.setItem(cache_key, JSON.stringify(this.cy.json()));
            }*/

      this.cy.elements().remove();
      this.cy.json({ elements: this.props.data });
      this.cy.layout(current_layout).run();
      this.props.update_graph_loading_bool(false);
      this.node_and_edges_store = generate_graph_stats(
        this.cy,
        this.props.update_graph_metadata,
      );
      this.cy.center();
      this.cy.fit();

      if (search_term.length > 0) {
        let searched_nodes = this.cy.nodes().filter(function (ele) {
          return (
            ele.data("label").toLowerCase().indexOf(search_term.toLowerCase()) >= 0
          );
        });

        for (let report of searched_nodes) {
          //let summary_report_label = report.data().label;
          this.cy.$("#" + report.data().id).addClass("searchterm");
        }

        if (searched_nodes.length > 1) {
          const search_count_result =
            searched_nodes.length + " nodes have been found.";
          this.handleSearchCountChange(search_count_result);
        } else {
          const search_count_result =
            searched_nodes.length + " node has been found.";
          this.handleSearchCountChange(search_count_result);
        }
      } else {
        this.handleSearchCountChange("");
      }
    }

    if (
      this.props.graph_controls.graph_layout !==
      prevProps.graph_controls.graph_layout
    ) {
      this.cy.layout(current_layout).run();
    }

    if (
      this.props.graph_controls.filterNodes !== prevProps.graph_controls.filterNodes
    ) {
      /*
                This function below handles the filtering of nodes via the checkboxes in the UI
             */
      const { metadata: prevMetadata } = prevProps.graph_controls;
      const { filterNodes, metadata } = this.props.graph_controls;
      remove_selected_sub_nodes_handler(
        filterNodes,
        metadata,
        prevMetadata,
        this.node_and_edges_store,
        this.cy,
      );
    }

    if (
      this.props.graph_controls.graph_search_term !==
      prevProps.graph_controls.graph_search_term
    ) {
      this.cy.elements().removeClass("searchterm");

      const search_term = this.props.graph_controls.graph_search_term;
      //let searched_nodes = this.cy.filter('node[label="' + search_term.toLowerCase() + '"]');
      if (search_term.length > 0) {
        let searched_nodes = this.cy.nodes().filter(function (ele) {
          return (
            ele.data("label").toLowerCase().indexOf(search_term.toLowerCase()) >= 0
          );
        });

        for (let report of searched_nodes) {
          //let summary_report_label = report.data().label;
          this.cy.$("#" + report.data().id).addClass("searchterm");
        }

        if (searched_nodes.length > 1) {
          const search_count_result =
            searched_nodes.length + " nodes have been found.";
          this.handleSearchCountChange(search_count_result);
        } else {
          const search_count_result =
            searched_nodes.length + " node has been found.";
          this.handleSearchCountChange(search_count_result);
        }
      } else {
        this.handleSearchCountChange("");
      }
    }
  };

  layoutHandler = (layout) => {
    switch (layout) {
      case EULER: {
        return euler_layout;
      }
      case FCOSE: {
        return fcose_layout;
      }
      case AVSDF: {
        return avsdf_layout;
      }
      case KLAY: {
        return klay_layout;
      }
      default:
        return euler_layout;
    }
  };

  componentWillUnmount = () => {
    console.log("remove listeners");
    if (this.cy) {
      this.cy.removeAllListeners();
    }
  };

  setUpListeners = () => {
    const graph_data = this.props.data;

    this.node_and_edges_store = generate_graph_stats(
      this.cy,
      this.props.update_graph_metadata,
    );
    modify_unverified_edges(this.cy);

    this.cy.unbind("click");
    /*
          For click events, place your code here
         */
    let popper_pinned_id = [];
    let popper_pinned = [];
    let current_popper = [];

    this.cy.on("tap", function (e) {
      // target holds a reference to the originator
      // of the event (core or element)
      var target = e.target;

      if (typeof target["0"] === "undefined") {
        // When click on core
        if (popper_pinned.length === 0) {
          for (let i = 0; i < current_popper.length; i++) {
            var element = document.getElementById(current_popper[i][0]);
            element.style.display = "none";
            current_popper[i][1].edges().animate({
              style: {
                lineColor: "#adadaf",
              },
            });
          }
          current_popper.length = 0;
        } else {
          for (let i = 0; i < current_popper.length; i++) {
            if (!popper_pinned_id.includes(current_popper[i][0])) {
              var element = document.getElementById(current_popper[i][0]);
              element.style.display = "none";
              current_popper[i][1].edges().animate({
                style: {
                  lineColor: "#adadaf",
                },
              });
              current_popper.splice(i, 1);
              i = 0;
            }
          }
        }
      } else {
        console.log("see see", current_popper);
        let type_str = "";
        let source_node = target.data().source;
        let source_node_label = "";
        let target_node = target.data().target;
        let target_node_label = "";
        let properties = target.data().details;
        if (properties) {
          var keys = Object.keys(properties);
          for (let key of keys) {
            let items = properties[key];
            let item_list = items.split(", ");
            type_str = type_str.concat(key, ": <br>");
            for (const item of item_list) {
              type_str = type_str.concat(
                '<a href="',
                window.location.origin,
                "/?search=",
                item,
                '" target="_blank">',
                item,
                "</a><br>",
              );
            }
            type_str = type_str.concat("<br>");
          }
        }
        if (type_str) {
          target.edges().animate({
            style: {
              lineColor: "#00A0E8",
            },
          });

          for (let node of graph_data.nodes) {
            if (node["data"]["id"] === source_node) {
              source_node_label = node["data"]["label"];
            } else if (node["data"]["id"] === target_node) {
              target_node_label = node["data"]["label"];
            }
          }

          duplicatePopper(target.data().id);

          function showPopper(popper_id) {
            var popper = document.getElementById(popper_id);
            dragPopper(popper);
            var header = document.getElementById("header_" + popper_id);
            var title = document.getElementById("title_" + popper_id);
            var copyButton = document.getElementById("copyButton_" + popper_id);
            var pinButton = document.getElementById("pinButton_" + popper_id);
            var crossButton = document.getElementById("crossButton_" + popper_id);
            var copyIcon = document.getElementById("copyIcon_" + popper_id);
            var pinIcon = document.getElementById("pinIcon_" + popper_id);
            var crossIcon = document.getElementById("crossIcon_" + popper_id);
            var content = document.getElementById("content_" + popper_id);

            popper.style.display = "block";
            popper.style.position = "absolute";
            popper.style.backgroundColor = "#14151a";
            popper.style.cursor = "move";
            popper.style.zIndex = "10000";

            header.style.display = "block";
            header.style.fontSize = "14px";
            header.style.backgroundColor = "#1d2f5d";
            header.style.color = "#FFFFFF";

            title.style.display = "inline-block";
            title.style.width = "60%";
            title.innerHTML = source_node_label + " &#8212 " + target_node_label;
            title.style.padding = "5px";

            copyButton.style.display = "inline-block";
            copyButton.style.backgroundColor = "#1d2f5d";
            copyButton.style.color = "#FFFFFF";
            copyButton.style.borderRadius = "5px";
            copyButton.style.cursor = "pointer";
            copyButton.style.alignItems = "center";
            copyButton.style.fontSize = "18px";
            copyButton.style.margin = "0";

            pinButton.style.display = "inline-block";
            pinButton.style.backgroundColor = "#1d2f5d";
            pinButton.style.color = "#FFFFFF";
            pinButton.style.borderRadius = "5px";
            pinButton.style.cursor = "pointer";
            pinButton.style.alignItems = "center";
            pinButton.style.fontSize = "18px";
            pinButton.style.margin = "0";

            crossButton.style.backgroundColor = "#1d2f5d";
            crossButton.style.color = "#FFFFFF";
            crossButton.style.borderRadius = "5px";
            crossButton.style.cursor = "pointer";
            crossButton.style.alignItems = "center";
            crossButton.style.fontSize = "18px";
            crossButton.style.margin = "0";

            copyIcon.style.color = "#FFFFFF";
            copyIcon.style.fontSize = "18px";
            copyIcon.style.margin = "0";

            pinIcon.style.color = "#FFFFFF";
            pinIcon.style.fontSize = "18px";
            pinIcon.style.margin = "0";

            crossIcon.style.color = "#FFFFFF";
            crossIcon.style.fontSize = "18px";
            crossIcon.style.margin = "0";

            content.innerHTML = type_str;
            content.style.fontSize = "14px";
            content.style.backgroundColor = "#14151a";
            content.style.color = "#FFFFFF";
            content.style.borderRadius = "5px";
            content.style.padding = "5px";
            content.style.width = "270px";
            content.style.height = "300px";

            copyButton.onclick = function () {
              copyToClipboard("content_" + popper_id);
            };
            pinButton.onclick = function () {
              pinButton.style.display = "none";
              crossButton.style.display = "inline-block";
              popper_pinned.push([popper_id, target]);
              popper_pinned_id.push(popper_id);
            };
            crossButton.onclick = function () {
              popper.style.display = "none";
              let popper_pinned_id_index = popper_pinned_id.indexOf(popper_id);
              current_popper[popper_pinned_id_index][1].edges().animate({
                style: {
                  lineColor: "#adadaf",
                },
              });
              for (let i = 0; i < current_popper.length; i++) {
                if (current_popper[i][0] === popper_id) {
                  current_popper.splice(i, 1);
                  break;
                }
              }
              popper_pinned.splice(popper_pinned_id_index, 1);
              popper_pinned_id.splice(popper_pinned_id_index, 1);
              crossButton.style.display = "none";
            };
          }

          if (!current_popper.find((id) => id[0] === target.data().id)) {
            showPopper(target.data().id);
            current_popper.push([target.data().id, target]);
          }

          if (popper_pinned.length === 0) {
            if (current_popper.length > 1) {
              var popper_new = document.getElementById(current_popper[0][0]);
              popper_new.style.display = "none";
              current_popper[0][1].edges().animate({
                style: {
                  lineColor: "#adadaf",
                },
              });
              current_popper.splice(0, 1);
            }
          } else {
            var popper_pinned_length = popper_pinned.length;
            if (current_popper.length > popper_pinned_length + 1) {
              var popper_new = document.getElementById(
                current_popper[popper_pinned_length][0],
              );
              popper_new.style.display = "none";
              current_popper[popper_pinned_length][1].edges().animate({
                style: {
                  lineColor: "#adadaf",
                },
              });
              current_popper.splice(popper_pinned_length, 1);
            }
          }
        }
      }
    });

    this.cy.on("click", "node", (event) => {
      let target = event.target;
      remove_edge_from_node(target, this.node_map, this.cy);
    });

    // this.cy.on('click', 'edge', (event) => {
    //     console.log('this is an event', event)
    //     let edge = event.target;
    //     console.log('this is event target', edge)
    //     let type_str = "";
    //     let properties = edge.data().details;
    //     console.log('this is properties', properties)
    //     if (properties){
    //         var keys = Object.keys(properties);
    //         console.log('this is object dot keys', keys)
    //         console.log('this is window location', window.location.origin)
    //         for (let key of keys) {
    //             //console.log("key: " + key + ", " + properties[key]);
    //             let items = properties[key]
    //             let item_list = items.split(", ")
    //             type_str = type_str.concat(key, ": <br>");
    //             for (const item of item_list){
    //                 type_str = type_str.concat("<a href=\"", window.location.origin,"/?search=", item , "\" target=\"_blank\">", item, "</a><br>")
    //             }
    //             type_str = type_str.concat("<br>")
    //         }
    //     }

    //     // let ref = edge.popperRef();
    //     // console.log('this is the ref', ref)
    //     // let dummyDomEle = document.createElement('div');
    //     // //dummyDomEle.style= "min-width:500px;";
    //     // this.tip = PopperObj(dummyDomEle, type_str, ref);
    //     // this.tip.show();
    //     // this.tip.setProps({
    //     //     arrow: false,
    //     // })
    //     if (type_str) {
    //         let element = document.getElementById('popper');
    //         element.style.display = 'inline';
    //         element.style.position = 'absolute';
    //         element.style.fontSize = "14px";
    //         element.style.backgroundColor = "#424242";
    //         element.style.color = "#FFFFFF";
    //         element.style.borderRadius = "5px";
    //         element.style.padding = "5px";
    //         element.style.cursor = 'move';
    //         element.style.width = '270px';
    //         element.style.height = '300px';
    //         element.style.overflow = 'auto';
    //         element.style.right = '240px';
    //         element.style.zIndex = '10000';
    //         element.style.cursor = 'move';
    //         element.innerHTML = type_str
    //     }
    // });

    /*
        this.cy.on('tap', (event) => {
            var evtTarget = event.target;

            if (evtTarget === this.cy){
                this.tip.destroy()
            }
        })

         */

    this.cy.on("mouseover", "node", (event) => {
      let node = event.target;
      let type_str = node.data().type;
      let ref = node.popperRef();
      let dummyDomEle = document.createElement("div");
      this.tip = PopperObj(dummyDomEle, type_str, ref);
      this.tip.show();
    });

    /*
        this.cy.on('mouseover', 'edge', (event) => {
            let edge = event.target;
            let type_str = "";
            let properties = edge.data().details;
            if (properties){

                var keys = Object.keys(properties);
                for (let key of keys) {
                    //console.log("key: " + key + ", " + properties[key]);
                    type_str = type_str.concat(key, ": ", properties[key], "<br><br>");
                }
            }

            let ref = edge.popperRef();
            let dummyDomEle = document.createElement('div');
            this.tip = PopperObj(dummyDomEle, type_str, ref);
            this.tip.show();
        });

         */

    this.cy.on("mouseout", "node", () => {
      this.tip.destroy();
    });
    /*
        this.cy.on('mouseout', 'edge', () => {
            this.tip.destroy();
        });

         */

    // this.cy.cxtmenu(content_menu);
    this.cy.on("cxttap", (event) => {
      let node = event.target;
      let node_type = node.data().type;
      let node_label = node.data().label;

      if (node_type === "summary_report") {
        window.open(`/report_detail?pdf_file_path=${node_label}`, "_blank");
      }
    });
    this.cy.ready(() => {
      // when the graph is ready to be shown
      this.props.update_graph_loading_bool(false);
    });
  };

  render() {
    const { classes } = this.props;
    const current_layout = this.layoutHandler(
      this.props.graph_controls.graph_layout,
    );

    return (
      <div className={classes.root}>
        <CytoscapeComponent
          elements={CytoscapeComponent.normalizeElements(this.props.data)}
          // style={{ height: 'calc(91.3vh)', width: '100vw', background: '#14151a'}}
          style={{
            height: "calc(100vh - 55px)",
            width: "100vw",
            background: "#424242",
          }}
          layout={current_layout}
          zoom={0}
          minZoom={0}
          maxZoom={4}
          wheelSensitivity={0.2}
          stylesheet={stylesheet}
          cy={(cy) => {
            this.cy = cy;
          }}
        />
        {this.cy && <GraphSpeedDial cy={this.cy} file_list={this.props.file_list} />}
        {/* {copyToClipboard('content_' + target.data().id, 'copyButton' + target.data().id)} */}
        <div id="popper" style={{ display: "none" }}>
          <div id="header">
            <div id="title"></div>
            <div
              style={{
                display: "inline-block",
                width: "40%",
                marginLeft: "auto",
                marginRight: "0",
              }}
            >
              <div id="alert" style={{ display: "none" }}></div>
              <IconButton id="copyButton" style={{ display: "none" }}>
                <FileCopyOutlinedIcon id="copyIcon" />
              </IconButton>
              <IconButton id="pinButton" style={{ display: "none" }}>
                <PinDropOutlinedIcon id="pinIcon" />
              </IconButton>
              <IconButton id="crossButton" style={{ display: "none" }}>
                <CloseIcon id="crossIcon" />
              </IconButton>
            </div>
          </div>
          <div style={{ overflow: "auto" }}>
            <div id="content"></div>
          </div>
        </div>
        {this.state.search_count_result !== "" && (
          <div
            style={{
              border: "none",
              position: "absolute",
              bottom: "10px",
              paddingLeft: "10px",
            }}
          >
            <input
              className="search_count_display"
              type="text"
              value={this.state.search_count_result}
              onChange={this.handleSearchCountChange}
              readOnly={true}
              style={{
                fontSize: "large",
                border: "none",
                background: "transparent",
                color: "#ffffff",
                width: "100vw",
              }}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { graph_controls } = state;
  return { graph_controls };
};

export default connect(mapStateToProps, {
  update_graph_loading_bool,
  update_graph_metadata,
})(withStyles(styles)(GraphView));
