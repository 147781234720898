import clsx from "clsx";
import React, { useState } from "react";
import { TDToggleSwitchProps } from "./TDToggleSwitch.types";

// TODO: Use '@radix-ui/react-switch' instead
export default function TDToggleSwitch({
  id,
  label,
  labelPos = "LEFT",
  defaultValue = false,
  onIndicator,
  offIndicator,
  offLabel,
  onLabel,
}: TDToggleSwitchProps) {
  const [toggle, setToggle] = useState(defaultValue);

  const LabelComp = ({ sideLabel }: { sideLabel?: any }) => {
    return (
      <label htmlFor={id} className="text-slate-500 text-sm cursor-pointer">
        {sideLabel !== null ? sideLabel : label}
      </label>
    );
  };

  function onToggleChange() {
    setToggle(!toggle);
  }

  return (
    <label
      htmlFor={id}
      className="relative items-center cursor-pointer space-x-2 flex flex-row flex-nowrap"
    >
      {((label && labelPos == "LEFT") || offLabel !== null) && (
        <LabelComp sideLabel={offLabel} />
      )}
      <div>
        <input
          defaultChecked={toggle}
          onChange={onToggleChange}
          type="checkbox"
          id={id}
          className="sr-only peer group"
        />
        <div
          className={clsx(
            "relative w-11 h-6 bg-gray-200 rounded-full peer-focus:ring-2 peer-focus:ring-primary-200  after:absolute",
            "dark:bg-slate-300 hover:bg-primary-400 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-['']",
            "after:top-0.5 after:left-[2px] peer-checked:hover:bg-primary-300 after:bg-white after:rounded-full after:h-5 after:w-5",
            "after:transition-all dark:border-slate-600 peer-checked:bg-primary transition-colors flex items-center text-white",
          )}
        >
          <div className="text-4xs font-semibold w-full pl-0.5 flex flex-row items-center justify-center">
            {onIndicator}
          </div>
          <div className="text-4xs font-semibold w-full pr-0.5 flex items-center justify-center">
            {offIndicator}
          </div>
        </div>
      </div>
      {((label && labelPos == "RIGHT") || onLabel !== null) && (
        <LabelComp sideLabel={onLabel} />
      )}
    </label>
  );
}
