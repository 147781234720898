import { ElevationLevel } from "./enums";

const ElevationLevelClass = {
  level_1: "",
  level_2: "shadow-md",
  level_3: "shadow-lg",
};

interface ElevationLevels {
  elevationLevel?: keyof typeof ElevationLevel;
}

interface RowOption {
  id: string,
  name: string
}


const rowsPerPage:Array<RowOption> = [
  {
    id: "20",
    name: "20",
  },
  {
    id: "50",
    name: "50",
  },
  {
    id: "100",
    name: "100",
  },
];

export { ElevationLevelClass, rowsPerPage };
export type { ElevationLevels, RowOption };

