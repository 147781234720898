import {resolve} from "../common_utils/resolve";
import axios from "axios";
import {API_ADDRESSES} from "../../constants/api_addresses";


export const upload_pdf_zip = async (formData, setProgress, setLoaderStatus) => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: progressEvent => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setProgress(percentCompleted);
            if (percentCompleted === 100) {
                setLoaderStatus(false);
            }
        }
    }

    return await resolve(axios.post(API_ADDRESSES['upload'], formData,
        config).then(res => res.data));
}

export const retrieve_pending_data = async () => {
    return await resolve(axios.get(API_ADDRESSES['pendingOCR']).then(res => res.data));
}

export const retrieve_completed_ocr_data = async () => {
    return await resolve(axios.get(API_ADDRESSES['completedOCR']).then(res => res.data));
}

export const process_data = async () => {
    return await resolve(axios.get(API_ADDRESSES['processData']).then(res => res.data));
}
