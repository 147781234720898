import ChartBarIcon from "@heroicons/react/24/solid/ChartBarIcon";
import DocumentIcon from "@heroicons/react/24/solid/DocumentIcon";
import DocumentMagnifyingGlassIcon from "@heroicons/react/24/solid/DocumentMagnifyingGlassIcon";
import DocumentPlusIcon from "@heroicons/react/24/solid/DocumentPlusIcon";
import SearchIcon from "@material-ui/icons/Search";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import ChangePasswordIcon from "@material-ui/icons/VpnKey";
import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router";
import { TDNetworkGraphIcon, TDSideNav } from "tau-design-system";
import TAULogo from "../assets/tau_new.png";
import clsx from 'clsx';
import {
  CONCEPTNETWORK_PATH,
  DASHBOARD_PATH,
  HOME_PATH,
  REF_DOC_PATH,
  STIXSEARCH_PATH,
  UPLOAD_PATH,
} from "../constants/path";
import { API_GATEWAY_ADDRESS } from "../constants/api_addresses";

//Titan Logo theme colour from material UI is indigo[800] or normal hex code is #2e3292
//TAU Logo theme colour from material UI is ... or normal hex code is #1d2f5d

function Header(props) {
  const { location } = props;

  const menuItems = [
    {
      label: "Intelligent Search",
      icon: <SearchIcon />,
      url: HOME_PATH,
    },
    {
      label: "Multi-Concept Search",
      icon: <DocumentMagnifyingGlassIcon />,
      url: STIXSEARCH_PATH,
    },
    {
      label: "Network Graph",
      icon: <TDNetworkGraphIcon />,
      url: CONCEPTNETWORK_PATH,
    },
    {
      label: "Dashboard",
      icon: <ChartBarIcon />,
      url: DASHBOARD_PATH,
    },
    {
      label: "Reference Document",
      icon: <DocumentPlusIcon />,
      url: REF_DOC_PATH,
    },
    {
      label: "Document Processor",
      icon: <DocumentIcon />,
      url: UPLOAD_PATH,
    },
    {
      label: "Logout",
      icon: <LogoutIcon />,
      url: API_GATEWAY_ADDRESS+"/auth/logout",
    },
    {
      label: "Change Password",
      icon: <ChangePasswordIcon />,
      url: "javascript:(function(){window.open('"+API_GATEWAY_ADDRESS+"/auth/change-password"+"', 'soda', 'popip')})()"
    },
  ];

  let defaultPageWidth = "w-28";
  const initialOpen = localStorage.getItem('sidenav-toggle') === "true";

  if (initialOpen) {
    defaultPageWidth = "w-[19.5rem]";
  }

  function onNavToggle(_state) {
    const wrapper = document.getElementById("sidenav--wrapper");

    wrapper.classList.toggle("w-28");
    wrapper.classList.toggle("w-[19.5rem]");
  }

  return (
    <div className="flex flex-col h-full w-full">
      <div className="h-20 w-full flex flex-row justify-start items-center space-x-6 py-2 px-6 mt-2">
        <img className="h-full w-auto max-h-[1.75rem]" src={TAULogo} alt="logo" />
        <div className="flex flex-row items-center space-x-4">
          <div className="font-bold text-xl">
            TITAN: Intelligence Analytics Platform
          </div>
        </div>
      </div>
      <div className="flex flex-row space-x-4 w-full h-full">
        <div
          id="sidenav--wrapper"
          className={clsx("z-40 shrink-0 relative h-[calc(100vh-5rem)]", defaultPageWidth)}
        >
          <div className="fixed h-screen top-0 left-0">
            <TDSideNav
              className="px-1 pt-[5rem] pb-2 h-full"
              menuItems={menuItems}
              onUpdate={onNavToggle}
              currentUrl={location.pathname}
            />
          </div>
        </div>
        <div className="w-full h-auto">{props.children}</div>
      </div>
    </div>
  );
}

Header.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(Header);
