import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import FreeTextSearch from './routes/freetextsearch/FreeTextSearch';
import DashBoard from './routes/dashboard/DashBoard'
import StixSearch from "./routes/stixsearch/StixSearch";
import Graph from "./routes/graph/Graph";
import Report from './routes/report_generator/Report';
import ReferenceDocs from './routes/reference_docs/ReferenceDocuments';
import NotFound from "./routes/not_found/NotFound";
import * as path_constant from '../constants/path';
import Upload from "./routes/upload/Upload";
import ConceptNetworkSearch from "./routes/concept_network_search/ConceptNetworkSearch";


function Page() {
  return(
        <Switch>
            <Route exact path={path_constant.HOME_PATH}
                render={props => <FreeTextSearch {...props} /> } />
            <Route path= {path_constant.REPORT_PATH}
                render={props => <Report {...props} /> } />
            <Route path= {path_constant.REF_DOC_PATH}
                render={props => <ReferenceDocs {...props} /> } />
            <Route path= {path_constant.DASHBOARD_PATH}
                render={props => <DashBoard {...props} /> } />
            <Route path= {path_constant.STIXSEARCH_PATH}
                render = {props => <StixSearch{...props}/>}/>
            <Route path= {path_constant.CONCEPTNETWORK_PATH}
                   render = {props => <ConceptNetworkSearch{...props}/>}/>
            <Route path= {path_constant.GRAPHSEARCH_PATH}
                render = {props => <Graph{...props}/>}/>
            <Route path={path_constant.UPLOAD_PATH}
                render = {props => <Upload {...props} />} />
            <Route path= {path_constant.NOT_FOUND}
                   render = {props => <NotFound{...props}/>}/>
            <Redirect to={path_constant.NOT_FOUND} />
        </Switch>
  );
}

export default Page;