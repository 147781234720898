import {UPDATE_REPORT_PDF_SEARCH, UPDATE_REPORT_PDF_URI} from "../actionTypes";


const initialState = {
    search: '',
    encoded_uri: null
};

// Note: no function name!
export default function(state = initialState, action) {
    switch (action.type) {
        case UPDATE_REPORT_PDF_SEARCH: {
            const { search_value } = action.payload;
            return {
                ...state,
                search: search_value
            };
        }

        case UPDATE_REPORT_PDF_URI: {
            const { pdf_blob } = action.payload;
            const encoded_uri = encodeURIComponent(URL.createObjectURL(pdf_blob));

            return {
                ...state,
                encoded_uri
            }
        }

        default:
            return state;
    }
}