import {
    ATTACK_METHODS,
    CAMPAIGN_NAMES, COUNTRIES_INVOLVED,
    // DATES_EXTRACTED,
    EMAILS,
    HASHES, HASHES_MD5, HASHES_SHA1, HASHES_SHA256,
    IPS, MALWARE_CATEGORIES,
    MALWARE_NAMES,
    REGISTRY_KEYS, SUMMARY_REPORT,
    TARGET_VERTICALS,
    THREAT_ACTORS,
    TTP,
    URLS, VULNERABILITIES
} from "./tables";

// STIX Naming
export const ATTACK_PATTERN = "attack_pattern";
export const CAMPAIGN = "campaign";
export const IDENTITY = "identity";
export const INDICATOR = "indicator";
export const INTRUSION_SET = "intrusion_set";
export const LOCATION = "location";
export const MALWARE = "malware";
export const OBSERVED_DATA = "observed_data";
export const REPORT = "report";
export const THREAT_ACTOR = "threat_actor";
export const GOAL_OF_ATTACK = "goal_of_attack";
export const VULNERABILITY = "vulnerability";


export const SCIM_NAMES = {
    [ATTACK_METHODS]: { title: 'Attack Method'},
    [TTP]: { title: 'TTP'},
    [CAMPAIGN_NAMES]: { title: 'Campaign Name'},
    [EMAILS]: { title: 'Email'},
    [TARGET_VERTICALS]: { title: 'Target Vertical'},
    [HASHES]: { title: 'Hashes'},
    [URLS]: { title: 'URL'},
    [THREAT_ACTORS]: { title: 'Threat Actor'},
    [COUNTRIES_INVOLVED]: { title: 'Country'},
    [MALWARE_NAMES]: { title: 'Malware Name'},
    [MALWARE_CATEGORIES]: { title: 'Malware Category'},
    [REGISTRY_KEYS]: { title: 'Registry Key'},
    [IPS]: { title: 'IP'},
    // [DATES_EXTRACTED]: { title: 'Dates Extracted'},
    [GOAL_OF_ATTACK]: { title: 'Goal Of Attack'},
    [VULNERABILITIES]: { title: 'Vulnerability' },
    [HASHES_MD5]: { title: 'Hash - MD5'},
    [HASHES_SHA1]: { title: 'Hash - SHA1'},
    [HASHES_SHA256]: { title: 'Hash - SHA256'},
    [SUMMARY_REPORT]: { title: 'PDF Filename'}
}

export const STIX_MAPPING = {
    [ATTACK_PATTERN]: {
        title: "Attack Pattern",
        mapping: [ATTACK_METHODS, TTP]
    },
    [CAMPAIGN]: {   // DATES_EXTRACTED removed
        title: "Campaign",
        mapping: [CAMPAIGN_NAMES]
    },
    [IDENTITY]: {
        title: "Identity",
        mapping: [TARGET_VERTICALS]
    },
    [INDICATOR]: {
        title: "Indicator",
        mapping:[HASHES_MD5, HASHES_SHA1, HASHES_SHA256, URLS, EMAILS, IPS]
    },
    [INTRUSION_SET]: {
        title: "Intrusion Set",
        mapping: [GOAL_OF_ATTACK]
    },
    [LOCATION]: {
        title: "Location",
        mapping: [COUNTRIES_INVOLVED]
    },
    [MALWARE]: {
        title: "Malware",
        mapping: [MALWARE_NAMES, MALWARE_CATEGORIES]
    },
    [OBSERVED_DATA]: {
        title: "Observed Data",
        mapping: [REGISTRY_KEYS]
    },
    [REPORT]: {
        title: "Report",
        mapping: [SUMMARY_REPORT]
    },
    [THREAT_ACTOR]: {
        title: "Threat Actor",
        mapping: [THREAT_ACTORS]
    },
    [VULNERABILITY]: {
        title: "Vulnerability",
        mapping: [VULNERABILITIES]
    }
};
