export const remove_selected_node_type = (cy, summary_node, type_of_sub_node) => {
    let filteredNodesAndEdges;

    const removeInterNodes = (node1_type, node2_type) => {
        let summaryNode = cy.nodes(`node[label="${summary_node}"]`);
        let connectedNodes = summaryNode.neighborhood();
        let filteredConnectedNodes = connectedNodes.filter((ele) => {
            const node_type = ele.data().type;
            return node_type === node1_type || node_type === node2_type;
        })
        let allConnectedEdgesToTarget = filteredConnectedNodes.connectedEdges()
        return allConnectedEdgesToTarget.union(filteredConnectedNodes);
    }

    if (type_of_sub_node === 'url & domain') {
        filteredNodesAndEdges = removeInterNodes('url', 'domain');
    }
    else if (type_of_sub_node === 'hashes & metadata'){
        filteredNodesAndEdges = removeInterNodes('hashes', 'metadata');
    }
    else {
        let summaryNode = cy.nodes(`node[label="${summary_node}"]`);
        let connectedNodes = summaryNode.neighborhood(`node[type="${type_of_sub_node}"]`);
        let allConnectedEdgesToTarget = summaryNode.edgesTo(connectedNodes);
        let connectedFilteredNodes = connectedNodes.filter('node[[indegree = 1]]');
        filteredNodesAndEdges = allConnectedEdgesToTarget.union(connectedFilteredNodes);
    }
    cy.remove(filteredNodesAndEdges);
    return filteredNodesAndEdges;
};

export const remove_selected_summary_nodes = (cy, summary_node) => {
    let filteredNodesAndEdges;
    let selectedNode = cy.nodes(`node[label="${summary_node}"]`)
    let connectedNodesAndEdges = selectedNode.neighborhood();   // nodes and edges
    // filter nodes that have >= 2 connected summary nodes
    let filteredNodes = connectedNodesAndEdges.filter((ele) => {
        if (ele.isNode()) {
            let connectedEdges = ele.connectedEdges();
            let connectedNodes = connectedEdges.connectedNodes();
            let filtered = connectedNodes.filter('node[type="summary_report"]');
            return filtered.length === 1
        } else {
            return false;
        }
    });
    let filteredEdges = connectedNodesAndEdges.filter((ele) => {
        return ele.isEdge();
    });

    // filter nodes for url and domain and get their connected edges.
    let filteredConnectedNodes = connectedNodesAndEdges.filter((ele) => {
        const node_type = ele.data().type;
        return node_type === 'url' || node_type === 'domain';

    })
    let allConnectedEdgesToTarget = filteredConnectedNodes.connectedEdges()
    filteredNodesAndEdges = filteredEdges.union(filteredNodes).union(allConnectedEdgesToTarget);
    cy.remove(filteredNodesAndEdges);
    return filteredNodesAndEdges;
};