import {DATA_DICT} from "../../../../constants/tables";

export const QueryTabSearcher = (search_params, table_data, update_expansion_panel_multiple) => {
    /*
        @search_params: search params state from Report
        @table_data: redux store table_data
        @update_expansion_panel_multiple:  redux action to update the expansion panel

        This function search the table_data object for the search query
        and output the index of DATA_DICT which will then be sent to
        the redux store to update the expansion panel
     */
    const {query, summary} = search_params;
    let query_exist_in_which_arr = [];
    if (summary) {
        // Search which DATA DICT table has the word
        for (let [key, value] of Object.entries(table_data)) {
            if (typeof value === 'object') {
                // check whether the array contains the query word
                // value -> [{'key': 'value', 'key2': 'value2'},{'key': 'value' ...}]
                // console.log(value);
                if (!value){
                    continue
                }
                value.forEach(obj => {
                    for (const property in obj) {
                        if(obj[property] === query) {
                            query_exist_in_which_arr.push(key);
                            break;
                        } else if (property === 'alias') {
                            if (obj[property].includes(query)) {
                                query_exist_in_which_arr.push(key);
                                break;
                            }
                        }
                    }
                });
            }
        }
    }
    // find the index in data_dict
    const indexes = query_exist_in_which_arr.map(query => {
        return Object.keys(DATA_DICT).indexOf(query);
    })
    update_expansion_panel_multiple(indexes);
}
